import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const passwordStrengthIcon = (
  errors: [{ field: string; reason: string }],
  condition: string
) => {
  if (errors === null || errors?.find((error) => error.reason === condition)) {
    return <CancelIcon sx={{ color: 'red' }} />
  } else {
    return <CheckCircleIcon sx={{ color: 'green' }} />
  }
}

export const passwordStrengthGetColor = (strength: number) => {
  switch (strength) {
    case 0:
      return 'primary'
    case 20:
      return 'error'
    case 40:
      return 'error'
    case 60:
      return 'warning'
    case 80:
      return 'warning'
    case 100:
      return 'success'
    default:
      break
  }
}

export const calculateStrength = (
  errors: [{ field: string; reason: string }],
  conditions: string[]
): number => {
  const score = conditions.reduce((acc, condition) => {
    if (errors) {
      if (
        !errors?.find((error: { reason: string }) => error.reason === condition)
      ) {
        return acc + 20
      }
    }
    return acc
  }, 0)

  return score
}
