// -- Import NPM
import { useTranslation } from 'react-i18next'

// Import Components
import { Fragment, useCallback, useEffect, useState } from 'react'
import AttachmentIcon from '../../../assets/icons-io-v1/attachment.svg?react'
import DetachmentIcon from '../../../assets/icons-io-v1/detachment.svg?react'
import AdministrativeStatus from '../../../components/chips/administrativeStatus'
import Visible from '../../../components/permissions/Visible'
import { displayElementType } from '../../../hooks/displayElementType'
import { attachmentElementIcons } from '../../../hooks/displayIcons'
import { ResourceType } from '../../../slices/authorizationsApiSlice'
import type {
  AttachmentElementData,
  TransportElementV2,
  WorkspaceV2,
} from '../../../slices/types'
import { AttachmentIsDetachable } from './Attachments'

//Import Icons
import { useNavigate } from 'react-router-dom'
import ShowIcon from '../../../assets/icons-io-v1/show.svg?react'
import { StyledButton } from '../../../components/forms/StyledFormComponents'
import { usePermissions } from '../../../hooks/usePermissions'

type AccordionTransportProps = {
  data: Omit<TransportElementV2, 'attachments'>
  workspaceData?: WorkspaceV2
}

interface DisplayAttachmentButtonProps {
  attachment: AttachmentElementData
  data: AccordionTransportProps['data']
  workspaceData: AccordionTransportProps['workspaceData']
}

const DisplayAttachmentButton = ({
  attachment,
  data,
  workspaceData,
}: DisplayAttachmentButtonProps) => {
  const nodeAttached = workspaceData?.data?.nodes?.find(
    (element) => element.id === attachment.nodeId
  )
  const { t } = useTranslation()

  const navigate = useNavigate()

  const showDetailsAttachment = useCallback(() => {
    navigate(`details/attachments/${attachment?.id}/properties`)
  }, [attachment?.id, navigate])
  const showDetachAttachment = useCallback(() => {
    navigate(
      `attachment/${nodeAttached?.type}/${nodeAttached?.id}/${attachment?.side}`
    )
  }, [attachment?.side, navigate, nodeAttached?.id, nodeAttached?.type])

  return (
    <div className='product__container--current-prod'>
      {attachmentElementIcons(nodeAttached?.kind, nodeAttached?.type, '')}
      <span className='product__container--attachments-type'>
        {displayElementType(nodeAttached?.kind, nodeAttached?.type, t)}
      </span>
      <span className='product__container--attachments-name'>
        {nodeAttached?.name}
      </span>
      <span className='product__container--attachments-status'>
        <AdministrativeStatus
          status={attachment?.administrativeState}
          attachment={true}
        />
      </span>

      <Visible
        resource={ResourceType.Workspace}
        resourceId={data?.id}
        action='get_attachment'>
        <StyledButton
          sx={{
            margin: 0,
            marginLeft: '10px',
            padding: '0 0 0 7px',
            minWidth: 'unset',
          }}
          variant='outlined'
          disableRipple
          startIcon={<ShowIcon />}
          onClick={showDetailsAttachment}
        />
      </Visible>

      <Visible
        resource={ResourceType.Workspace}
        resourceId={data?.id}
        action='delete_attachment'>
        <StyledButton
          sx={{ marginLeft: '12px' }}
          variant='outlined'
          startIcon={<DetachmentIcon />}
          disabled={!AttachmentIsDetachable(attachment?.administrativeState)}
          onClick={showDetachAttachment}>
          Detach
        </StyledButton>
      </Visible>
    </div>
  )
}

export default function TransportDetails({
  data,
  workspaceData,
}: AccordionTransportProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { allowed } = usePermissions(
    ResourceType.Workspace,
    workspaceData.data?.id,
    'add_attachment'
  )

  const [attachedElements, setAttachedElements] =
    useState<AttachmentElementData[]>()

  useEffect(() => {
    const newAttachedElements = workspaceData?.data?.attachments?.filter(
      (attachment) => attachment?.transportId === data?.id
    )
    setAttachedElements(newAttachedElements)
  }, [data?.id, workspaceData?.data?.attachments])

  const openAttach = useCallback(() => {
    navigate(`attachment/transport/${data?.id}`)
  }, [navigate, data?.id])

  return (
    <>
      <div className='accordion__container-open'>
        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>
            {t('element.PROVIDER')}
          </div>
          <div className='accordion__open--details-info'>
            {data?.product?.provider ? data?.product?.provider : ' - '}
          </div>
        </div>
      </div>

      <div
        className='accordion__container-open'
        style={{ alignItems: 'flex-start' }}>
        {['A', 'Z'].map((side) => {
          const attachment = attachedElements?.find((att) => att.side === side)

          return (
            <Fragment key={side}>
              <div className='accordion__container-open--details'>
                <div className='accordion__open--details-info'>
                  <div className='accordion__open--details-info-attachment'>
                    {t('element.ATTACHMENT')}
                    {attachedElements?.length > 0 && (
                      <>
                        {': '}
                        {side === 'A'
                          ? data?.product?.location
                          : data?.product?.locationTo}
                      </>
                    )}
                  </div>
                  {attachment ? (
                    <DisplayAttachmentButton
                      attachment={attachment}
                      data={data}
                      workspaceData={workspaceData}
                    />
                  ) : (
                    <StyledButton
                      sx={{ height: '48px', marginTop: '10px' }}
                      variant='outlined'
                      startIcon={<AttachmentIcon />}
                      disabled={
                        data?.administrativeState !== 'deployed' || !allowed
                      }
                      onClick={openAttach}>
                      Attach element
                    </StyledButton>
                  )}
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
    </>
  )
}
