import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetAccountByIdQuery } from '../slices/accountApiSlice'
import { selectSelectedAccount } from '../slices/accountSlice'
import { useSelfQuery } from '../slices/authApiSlice'
import { selectCurrentToken } from '../slices/authSlice'

const useCurrentAccount = () => {
  const selectedAccount = useSelector(selectSelectedAccount)

  // --------------------------------------------------
  // ----- Get Self Data, and fix refetchOnMount issue
  const token = useSelector(selectCurrentToken)
  const [tokenState, setTokenState] = useState(token)
  const { data: MeData, refetch } = useSelfQuery(null, {
    skip: !token,
  })

  useEffect(() => {
    if (tokenState !== token) {
      refetch()
      setTokenState(token)
    }
  }, [tokenState, token, refetch])

  const { data: currentAccount, error } = useGetAccountByIdQuery(
    selectedAccount?.id || MeData.accountId,
    {
      skip: !(selectedAccount?.id || MeData?.accountId),
    }
  )

  return { currentAccount, error }
}

export default useCurrentAccount
