// IMPORT DEPENDENCIES
import { Fragment, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// IMPORT STYLES
import { StyledDrawer } from './StyledDrawer'

// IMPORT ASSETS
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useGetPortByIdV2Query } from '../../slices/portsApiSlice'
import type { WorkspaceV2 } from '../../slices/types'
import { useGetElementByIdV2Query } from '../../slices/workspacesApiSliceV2'
import ElementDetails from './drawerDetailsContent/ElementDetailsV2'

type DrawerReadOnlyProps = {
  workspaceData?: WorkspaceV2
}

const adaptElementKind = (type: string) => {
  switch (type) {
    case 'cloud':
      return 'nodes'
    case 'access':
      return 'nodes'
    case 'transport':
      return 'transports'
    case 'attachments':
      return 'attachments'
    case 'port':
      return 'ports'
    default:
      break
  }
}

export default function DrawerReadOnly({ workspaceData }: DrawerReadOnlyProps) {
  const { currentAccount } = useCurrentAccount()
  const { workspaceId, type: elementType, elementId } = useParams()
  const navigate = useNavigate()

  const adaptedType = adaptElementKind(elementType)
  const skipPorts =
    !currentAccount?.id || !adaptedType || adaptedType !== 'ports'
  const skipElses =
    !currentAccount?.id || !adaptedType || adaptedType === 'ports'

  // FETCH THE CURRENT WORKSPACE DATA
  const { data: elseElementData } = useGetElementByIdV2Query(
    {
      accountId: currentAccount?.id,
      workspaceId,
      elementType: adaptedType !== 'ports' ? adaptedType : 'nodes',
      elementId,
    },
    {
      skip: skipElses,
      refetchOnMountOrArgChange: true,
    }
  )
  // FETCH THE CURRENT WORKSPACE DATA

  const { data: portsElementData } = useGetPortByIdV2Query(
    {
      accountId: currentAccount?.id,
      portId: elementId,
    },
    {
      skip: skipPorts,
      refetchOnMountOrArgChange: true,
    }
  )

  let elementData
  if (adaptedType === 'ports') {
    elementData = portsElementData
  } else {
    elementData = elseElementData
  }

  const toggleDrawer = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <div>
      <Fragment key='right'>
        <StyledDrawer anchor='right' open onClose={toggleDrawer}>
          <ElementDetails
            elementData={elementData}
            workspaceData={workspaceData}
            handleClose={toggleDrawer}
          />
        </StyledDrawer>
      </Fragment>
    </div>
  )
}
