// -- Import NPM
import BarChartIcon from '@mui/icons-material/BarChart'
import TimelineIcon from '@mui/icons-material/Timeline'
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@mui/material'
import moment from 'moment'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

// -- Import ASSETS
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useTranslation } from 'react-i18next'
import CalendarIcon from '../../assets/icons-24/calendar.svg?react'
import RefreshIcon from '../../assets/icons-24/refresh.svg?react'
import {
  humanizeBandwidth,
  humanizeBandwidthInMetrics,
  humanizeBandwidthInTickChart,
} from '../../hooks/humanizeBandwidth'
import type { MetricsData } from '../../slices/types'
import { StyledButton, StyledIconButton } from '../forms/StyledFormComponents'
import './style.scss'

type MetricsProps<T extends number | boolean> = {
  data: MetricsData<T>
  refreshMetricsData: (
    customDate?: string,
    startDate?: Date,
    endDate?: Date
  ) => void
  errorData: FetchBaseQueryError | SerializedError

  minDate: Date
  bandwidth?: number | false

  // elementData: ConcatElementV2
  defaultRangeState: string
  setDefaultRangeState: Dispatch<SetStateAction<string>>
}

export default function Recharts({
  data,
  refreshMetricsData,
  errorData,

  minDate,
  bandwidth = false,

  defaultRangeState,
}: MetricsProps<number>) {
  // @FIXME: get right type
  const { t } = useTranslation()

  // const [defaultRangeState, setDefaultRangeState] = useState('default')
  const [defaultChart, setdefaultChart] = useState('barChart')

  const chartedData = data?.data?.map((el) => {
    return {
      timestamp: el[0],
      bandwidth: el[1],
      time: moment(el[0]).format('HH:mm'),
      date: moment(el[0]).format('MMMM D, YYYY'),
    }
  })

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='monitoring__custom-tooltip'>
          <span className='monitoring__custom-tooltip-timestamp'>
            {moment(payload[0].payload.timestamp).format('MMM D, HH:mm:ss')}
          </span>
          <span className='monitoring__custom-tooltip-bandwidth'>
            {humanizeBandwidthInMetrics(payload[0].value)}
          </span>
        </div>
      )
    }
    return null
  }

  const switchChart = (chartType: string) => {
    setdefaultChart(chartType)
  }

  // DATE PICKER
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startTime, setStartTime] = useState(
    new Date(new Date().getTime() - 1000 * 60 * 15).toLocaleTimeString()
  )

  const [endTime, setEndTime] = useState(new Date().toLocaleTimeString())

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const onChangeStartTime = (e) => {
    setStartTime(e.target.value)
  }

  const onChangeEndTime = (e) => {
    setEndTime(e.target.value)
  }

  const [open, setOpen] = useState(false)
  const handleOpenCustomRangeMenu = () => {
    setOpen(true)
  }

  // close and set date and time to default
  const handleCloseCustomRangeMenu = () => {
    setOpen(false)
  }

  // close and with custom time and date, and send request to metrics
  const handleAgree = () => {
    setOpen(false)
    const startTimeCustom = startTime.split(':').map((res) => {
      return parseInt(res)
    })

    const endTimeCustom = endTime.split(':').map((res) => {
      return parseInt(res)
    })
    refreshMetricsData(
      'custom',
      new Date(
        startDate.setHours(
          startTimeCustom[0],
          startTimeCustom[1],
          startTimeCustom[2]
        )
      ),
      new Date(
        endDate.setHours(endTimeCustom[0], endTimeCustom[1], endTimeCustom[2])
      )
    )
  }

  // UTILS
  const displayTime = (dateToConvert: string) => {
    return moment(dateToConvert).format('HH:mm')
  }

  const displayDate = (dateToConvert: string) => {
    return moment(dateToConvert).format('MMM D, YYYY')
  }

  return (
    <>
      <div className='monitoring__content'>
        <div className='monitoring__content--title'>
          {t('metrics.BANDWIDTH').toUpperCase()}
        </div>

        <div className='monitoring__content--action'>
          <StyledIconButton
            className='monitoring__content--refresh-button'
            onClick={() => refreshMetricsData()}>
            <RefreshIcon />
          </StyledIconButton>

          <StyledButton
            variant='contained'
            className={defaultRangeState === 'default' ? 'active' : ''}
            onClick={() => refreshMetricsData()}>
            {t('metrics.LAST_15_MIN')}
          </StyledButton>
          <StyledButton
            variant='contained'
            className={defaultRangeState === 'lastHour' ? 'active' : ''}
            onClick={() => refreshMetricsData('last_hour')}>
            {t('metrics.LAST_HOUR')}
          </StyledButton>
          <StyledButton
            variant='contained'
            className={defaultRangeState === 'lastDay' ? 'active' : ''}
            onClick={() => refreshMetricsData('last_day')}>
            {t('metrics.LAST_DAY')}
          </StyledButton>
          <StyledButton
            variant='contained'
            className={defaultRangeState === 'custom' ? 'active' : ''}
            startIcon={<CalendarIcon />}
            onClick={() => handleOpenCustomRangeMenu()}>
            {t('metrics.CUSTOM')}
          </StyledButton>
          <Dialog open={open} onClose={handleCloseCustomRangeMenu}>
            <DialogContent>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                minDate={minDate}
                maxDate={new Date()}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                showDisabledMonthNavigation
                shouldCloseOnSelect={false}
                calendarClassName='datepicker-custom'
                dayClassName={() => 'datepicker-day-custom'}
                weekDayClassName={() => 'datepicker-weekday-custom'}
              />
              <Divider
                sx={{
                  marginTop: '16px',
                  borderColor: 'var(--border-dialog)',
                  opacity: '0.6',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '24px 0',
                  background: 'var(--background-dialog)',
                }}>
                <span onChange={onChangeStartTime}>
                  <TextField
                    value={startTime}
                    type='time'
                    onChange={onChangeStartTime}
                    slotProps={{
                      htmlInput: {
                        style: {
                          padding: '8px 12px',
                        },
                      },
                    }}
                  />
                </span>
                <div> {'>'} </div>
                <span onChange={onChangeEndTime}>
                  <TextField
                    value={endTime}
                    type='time'
                    onChange={onChangeEndTime}
                    slotProps={{
                      htmlInput: {
                        style: {
                          padding: '8px 12px',
                        },
                      },
                    }}
                  />
                </span>
              </Box>
              <Divider sx={{ borderColor: 'var(--border-dialog)' }} />
            </DialogContent>
            <DialogActions sx={{ padding: '12px 24px 24px' }}>
              <Button variant='outlined' onClick={handleCloseCustomRangeMenu}>
                Clear
              </Button>
              <Button variant='contained' onClick={handleAgree} autoFocus>
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div className='monitoring__content--switch-graph'>
        <ButtonGroup variant='outlined'>
          <StyledIconButton
            className={defaultChart === 'barChart' ? 'active' : ''}
            onClick={() => switchChart('barChart')}>
            <BarChartIcon />
          </StyledIconButton>
          <StyledIconButton
            className={defaultChart === 'lineChart' ? 'active' : ''}
            onClick={() => switchChart('lineChart')}>
            <TimelineIcon />
          </StyledIconButton>
        </ButtonGroup>
      </div>
      {errorData ? (
        <div className='monitoring__content--no-data'>
          {t('metrics.ERROR_LOADING')}
        </div>
      ) : data?.data === null || data?.data.length === 0 ? (
        <div className='monitoring__content--no-data'>
          {t('metrics.NO_DATA')}
        </div>
      ) : (
        <div className='monitoring-cahrt-container'>
          <div className='monitoring__content--info-range'>
            {t('metrics.INPUT_FROM')} {displayDate(data?.data[0][0])} at{' '}
            {displayTime(data?.data[0][0])} at{' '}
            {displayDate(data?.data[data?.data.length - 1][0])} at{' '}
            {displayTime(data?.data[data?.data.length - 1][0])}
          </div>
          <div className='monitoring-chart'>
            {defaultChart === 'barChart' && (
              <ResponsiveContainer height={300}>
                <BarChart
                  data={chartedData}
                  margin={{
                    top: 30,
                    right: 0,
                    left: -12,
                    bottom: 20,
                  }}>
                  <CartesianGrid strokeDasharray='0.7' />

                  <XAxis dataKey='time'>
                    <Label
                      value={chartedData[0].date}
                      offset={-10}
                      position='insideBottom'
                    />
                  </XAxis>

                  <YAxis
                    type='number'
                    width={100}
                    domain={[0, 'dataMax + 200']}
                    tickFormatter={(tick) => humanizeBandwidthInTickChart(tick)}
                  />
                  <Tooltip
                    content={
                      <CustomTooltip
                        active={undefined}
                        payload={undefined}
                        label={undefined}
                      />
                    }
                  />
                  <Bar
                    dataKey='bandwidth'
                    fill='var(--root-color-action)'
                    fillOpacity={0.8}
                    activeBar={<Rectangle fillOpacity={1} />}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}

            {defaultChart === 'lineChart' && (
              <ResponsiveContainer height={300}>
                <LineChart
                  width={500}
                  height={300}
                  data={chartedData}
                  margin={{
                    top: 30,
                    right: 0,
                    left: 0,
                    bottom: 20,
                  }}>
                  <CartesianGrid strokeDasharray='0.7' />
                  <XAxis dataKey='time'>
                    <Label
                      value={chartedData[0].date}
                      offset={-7}
                      position='insideBottom'
                    />
                  </XAxis>
                  <YAxis
                    type='number'
                    width={100}
                    domain={[0, 'dataMax + 150']}
                    tickFormatter={(tick) => humanizeBandwidthInTickChart(tick)}
                  />
                  <Tooltip
                    content={
                      <CustomTooltip
                        active={undefined}
                        payload={undefined}
                        label={undefined}
                      />
                    }
                  />
                  <Line
                    type='linear'
                    dataKey='bandwidth'
                    stroke='var(--root-color-action)'
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>

          <div className='monitoring__infos'>
            <div className='monitoring__infos-content'>
              <div className='monitoring__infos-content-min'>
                <span>{t('metrics.MIN')} </span>
                <span>
                  {data?.min ? humanizeBandwidthInMetrics(data?.min[1]) : '-'}
                </span>
              </div>
            </div>
            <Divider
              sx={{
                width: '1px',
                backgroundColor: 'var(--background-primary)',
              }}
            />
            <div className='monitoring__infos-content'>
              <div className='monitoring__infos-content-max'>
                <span>{t('metrics.MAX')} </span>
                <span>
                  {data?.max ? humanizeBandwidthInMetrics(data?.max[1]) : '-'}
                </span>
              </div>
            </div>
            <Divider
              sx={{
                width: '1px',
                backgroundColor: 'var(--background-primary)',
              }}
            />
            <div className='monitoring__infos-content'>
              <div className='monitoring__infos-content-max'>
                <span>{t('metrics.PRODUCT')} </span>
                <span>
                  {bandwidth !== false ? humanizeBandwidth(bandwidth) : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
