// -- Import NPM
import { useTranslation } from 'react-i18next'

// Import Components
import { useParams } from 'react-router-dom'
import AdministrativeStatus from '../../../components/chips/administrativeStatus'
import OperationalStatus from '../../../components/chips/operationalStatus'
import { displayElementType } from '../../../hooks/displayElementType'
import { DisplayElementIcon } from '../../../hooks/displayIcons'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useGetElementOperationalStatusMetricsQuery } from '../../../slices/MetricsApiSlice'
import type { CloudElement, TransportElementV2 } from '../../../slices/types'

// Import Assets

type AccordionCloudProps = {
  data: CloudElement
  transportData?: TransportElementV2[]
}

export default function CloudSummary({ data }: AccordionCloudProps) {
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()

  const { data: operationalStatusData } =
    useGetElementOperationalStatusMetricsQuery(
      {
        accountId: currentAccount?.id,
        elementType: 'node',
        id: data?.id,
        workspaceId: workspaceId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !data || data.administrativeState !== 'deployed',
      }
    )

  return (
    <>
      <div className='accordion__container-space-between'>
        <div className='accordion__container-show'>
          <div className='accordion__element-type'>
            <DisplayElementIcon
              kind={data?.kind}
              type={data?.type}
              csp={data?.product?.cspName}
            />
          </div>

          <div className='accordion__element-details'>
            <div className='accordion__element-details-container'>
              <div className='accordion__element-details--type'>
                {displayElementType(data?.kind, data?.type, t)}
              </div>
              <div className='accordion__element-details--name'>
                {data?.name}
              </div>
            </div>

            <div className='accordion__element-details-container'>
              {
                <div className='accordion__element-details--sm'>
                  {data?.product?.cspRegion ? data?.product?.cspRegion : ' - '}
                </div>
              }
              <div className='accordion__element-details--sm'> | </div>
              {
                <div className='accordion__element-details--sm'>
                  {data?.product?.bandwidth
                    ? humanizeBandwidth(data?.product?.bandwidth)
                    : ' - '}
                </div>
              }
            </div>
          </div>
        </div>

        <div className='accordion__container-status'>
          <div className='accordion__element-state'>
            <OperationalStatus
              status={operationalStatusData?.status}
              showStatusText={true}
            />
          </div>
          <div className='accordion__element-state accordion__element-state--fixed-width'>
            <AdministrativeStatus status={data?.administrativeState} />
          </div>
        </div>
      </div>
    </>
  )
}
