import styled from '@emotion/styled'
import { Checkbox, FormControlLabel } from '@mui/material'

export const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: 'var(--root-color-action)',
  '&.Mui-disabled': {
    color: 'var(--content-primary)',
  },
}))

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    color: 'var(--content-primary)',
    '&.MuiFormControlLabel-root': {
      '&.Mui-disabled': {
        color: 'var(--content-primary)',
        opacity: 0.4,
      },
    },
  },
}))
