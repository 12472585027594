// IMPORT DEPENDENCIES
import { Button, IconButton } from '@mui/material'
import type { Dispatch, MouseEventHandler, SetStateAction } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'
import type { CreationState } from '../DrawerCreateElementV2'

// IMPORT ICONS
import ChevronLeftIcon from '../../../assets/icons-io-v1/chevron_left_2.svg?react'
import {
  StyledInputLabel,
  StyledTextField,
} from '../../forms/StyledFormComponents'

// TYPES
type ConfigureCloudTechnicalInfoProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: MouseEventHandler<HTMLButtonElement>
}

export default function ConfigureCloudTechnicalInfo({
  newElementData,
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: ConfigureCloudTechnicalInfoProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handClickBack = useCallback(
    (creationStep: number) => {
      setNewElementData((previous) => ({
        ...previous,
        cspName: '',
        cspCity: '',
        cspRegion: '',
        bandwidth: '',
        provider: '',
        location: '',
      }))
      setCreationStep(creationStep)
      navigate(-1)
    },
    [navigate, setCreationStep, setNewElementData]
  )

  const setTechnicalInfo = (value: string) => {
    setNewElementData((previous) => ({
      ...previous,
      elementTechInfo: value,
    }))
  }

  const handleSetTechInfo = useCallback(() => {
    setCreationStep(1)
    navigate(-1)
  }, [navigate, setCreationStep])

  return (
    <>
      <div className='drawer-create__wrapper'>
        <div className='show__element__container-close'>
          <IconButton
            onClick={toggleDrawer}
            sx={{ padding: '0', height: '24px' }}>
            <CloseIcon fontSize='small' style={{ width: '22px' }} />
          </IconButton>
        </div>
        <div>
          <div className='drawer-create__header'>
            <div
              className='drawer-create__header--link'
              onClick={() => handClickBack(1)}>
              <ChevronLeftIcon />
            </div>
            {/* // TOTO - ADD LOCALS*/}
            Technical Info
          </div>
        </div>
        <>
          {newElementData?.elementProduct?.cspName === 'AWS' && (
            <div className='workspace__form-csp-config'>
              <div className='workspace__form__container--field'>
                <StyledInputLabel
                  shrink
                  htmlFor='element-form-csp-aws-account-id'>
                  {t('element.CSP_ACCOUNT_ID')}
                </StyledInputLabel>
                <StyledTextField
                  required
                  id='element-form-csp-aws-account-id'
                  variant='outlined'
                  size='small'
                  defaultValue={newElementData?.elementTechInfo || ''}
                  onChange={(e) => setTechnicalInfo(e.target.value)}
                  slotProps={{
                    htmlInput: {
                      form: {
                        autocomplete: 'off',
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}

          {newElementData?.elementProduct?.cspName === 'GCP' && (
            <div className='workspace__form-csp-config'>
              <div className='workspace__form__container--field'>
                <StyledInputLabel
                  shrink
                  htmlFor='element-form-csp-gcp-pairing-key'>
                  {t('element.PAIRING_KEY')}
                </StyledInputLabel>
                <StyledTextField
                  required
                  id='element-form-csp-gcp-pairing-key'
                  variant='outlined'
                  size='small'
                  defaultValue={newElementData?.elementTechInfo || ''}
                  onChange={(e) => setTechnicalInfo(e.target.value)}
                  slotProps={{
                    htmlInput: {
                      form: {
                        autocomplete: 'off',
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}

          {newElementData?.elementProduct?.cspName === 'AZURE' && (
            <div className='workspace__form-csp-config'>
              <div className='workspace__form__container--field'>
                <StyledInputLabel
                  shrink
                  htmlFor='element-form-csp-azure-service-key'>
                  {t('element.SERVICE_KEY')}
                </StyledInputLabel>
                <StyledTextField
                  required
                  id='element-form-csp-azure-service-key'
                  variant='outlined'
                  size='small'
                  defaultValue={newElementData?.elementTechInfo || ''}
                  onChange={(e) => setTechnicalInfo(e.target.value)}
                  slotProps={{
                    htmlInput: {
                      form: {
                        autocomplete: 'off',
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
        <div className='drawer-create__techInfo-save'>
          <Button variant='contained' onClick={handleSetTechInfo}>
            {t('element.SAVE')}
          </Button>
        </div>
      </div>
    </>
  )
}
