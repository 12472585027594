import { apiSlice } from './apiSlice'
import type { CheckPassword, SendResetPassword, SetPassword } from './types'
import { API_PATH_V1, API_PATH_CUSTOMERS_TOKEN } from '../environments/env'

export const setPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    setPassword: builder.mutation<
      SetPassword,
      { token: string; password: string; cgu?: boolean }
    >({
      query: ({ password, token, cgu }) => ({
        url: `${API_PATH_V1}/password/${token}`,
        method: 'POST',
        body: {
          password,
          cgu,
        },
      }),
    }),
    checkPassword: builder.mutation<CheckPassword, { password: string }>({
      query: ({ password }) => ({
        url: `${API_PATH_V1}/password/check`,
        method: 'POST',
        body: {
          password,
        },
      }),
    }),
    checkTokenValidity: builder.query({
      query: (token: string) => ({
        url: `${API_PATH_CUSTOMERS_TOKEN}/${token}/check`,
      }),
    }),
    sendResetPassword: builder.mutation<SendResetPassword, { email: string }>({
      query: ({ email }) => ({
        url: `${API_PATH_V1}/password/reset`,
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),
  }),
})

export const {
  useSetPasswordMutation,
  useSendResetPasswordMutation,
  useCheckTokenValidityQuery,
  useCheckPasswordMutation,
} = setPasswordApiSlice
