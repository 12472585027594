// IMPORT NPM
import { IconButton } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'

// IMPORT COMPONENTS
import { displayElementType } from '../../../hooks/displayElementType'
import { DisplayElementIcon } from '../../../hooks/displayIcons'
import OperationalStatus from '../../chips/operationalStatus'
import DetailsTabMetrics from './DetailsTabMetricsV2'
import DetailsTabProperties from './DetailsTabPropertiesV2'

// IMPORT ICONS
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'

// TYPES
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useGetElementOperationalStatusMetricsQuery } from '../../../slices/MetricsApiSlice'
import type {
  AccessElementV2,
  Attachment,
  CloudElementV2,
  Kind,
  PortElementV2,
  TransportV2,
  WorkspaceV2,
} from '../../../slices/types'
import DetailsTabErrors from './DetailsTabErrorsV2'
type ElementDetailsProps = {
  elementData?:
    | AccessElementV2
    | CloudElementV2
    | TransportV2
    | PortElementV2
    | Attachment
  workspaceData?: WorkspaceV2
  handleClose: () => void
}

enum DetailsTabNames {
  properties = 'properties',
  metrics = 'metrics',
  errors = 'errors',
}

const Tab = ({
  current,
  value,
  i18nLabel,
  basePath,
}: {
  current: string
  value: DetailsTabNames
  i18nLabel: string
  basePath: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onClick = useCallback(() => {
    navigate(`${basePath}/${value}`)
  }, [basePath, navigate, value])

  return (
    <li
      className={current === value ? 'show__element--tabs-active' : ''}
      onClick={onClick}>
      {t(i18nLabel)}
    </li>
  )
}

export default function ElementDetails({
  elementData,
  workspaceData,
  handleClose,
}: ElementDetailsProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const {
    workspaceId,
    elementId,
    type: elementType,
    viewKind,
    detailsTabView,
  } = useParams()

  const location = useLocation()

  const matchesViewWorkspace = useMatch(
    '/workspaces/:workspaceId/view/:viewKind/details/:type/:elementId/*'
  )
  const matchesViewPort = useMatch('/ports/details/:type/:elementId/*')

  const basePath = useMemo(() => {
    if (matchesViewWorkspace) {
      return matchesViewWorkspace.pathnameBase
    } else if (matchesViewPort) {
      return matchesViewPort.pathnameBase
    }
  }, [matchesViewPort, matchesViewWorkspace])

  useEffect(() => {
    if (detailsTabView) {
      return
    }

    if (matchesViewWorkspace) {
      navigate(
        `/workspaces/${workspaceId}/view/${viewKind}/details/${elementType}/${elementId}/${DetailsTabNames.properties}`
      )
    } else if (matchesViewPort) {
      navigate(`/ports/details/port/${elementId}/${DetailsTabNames.properties}`)
    }
  }, [
    detailsTabView,
    navigate,
    elementId,
    elementType,
    workspaceId,
    viewKind,
    location,
    matchesViewWorkspace,
    matchesViewPort,
  ])

  const dataKind: Exclude<Kind, 'service-provider'> = elementData?.data?.kind

  const { data: operationalStatusData } =
    useGetElementOperationalStatusMetricsQuery(
      {
        accountId: currentAccount?.id,
        elementType: dataKind,
        id: elementId,
        workspaceId: workspaceId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip:
          !elementData?.data ||
          elementData?.data?.administrativeState !== 'deployed',
      }
    )

  const dataProductCspName =
    elementData &&
    'product' in elementData?.data &&
    elementData?.data?.product?.cspName
      ? elementData?.data?.product?.cspName
      : ''
  const dataType =
    elementData?.data?.kind === 'node' ? elementData?.data?.type : ''

  const getElementIcon = useMemo(() => {
    if (elementType === 'port') {
      return <DisplayElementIcon kind='port' />
    } else if (elementType === 'attachments') {
      return <DisplayElementIcon kind='attachments' />
    } else {
      return (
        <DisplayElementIcon
          kind={elementData?.data?.kind}
          type={dataType}
          csp={dataProductCspName}
        />
      )
    }
  }, [dataProductCspName, dataType, elementData?.data?.kind, elementType])

  const getElementType = useMemo(() => {
    if (dataKind === 'port') {
      return displayElementType('port', 'PHYSICAL', t)
    } else if (dataKind === 'attachment') {
      return displayElementType('attachments', '', t)
    } else {
      return displayElementType(
        dataKind,
        dataKind === 'node' ? dataType : '',
        t
      )
    }
  }, [dataKind, dataType, t])

  const showMetricTab = !!(
    (elementData?.data.kind === 'node' || elementData?.data.kind === 'port') &&
    elementData?.data.id &&
    elementData?.data.deployedAt
  )

  if (!elementData?.data) {
    return
  }

  return (
    <div className='show__element__container'>
      <div className='show__element__container-close'>
        <IconButton onClick={handleClose} sx={{ padding: '0', height: '24px' }}>
          <CloseIcon fontSize='small' style={{ width: '22px' }} />
        </IconButton>
      </div>

      <div className='show__element--header'>
        <div className='show__element--header-icon'>{getElementIcon}</div>

        <div className='show__element--header-infos'>
          <div className='show__element--header-infos-title'>
            <div className='show__element--header-infos-title-type'>
              {getElementType}
            </div>

            <div className='show__element--header-infos-title-name'>
              {elementData?.data?.kind !== 'attachment'
                ? elementData?.data.name
                : ''}
            </div>
          </div>

          <div className='show__element--header-infos-state'>
            {elementData?.data?.kind !== 'attachment' ? (
              <OperationalStatus
                status={operationalStatusData?.status}
                showStatusText={true}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div className='show__element--tabs-container'>
        <ul>
          <Tab
            current={detailsTabView}
            value={DetailsTabNames.properties}
            i18nLabel={'element.PROPERTIES'}
            basePath={basePath}
          />
          {showMetricTab && (
            <Tab
              current={detailsTabView}
              value={DetailsTabNames.metrics}
              i18nLabel={'element.METRICS'}
              basePath={basePath}
            />
          )}
          {elementData?.data?.kind !== 'attachment' &&
            elementData.data.error && (
              <Tab
                current={detailsTabView}
                value={DetailsTabNames.errors}
                i18nLabel={'element.ERRORS'}
                basePath={basePath}
              />
            )}
        </ul>
      </div>

      {detailsTabView === DetailsTabNames.properties && (
        <DetailsTabProperties
          elementData={elementData}
          workspaceData={workspaceData}
          elementType={elementType as Kind}
        />
      )}

      {(detailsTabView === DetailsTabNames.metrics &&
        showMetricTab &&
        elementData?.data.kind === 'node') || // TS
        (elementData?.data.kind === 'port' && ( // TS
          <DetailsTabMetrics
            elementId={elementData.data.id}
            deployedAt={new Date(elementData.data.deployedAt)}
            bandwidth={elementData.data.product?.bandwidth ?? false}
            elementTypeMetric={`${elementData.data.kind}s`}
          />
        ))}

      {detailsTabView === DetailsTabNames.errors &&
        elementData?.data?.kind !== 'attachment' && (
          <DetailsTabErrors code={elementData.data.error?.code} />
        )}
    </div>
  )
}
