// -- Import NPM
import { Divider } from '@mui/material'

// -- Import COMPONENTS
import { useTranslation } from 'react-i18next'

type DetailsTabErrorsProps = {
  code: string
}

export default function DetailsTabErrors({ code = '' }: DetailsTabErrorsProps) {
  const { t } = useTranslation()
  if (!code) {
    return
  }
  return (
    <>
      <Divider sx={{ width: '100%', margin: '9px 0 32px 0' }} />
      <div className='show__element--content'>
        <div
          className='show__element--subtitle'
          style={{ textTransform: 'uppercase' }}>
          {t('element.ERROR_CODE')}
        </div>
      </div>
      <div
        className='show__element__infos-content'
        style={{ paddingBottom: '2rem' }}>
        {code}
      </div>

      <div className='show__element--content'>
        <div
          className='show__element--subtitle'
          style={{ textTransform: 'uppercase' }}>
          {t('element.ERROR_MESSAGE')}
        </div>
      </div>
      <div
        className='show__element__infos-content'
        style={{ maxWidth: '500px' }}>
        {t(`messages.errors.${code}`)}
      </div>
    </>
  )
}
