export type User = {
  device?: string
  otpEnabled?: boolean
  otpVerified?: boolean
  otpAuthURL?: string
  otpCreatedAt?: string
  otpSecret?: string
  id: string
  name: string
  email: string
  isAdmin: boolean
  accountId: string
  createdAt: string
  updatedAt: string
  profile: string
  profileId?: string
  lastConnection?: string
}

export type Account = {
  id: string
  name: string
}

export type NatureOfElementType = {
  family: string
  type?: string
}

export type Profiles = {
  id: string
  name: string
}

export type WorkspaceDataAPI = {
  terms: WorkspaceV2['terms']
  data: Omit<WorkspaceV2['data'], 'transports' | 'nodes' | 'attachments'> & {
    nodes: (Omit<CloudElement, 'kind'> | Omit<AccessElement, 'kind'>)[]
    transports: Omit<TransportElementV2, 'kind' | 'attachments'>[]
    attachments: Omit<AttachmentElementData, 'kind'>[]
  }
}

export type WorkspaceV2API = {
  terms: string
  data: WorkspaceDataAPI
}

export type WorkspaceV2 = {
  terms: string
  data: WorkspaceData
}
export type WorkspaceData = {
  accountId: string
  createdAt: string
  description: string
  id: string
  name: string
  updatedAt: string
  nodes: (CloudElement | AccessElement)[]
  transports: Omit<TransportElementV2, 'attachments'>[]
  attachments: AttachmentElementData[]
  usersCount?: number
  price?: number
  status?: number
}

export type PortsV2 = {
  terms: string
  data: PortElement[]
}

export type WorkspaceSetting = {
  id: string
  description: string
  name: string
}

export type ElementError = {
  code: string
  msg: string
}

export type Element = {
  administrativeState?: string
  attachment?: AttachmentElementData
  attachments?: AttachmentElementData[]
  bandwidth?: number
  createdAt?: string
  deletedAt?: string
  deployedAt?: string
  duration?: number
  error?: ElementError
  family?: string
  id: string
  location?: string
  name?: string
  priceMrc?: number
  priceNrc?: number
  provider?: string
  updatedAt?: string
  version?: number
}

type CommonNode = {
  vlan: number // Vlan on cloud ??
}

export type AccessElement = {
  type: NodeType.Access
  physicalPort: PortElement
  // vlan: number
  // workspaceId: string
  serviceKey?: ServiceKeyData
  product: ProductElement
} & Element &
  CommonNode & {
    kind: 'node'
  }

export type AccessElementV2 = {
  terms: string
  data: AccessElement & {
    kind: 'node'
  }
}

export type ServiceKeyData = {
  id: string
  expirationDate: string
  name: string
}

export type AccessElementDataV2 = AccessElementV2['data']

export type CloudElement = {
  type: NodeType.Cloud
  dxconId?: string
  providerConfig: ProviderConfigs
  product: ProductElement
} & Element &
  CommonNode & {
    kind: 'node'
  }

export type CloudElementV2 = {
  terms: string
  data: CloudElement & {
    kind: 'node'
  }
}

export type Kind =
  | 'node'
  | 'transport'
  | 'port'
  | 'attachment'
  | 'service-provider'

export type CloudElementDataV2 = CloudElementV2['data']

export type PortPositionElement = {
  deviceName?: string
  portName?: string
  patchPanel?: {
    name?: string
    rxPort?: number
    txPort?: number
  }
}

export type PortElementV2 = {
  terms: string
  data: PortElement
}

export type PortElement = Element & {
  id?: string
  accountId?: string
  kind: 'port'
  usedVlans?: string[]
  availableBandwidth?: number
  portPosition?: PortPositionElement
  nodes?: PortsNodesAttachment[]
  product: ProductElement
}

export type PortsNodesAttachment = {
  id: string
  name: string
  type: string //@refacto
  administrativeState: string
  bandwidth: number
  workspaceId: string
}

export type PortAddElement = {
  name: string
  accoundId?: string
  portType?: string
  product: {
    sku: string
  }
}

export type VLANs = {
  aVlan: number
  zVlan: number
}

export type TransportElementV2 = {
  kind: 'transport'
  vlans: VLANs
  isLocal: boolean
  attachments: AttachmentElementData
  product: ProductElement
} & Element

export type TransportV2 = {
  terms: string
  data: TransportElementV2
}

// @refacto in global
export enum NodeType {
  Access = 'access',
  Cloud = 'cloud',
  Bridge = 'bridge',
  Router = 'router',
}

export type AccessElementTest = {
  tpe
}
export type CloudElementTest = {}

export type NodeElement = AccessElement | CloudElement

export type ConcatElementV2 =
  | WorkspaceV2['data']['nodes'][0]
  | WorkspaceV2['data']['transports'][0]
  | WorkspaceV2['data']['attachments'][0]
  | PortElementV2['data']

export type Profile = {
  id: string
  name: string
}

export type SetPassword = {
  password: string
  token: string
  cgu: boolean
}
export type CheckPassword = {
  password: string
}

export type SendResetPassword = {
  email: string
}

export type CredentialsResponse = {
  accessToken: {
    token: string
    expirationDate: string
  }
}

export enum SseEventTypes {
  ElementUpdated = 'element_updated',
}

export enum UserProfileType {
  AccountView = 'account_viewer',
  AccountAdmin = 'account_admin',
}

export enum WorkspaceEditActions {
  None = '',
  EditElement = 'editElement',
  EditUserProfile = 'editUserProfile',
  AddElement = 'addElement',
  AddUserProfile = 'addUserProfile',
}

export enum WorkspaceAddActions {
  None = '',
  AddElement = 'addElement',
  AddUserProfile = 'addUserProfile',
}

export enum WorkspaceStates {
  draft = 'draft',
  deployed = 'deployed',
  pending = 'pending',
  deployable = 'deployable',
  failed = 'failed',
  undeployed = 'undeployed',
  undeploying = 'undeploying',
  undeployable = 'undeployable',
}

export enum OverviewTabVars {
  RAD = 'review-and-deploy',
}

export enum ElementStates {
  creation_pending = 'creation_pending',
  creation_proceed = 'creation_proceed',
  creation_error = 'creation_error',
  deployed = 'deployed',
  delete_pending = 'delete_pending',
  delete_proceed = 'delete_proceed',
  delete_error = 'delete_error',
  deleted = 'deleted',
}

export enum PortStates {
  creation_pending = 'creation_pending',
  creation_proceed = 'creation_proceed',
  creation_error = 'creation_error',
  created = 'created',
  delete_pending = 'delete_pending',
  delete_proceed = 'delete_proceed',
  delete_error = 'delete_error',
  deleted = 'deleted',
}

// le type qu'on doit retourner au back lors de l'update d'un element cloud
export type ProductElement = {
  locationUnderlay?: string
  cspNameUnderlay?: string
  sku: string
  duration: number
  sla: null
  bandwidth: number
  provider: string
  location: string
  locationTo?: string
  cspName: string
  cspRegion: string
  cspCity: string
  priceMrc: number
  priceNrc: number
  portType?: string
  date: string
  type: string
}

// le type pour les ProviderConfigs

export type ProviderConfigs =
  | {
      accountId: string
    }
  | {
      serviceKey: string
    }
  | {
      pairingKey: string
    }

// type de la réponse du back sur la route useGetCloudProductsQuery
export type AvailableProduct = {
  id?: number
  provider?: string
  duration?: number
  location?: string
  bandwidth?: number
  cspNameUnderlay?: string
  date?: string
  priceNrc?: number
  priceMrc?: number
  costNrc?: number
  costMrc?: number
  sla?: string
  sku?: string
  cspName?: 'AWS' | 'AZURE' | 'GCP'
  cspCity?: string
  cspRegion?: string
  locationUnderlay?: string
  locationToUnderlay?: string
  locationTo?: string
  portType?: string
  type?: string
}

// type du payload à envoyer sur la route useGetCloudProductsQuery
export type CloudQueryParams = {
  [key: string]: string | number
  bandwidth?: number
  cspCity?: string
  cspName?: string
  cspRegion?: string
  date?: string
  location?: string
  provider?: string
  sku?: string
}

// type du filters object pour les PortsFilters
export type PortsFilters = {
  id: number
  filter: string
}

// type du payload à envoyer sur la route useGetCloudProductsQuery
export type UsersFilters = {
  admin: boolean
}

export // type du payload à envoyer sur la route useGetCloudProductsQuery
type AccessQueryParams = {
  [key: string]: string | number
  provider?: string
  location?: string
  bandwidth?: number
  sku?: string
}

// type du payload à envoyer sur la route useGetTransportProductsQuery
export type TransportQueryParams = {
  [key: string]: string | number
  bandwidth?: number
  date?: string
  location?: string
  locationTo?: string
  provider?: string
  sku?: string
}

// type du payload à envoyer sur la route useGetCloudProductsQuery
export type PortsQueryParams = {
  [key: string]: string | number
  portBandwidth?: number
  portType?: string
  portLocation?: string
}

export type mapConfigType = {
  map: {}
  projection: string
  projectionConfig: {
    rotate?: [number, number, number]
    center?: [number, number]
    scale?: number
  }
}

export type mapCityMarker = {
  locationId: number
  name: string
  metro: string
  country: string
  longitude: number
  latitude: number
}

export type mfaPayload = {
  device: string
  otpAuthUrl: string
  otpCreatedAt: string
  keySecret?: string
}

export type MetricsData<T extends number | boolean> = {
  unit: string
  data: [string, T][]
  max: [string, number]
  min: [string, number]
}

export type NotificationsData = {
  accountId: string
  time: string
  workspaceId: string
  workspaceVersion: number
  node: {}
  transprt: {}
}

export type Attachment = {
  terms: string
  data: AttachmentElementData
}

export type AttachmentElementData = {
  id: string
  kind: 'attachment'
  createdAt: string
  updatedAt: string
  transportId: string
  nodeId: string
  administrativeState: ElementStates
  side: string
}

export type ErrorDetails = {
  field: string
  msg: string
  reason: string
}

export type ErrorData = {
  statusCode: number
  requestId: string
  code: string
  msg: string
  details: ErrorDetails[]
}
export type PersonalToken = {
  id: string
  createdAt: string
  expiredAt: string
  label: string
  description: string
  pat?: string
}

export type CreateTokenData = {
  label: string
  description: string
  expiredAt: Date
}

export type CreateTokenParams = {
  accountId: string
  userId: string
}

export type RevokeTokenParams = CreateTokenParams & {
  tokenId: string
}

export type ServiceProvider = {
  id: string
  kind: 'service-provider'
  administrativeState: ElementStates.deployed
  cspName: 'AWS' | 'AZURE' | 'GCP' | string
  provider: string
  name: string
}

export type CloudCreateFormData = {
  type: 'cloud'
  name: string
  product: { sku: string }
  providerConfig: ProviderConfigs
}

export type AccessCreateFormData =
  | {
      type: 'access'
      name: string
      product: { sku: string }
      physicalPortId: string
      vlan: number
    }
  | {
      type: 'access'
      name: string
      product: { sku: string }
    }

export type TransportCreateFormData = {
  type: 'transport'
  name: string
  product: { sku: string }
}

export type AttachmentCreateFormData = {
  nodeId: string
  transportId: string
}

export type OperationalStatus = 'up' | 'down' | 'unknown'
