// IMPORT DEPENDENCIES
import { MenuItem } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useMeiliSearch, { ProductCatalog } from '../../../hooks/useMeiliSearch'

// ASSETS
import { displayProviderLogo } from '../../../hooks/displayIcons'

// ICONS
import SortIcon from '../../../assets/icons-io-v1/arrow_swap_vertical.svg?react'

// TYPES
import type { SearchParams } from 'meilisearch/dist/types/types'
import { useNavigate } from 'react-router-dom'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import type { PortsQueryParams } from '../../../slices/types'
import SelectedFilter from '../../fields/SelectedFilter'
import { StyledTextField, selectProps } from '../../forms/StyledFormComponents'
import type { CreationState } from '../DrawerCreateElementV2'

type ConfigurePortProductProps = {
  newElementData: CreationState
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  setCreationStep: Dispatch<SetStateAction<number>>
}

interface formInput {
  name: string
  provider: string
  location: string
  bandwidth: string
  portType: string
}

export default function ConfigurePortProduct({
  newElementData,
  setNewElementData,
  setCreationStep,
}: ConfigurePortProductProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { register, reset, control } = useForm<formInput>({
    shouldUseNativeValidation: true,
  })

  const [portsQueryParams, setPortsQueryParams] = useState<PortsQueryParams>({})
  // MEILI SEARCH CONFIG
  const searchQuery: string = ''
  const [facetSearch, setFacetSearch] = useState<SearchParams>({
    filter: [],
    facets: ['location', 'bandwidth'],
  })

  const meiliSearchResponse = useMeiliSearch(
    searchQuery,
    facetSearch,
    ProductCatalog.Port
  )
  const {
    data: { hits: searchResults, facetDistribution: facetDistri },
  } = meiliSearchResponse

  useEffect(() => {
    const filter = Object?.entries(portsQueryParams ?? {})
      .map(([key, val]) => {
        return `${key} = "${val}"`
      })
      .join(' AND ')

    setFacetSearch((prevFacetSearch) => ({
      ...prevFacetSearch,
      filter,
    }))
  }, [portsQueryParams])

  const handleChange = useCallback(
    (name: string, value: string) => {
      setNewElementData((previous) => ({ ...previous, [name]: value }))
      setPortsQueryParams((previous) => ({ ...previous, [name]: value }))
    },
    [setNewElementData]
  )

  const handleClearFacet = useCallback(
    (facet: string) => {
      setNewElementData((previous) => ({ ...previous, [facet]: '' }))
      const { [facet]: _, ...portsQueryParamsBis } = portsQueryParams
      setPortsQueryParams({ ...portsQueryParamsBis })
      reset({ [facet]: '' })
    },
    [portsQueryParams, reset, setNewElementData]
  )

  // --------------------------------------------------------------
  // ----- HANDLE THE ADDITION OF THE PRODUCT AND REDIRECTION -----
  const handleAddProduct = useCallback(
    (elementProduct) => {
      setNewElementData((previous) => ({
        ...previous,
        elementProduct,
        bandwidth: '',
        location: '',
      }))
      setCreationStep(0)
      navigate(-1)
    },
    [navigate, setCreationStep, setNewElementData]
  )

  // ----------------------------------------------
  // ----- LOGIQUE TO SORT THE DATA BY COLUMN -----
  const [mySortedData, setMySortedData] = useState(searchResults)
  const [mySortedFilter, setMySortedFilter] = useState('')
  const [sortAscDesc, setSortAscDesc] = useState(true)
  useEffect(() => {
    setMySortedData(searchResults)
  }, [searchResults])

  const sortMyData = useCallback((sortBy: string) => {
    setMySortedFilter(sortBy)
    setSortAscDesc((previous) => !previous)
  }, [])

  useEffect(() => {
    if (
      mySortedFilter === 'priceMrc' ||
      mySortedFilter === 'bandwidth' ||
      mySortedFilter === 'duration'
    ) {
      if (sortAscDesc) {
        setMySortedData(
          (previous) =>
            previous &&
            [...previous].sort((a, b) => b[mySortedFilter] - a[mySortedFilter])
        )
      } else {
        setMySortedData(
          (previous) =>
            previous &&
            [...previous].sort((a, b) => a[mySortedFilter] - b[mySortedFilter])
        )
      }
    } else {
      if (sortAscDesc) {
        setMySortedData(
          (previous) =>
            previous &&
            [...previous].sort((a, b) =>
              a[mySortedFilter].localeCompare(b[mySortedFilter])
            )
        )
      } else {
        setMySortedData(
          (previous) =>
            previous &&
            [...previous].sort((a, b) =>
              b[mySortedFilter].localeCompare(a[mySortedFilter])
            )
        )
      }
    }
  }, [mySortedFilter, sortAscDesc])

  // ----- END LOGIQUE TO SORT THE DATA BY COLUMN -----
  // --------------------------------------------------

  return (
    <>
      {/* ----- FILTERS ----- */}
      <>
        <div className='drawer-create__containers'>
          <div className='drawer-create__titles'>
            <span>{t('element.FILTERS')} :</span>
          </div>
          <div className='drawer-create__columns-filters'>
            {newElementData?.location === '' ? (
              <div className='drawer-create__columns-filter'>
                <Controller
                  name='location'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      select
                      label={t('element.LOCATION')}
                      size='small'
                      slotProps={{
                        select: selectProps,
                      }}
                      {...register('location')}>
                      {facetDistri?.location !== undefined ? (
                        Object.keys(facetDistri?.location).map((location) => {
                          return (
                            <MenuItem
                              key={location}
                              value={location}
                              onClick={() =>
                                handleChange('location', location)
                              }>
                              {location}
                            </MenuItem>
                          )
                        })
                      ) : (
                        <div />
                      )}
                    </StyledTextField>
                  )}
                />
              </div>
            ) : (
              <SelectedFilter
                newElementData={newElementData}
                handleClearFacet={handleClearFacet}
                legend={t('element.LOCATION')}
              />
            )}

            {newElementData?.bandwidth === '' ? (
              <div className='drawer-create__columns-filter'>
                <Controller
                  name='bandwidth'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      select
                      label={t('element.BANDWIDTH')}
                      size='small'
                      slotProps={{
                        select: selectProps,
                      }}
                      {...register('bandwidth')}>
                      {facetDistri?.bandwidth !== undefined ? (
                        Object.keys(facetDistri?.bandwidth).map((bandwidth) => {
                          return (
                            <MenuItem
                              key={bandwidth}
                              value={bandwidth}
                              onClick={() =>
                                handleChange('bandwidth', bandwidth)
                              }>
                              {bandwidth}
                            </MenuItem>
                          )
                        })
                      ) : (
                        <div />
                      )}
                    </StyledTextField>
                  )}
                />
              </div>
            ) : (
              <SelectedFilter
                newElementData={newElementData}
                handleClearFacet={handleClearFacet}
                legend={t('element.BANDWIDTH')}
              />
            )}
          </div>
        </div>
      </>

      {/* ----- FILTERED RESULTS ----- */}
      <div
        className={`drawer-products__containers ${
          Object.keys(portsQueryParams).length >= 1
            ? 'drawer-products__containers--border-bottom'
            : ''
        }`}>
        {Object?.keys(portsQueryParams).length >= 1 ? (
          <>
            <div className='drawer-products__card header'>
              <div className='drawer-products__card--header'>{''}</div>
              <div className='drawer-products__card--header'>
                {t('element.PROVIDER')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'provider'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('provider')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.LOCATION')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'location'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('location')}
                />
              </div>

              <div className='drawer-products__card--header'>
                {t('element.BANDWIDTH')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'bandwidth'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('bandwidth')}
                />
              </div>

              <div className='drawer-products__card--header'>
                {t('element.MRC')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'priceMrc'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('priceMrc')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.COMMITMENT')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'duration'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('duration')}
                />
              </div>
            </div>
            {mySortedData.map((result) => {
              return (
                <div
                  key={result.id}
                  className='drawer-products__card'
                  onClick={() => handleAddProduct(result)}>
                  <div className='drawer-products__card--col'>
                    <div className='drawer-products__card--provider'>
                      {displayProviderLogo(result?.provider)}
                    </div>
                  </div>
                  <div className='drawer-products__card--col'>
                    {result?.provider}
                  </div>
                  <div className='drawer-products__card--col'>
                    {result?.location}
                  </div>

                  <div className='drawer-products__card--col'>
                    {humanizeBandwidth(result?.bandwidth)}
                  </div>

                  <div className='drawer-products__card--col'>
                    {result?.priceMrc}
                    {' €'}
                  </div>

                  <div className='drawer-products__card--col'>
                    {t('ports.messages.MONTH', {
                      count: result?.duration,
                    })}
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <div className='drawer-products__filters-message'>
            Please select a filter.
          </div>
        )}
      </div>
    </>
  )
}
