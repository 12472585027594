import moment from 'moment'

const JumboCardStatus = ({ label, date }) => {
  return (
    <div className='show__element__infos-content'>
      <span>{label}</span>
      <span>{moment(date).format('YYYY/MM/DD HH:MM:ss')}</span>
    </div>
  )
}

export default JumboCardStatus
