// -- Import NP%M
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import type { ReactNode } from 'react'
import { Fragment } from 'react'

// -- Import Components

// -- Import Assets

type DrawerWorkspaceProps = {
  openDrawer?: boolean
  setOpenDrawer?: React.Dispatch<React.SetStateAction<boolean>>
  pageView?: string
  addUserProfile?: ReactNode
  editTransport?: ReactNode
  setPageView?: React.Dispatch<React.SetStateAction<string>>
  editCloudNode?: ReactNode
  editAccessNode?: ReactNode
}

export default function DrawerWorkspace({
  pageView,
  openDrawer,
  setOpenDrawer,
  addUserProfile,
  editTransport,
  editCloudNode,
  editAccessNode,
}: DrawerWorkspaceProps) {
  const displayPage = () => {
    switch (pageView) {
      case 'addUsers':
        return addUserProfile
      case 'editAccessNode':
        return editAccessNode
      case 'editCloudNode':
        return editCloudNode
      case 'editTransport':
        return editTransport
      default:
        break
    }
  }

  const toggleDrawer = (open: boolean) => () => {
    setOpenDrawer(open)
  }

  const StyleDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
      background: 'var(--background-primary )',
      minWidth: 'fit-content',
      color: 'var(--content-primary)',
    },
  }))

  return (
    <div>
      <Fragment key='right'>
        <StyleDrawer
          anchor='right'
          open={openDrawer}
          onClose={toggleDrawer(false)}>
          <div role='presentation'>{displayPage()}</div>
        </StyleDrawer>
      </Fragment>
    </div>
  )
}
