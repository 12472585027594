import { InputLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '../../assets/icons-io-v1/close.svg?react'
import { displayCspWithLogo } from '../../hooks/displayIcons'
import { humanizeBandwidth } from '../../hooks/humanizeBandwidth'
import type { CreationState } from '../drawer/DrawerCreateElementV2'
import { StyledTextField } from '../forms/StyledFormComponents'
import { StyledIconButton } from '../forms/SyledIconButtons'

type SelectedFilterProps = {
  newElementData: CreationState
  legend: string
  handleClearFacet?: (facet: string) => void
  largeInput?: boolean
}

const SelectedFilter = ({
  newElementData,
  handleClearFacet,
  legend,
  largeInput,
}: SelectedFilterProps) => {
  const key = legend.toLowerCase().replaceAll(' ', '_')
  const { t } = useTranslation()

  const transformKey = (key: string) => {
    switch (key) {
      case 'csp_name':
        return 'cspName'
      case 'csp_region':
        return 'cspRegion'
      case 'csp_city':
        return 'cspCity'
      case 'location_1':
        return 'location'
      case 'location_2':
        return 'locationTo'
      case 'connection_type':
        return 'type'
      default:
        return key
    }
  }

  const displayData = () => {
    switch (key) {
      case 'csp_name':
        return displayCspWithLogo(newElementData[transformKey(key)])
      case 'bandwidth':
        return humanizeBandwidth(newElementData[transformKey(key)])
      case 'connection_type':
        switch (newElementData[transformKey(key)]) {
          case 'VIRTUAL':
            return t('element.CONNECTED_TO_PROVIDER_THROUGH_SERVICE_KEY')
          case 'PHYSICAL':
            return t('element.CONNECTED_TO_AUTONOMI_CUSTOMER_PORT')
          default:
            break
        }
        break
      default:
        return newElementData[transformKey(key)]
    }
  }

  return (
    <>
      {largeInput ? (
        <>
          <InputLabel className='drawer__forms--labels'>
            {t('element.CONNECTION_TYPE')}
          </InputLabel>
          <StyledTextField
            sx={{
              background: 'var(--background-secondary)',
              borderRadius: 'var(--border-radius)',
              height: '37px',
              '& .MuiInputBase-root ': {
                height: '37px',
                border: '1px solid var(--border-active)',
              },
            }}
            size='small'
            defaultValue={displayData()}
            slotProps={{
              input: {
                endAdornment: (
                  <StyledIconButton
                    onClick={() => handleClearFacet('type')}
                    sx={{
                      border: 'none',

                      width: '22px',
                      '.MuiButtonBase-root .MuiIconButton-root:hover': {
                        border: 'none',
                      },
                    }}>
                    <CloseIcon />
                  </StyledIconButton>
                ),
              },
            }}
          />
        </>
      ) : (
        <fieldset className={`drawer-create__columns-fieldset ${key}`}>
          <legend>{legend}</legend>
          <span>{displayData()}</span>
          <StyledIconButton
            onClick={() => handleClearFacet(transformKey(key))}
            sx={{
              height: '20px',
              marginLeft: '10px',
              marginBottom: '2px',
              maxWidth: '14px',
              padding: '0',
            }}>
            <CloseIcon />
          </StyledIconButton>
        </fieldset>
      )}
    </>
  )
}

export default SelectedFilter
