import { useEffect, useState } from 'react'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useGetWorkspaceByIdV2Query } from '../../slices/workspacesApiSliceV2'
import AdministrativeStatus from '../chips/administrativeStatus'

type Props = {
  label: string
  location: string
  icon: React.ReactNode
  type: string
  name: string
  adminStatus: string
  workspaceId: string | null
}

const JumboCardAttachmentCard = ({
  label,
  location,
  icon,
  type,
  name,
  adminStatus,
  workspaceId,
}: Props) => {
  const { currentAccount } = useCurrentAccount()
  const [skipWorkspace, setSkipWorkspace] = useState<boolean>(true)

  useEffect(() => {
    if (workspaceId) {
      setSkipWorkspace(false)
    }
  }, [workspaceId])

  const { data: workspaceData } = useGetWorkspaceByIdV2Query(
    {
      accountId: currentAccount?.id,
      workspaceId: workspaceId,
    },
    {
      skip: skipWorkspace,
      refetchOnMountOrArgChange: true,
    }
  )

  return (
    <>
      <div
        className='show__element__infos-contents'
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '10px',
        }}>
        <div style={{ marginRight: '15px' }}>
          <div style={{ fontSize: '.9rem' }}>{label}</div>
          <div style={{ fontWeight: '500', maxWidth: '150px' }}>
            {!location ? workspaceData?.data?.name : location}
          </div>
        </div>
        <div className='product__container--current-prod'>
          {icon}
          <span className='product__container--attachments-type'>{type}</span>
          <span className='product__container--attachments-name'>{name}</span>
          <span className='product__container--attachments-status'>
            <AdministrativeStatus status={adminStatus} attachment={true} />
          </span>
        </div>
      </div>
    </>
  )
}

export default JumboCardAttachmentCard
