// -- Import NPM
import { Chip, styled } from '@mui/material'
import { PortStates } from '../../slices/types'

type PortsAdministrativeStatusProps = {
  status: string
}

export default function PortsAdministrativeStatus(
  props: PortsAdministrativeStatusProps
) {
  const { status } = props

  // The code below sets the Chip for the Administrative Status
  // Draft | Deployable | Deploying | Deployed | Failed
  const capitalizeStr = (str: string) => {
    return str.replace('_', ' ').toUpperCase()
  }

  const StylesChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
      border: 'none',
      minWidth: '100%',
      fontSize: '12px',
      fontWeight: '500',
      minHeight: '24px',
    },
  }))

  const displayWsState = (state: string) => {
    switch (state) {
      case PortStates.creation_pending:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case PortStates.creation_proceed:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case PortStates.creation_error:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-failed-full)',
              backgroundColor: 'var(--chips-failed)',
            }}
          />
        )
      case PortStates.created:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deployed-full)',
              backgroundColor: 'var(--chips-deployed)',
            }}
          />
        )
      case PortStates.delete_pending:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case PortStates.delete_proceed:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case PortStates.delete_error:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-failed-full)',
              backgroundColor: 'var(--chips-failed)',
            }}
          />
        )
      case PortStates.deleted:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-failed-full)',
              backgroundColor: 'var(--chips-failed)',
            }}
          />
        )
      default:
        break
    }
  }

  return displayWsState(status)
}
