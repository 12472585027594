// IMPORT DEPENDENCIES
import type { Dispatch, SetStateAction } from 'react'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// IMPORT STYLES
import { StyledDrawer } from './StyledDrawer'
import './styles.scss'

// TYPES
import type { CreationState } from './DrawerCreateElementV2'
import ConfigureItem from './drawerCreateItem/ConfigureItem'
import ConfigureItemProduct from './drawerCreateItem/ConfigureItemProduct'
type DrawerCreateProps = {
  setLoading: Dispatch<SetStateAction<boolean>>
}

export default function DrawerCreateItem({ setLoading }: DrawerCreateProps) {
  const [newElementData, setNewElementData] = useState<CreationState>({
    creationStep: 0,
    elementType: '',
    elementName: '',
    elementProduct: null,
    elementTechInfo: '',
    accessPort: null,
    cspName: '',
    cspCity: '',
    cspRegion: '',
    bandwidth: '',
    provider: '',
    location: '',
    locationTo: '',
    duration: 0,
    portType: '',
  })
  const [creationStep, setCreationStep] = useState<number>(0)
  // const currentAccount = useCurrentAccount()
  const navigate = useNavigate()

  const toggleDrawer = () => () => {
    navigate(`/ports`)
  }

  useEffect(() => {
    setNewElementData((previous) => ({
      ...previous,
      elementType: 'ports',
    }))
  }, [])

  const displayStep = useCallback(
    (creationStep: number) => {
      switch (creationStep) {
        case 0:
          return (
            <ConfigureItem
              setCreationStep={setCreationStep}
              newElementData={newElementData}
              setNewElementData={setNewElementData}
              setLoading={setLoading}
            />
          )

        case 1:
          return (
            <ConfigureItemProduct
              setCreationStep={setCreationStep}
              newElementData={newElementData}
              setNewElementData={setNewElementData}
            />
          )
      }
    },
    [newElementData, setLoading]
  )

  return (
    <div>
      <Fragment key='right'>
        <StyledDrawer anchor='right' open onClose={toggleDrawer()}>
          {displayStep(creationStep)}
        </StyledDrawer>
      </Fragment>
    </div>
  )
}
