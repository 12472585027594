import { Divider } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { displayElementType } from '../../../hooks/displayElementType'
import { attachmentElementIcons } from '../../../hooks/displayIcons'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useGetElementOperationalStatusMetricsQuery } from '../../../slices/MetricsApiSlice'
import { useLazyGetLOAQuery } from '../../../slices/portsApiSlice'
import type {
  AccessElementV2,
  Attachment,
  CloudElementV2,
  PortElementV2,
  TransportV2,
  WorkspaceV2,
} from '../../../slices/types'
import { NodeType } from '../../../slices/types'
import PortProducts from '../../../views/Ports/Details/PortProducts'
import AccessProduct from '../../../views/Workspace/Elements/Details/AccessProduct'
import CloudProduct from '../../../views/Workspace/Elements/Details/CloudProduct'
import TransportProduct from '../../../views/Workspace/Elements/Details/TransportProduct'
import { StyleButton } from '../../buttons/StyledButton'
import JumboCardAttachmentCard from '../../cards/jumboCardAttachmentCard'
import AdministrativeStatus from '../../chips/administrativeStatus'
import OperationalStatus from '../../chips/operationalStatus'
import PortsAdministrativeStatus from '../../chips/portsAdministrativeStatus'
import JumboCardStatus from '../../info/jumboCardStatus'
import ReadOnlyRow from '../ReadOnlyRows'

type ElementDetailsProps = {
  elementData:
    | AccessElementV2
    | CloudElementV2
    | TransportV2
    | PortElementV2
    | Attachment
  workspaceData?: WorkspaceV2
  elementType: string
}

export default function DetailsTabProperties({
  elementData,
  workspaceData,
  elementType,
}: ElementDetailsProps) {
  const { t } = useTranslation()

  const { currentAccount } = useCurrentAccount()

  const [getLOA] = useLazyGetLOAQuery()
  const handleGetLoa = useCallback(
    (portId: string) => {
      getLOA({ accountId: currentAccount?.id, portId })
    },
    [currentAccount?.id, getLOA]
  )

  const elementKind = elementData?.data.kind

  const displayProduct = useCallback(() => {
    switch (elementKind) {
      case 'node':
        switch (elementData?.data.type) {
          case NodeType.Cloud:
            return <CloudProduct elementData={elementData as CloudElementV2} />

          case NodeType.Access:
            return (
              <AccessProduct elementData={elementData as AccessElementV2} />
            )
        }

        return null
      case 'transport':
        return <TransportProduct elementData={elementData as TransportV2} />

      case 'port':
        return <PortProducts elementData={elementData as PortElementV2} />

      default:
        return null
    }
  }, [elementData, elementKind])

  const displayTechnicalParameters = useMemo(() => {
    if (!(elementKind === 'node' && elementData.data.type === NodeType.Cloud)) {
      return null
    }
    const { providerConfig, dxconId } = elementData?.data
    const { cspName } = elementData?.data.product

    switch (cspName) {
      case 'AWS':
        return (
          <>
            <ReadOnlyRow
              title={t('element.AWS_ACCOUNT_ID')}
              value={
                'accountId' in providerConfig && providerConfig.accountId
                  ? providerConfig.accountId
                  : '-'
              }
            />
            <ReadOnlyRow title={t('element.DXCON_ID')} value={dxconId || '-'} />
          </>
        )

      case 'GCP':
        return (
          <ReadOnlyRow
            title={t('element.GCP_PAIRING_KEY')}
            value={
              'pairingKey' in providerConfig && providerConfig.pairingKey
                ? providerConfig.pairingKey
                : '-'
            }
          />
        )

      case 'AZURE':
        return (
          <ReadOnlyRow
            title={t('element.AZURE_SERVICE_KEY')}
            value={
              'serviceKey' in providerConfig && providerConfig.serviceKey
                ? providerConfig.serviceKey
                : '-'
            }
          />
        )
    }
  }, [elementData.data, elementKind, t])

  const transportAttached = useMemo(
    () =>
      workspaceData?.data?.transports?.find(
        (element) =>
          elementData?.data &&
          'attachment' in elementData?.data &&
          element.id === elementData?.data?.attachment?.transportId
      ),
    [elementData?.data, workspaceData?.data?.transports]
  )
  const nodesAttached = useMemo(
    () =>
      workspaceData?.data?.nodes?.filter((node) =>
        (elementData?.data && 'attachments' in elementData?.data
          ? elementData.data.attachments || []
          : []
        ).some((attachment) => attachment?.nodeId === node?.id)
      ),
    [elementData.data, workspaceData?.data?.nodes]
  )

  const displayNodeAttachmentState = useCallback(
    (attachment, element?: string) => {
      switch (element) {
        case 'node':
          const foundNode = workspaceData?.data?.attachments?.find(
            (element) => element.nodeId === attachment.id
          )
          return foundNode?.administrativeState

        case 'transport':
          const foundTransport = workspaceData?.data?.attachments?.find(
            (element) => element.transportId === attachment.id
          )
          return foundTransport?.administrativeState
      }
    },
    [workspaceData?.data?.attachments]
  )

  const nodeData = workspaceData?.data.nodes.filter(
    (node) =>
      elementKind === 'attachment' && node.id === elementData?.data.nodeId
  )

  const transportData = workspaceData?.data.transports.filter(
    (transport) =>
      elementKind === 'attachment' &&
      transport.id === elementData?.data.transportId
  )

  const deletedAt =
    elementData && elementData.data && 'deletedAt' in elementData.data
      ? elementData?.data?.deletedAt
      : null
  const deployedAt =
    elementData && elementData.data && 'deployedAt' in elementData.data
      ? elementData?.data?.deployedAt
      : null

  const showElementStatus = useMemo(() => {
    switch (elementType) {
      case 'port':
        return (
          <>
            <JumboCardStatus
              label={t('element.CREATED_AT')}
              date={elementData?.data?.createdAt}
            />

            {deletedAt && (
              <JumboCardStatus
                label={t('ports.columns.DELETED_AT')}
                date={deletedAt}
              />
            )}
          </>
        )

      case 'transport':
      case 'access':
      case 'cloud':
        return (
          <>
            <JumboCardStatus
              label={t('element.CREATED_AT')}
              date={elementData?.data?.createdAt}
            />
            <JumboCardStatus
              label={t('element.UPDATED_AT')}
              date={elementData?.data?.updatedAt}
            />
            {deployedAt && (
              <JumboCardStatus
                label={t('element.DEPLOYED_AT')}
                date={deployedAt}
              />
            )}

            {deletedAt && (
              <JumboCardStatus
                label={t('ports.columns.DELETED_AT')}
                date={deletedAt}
              />
            )}
          </>
        )

      default:
        return null
    }
  }, [
    deletedAt,
    deployedAt,
    elementData?.data?.createdAt,
    elementData?.data?.updatedAt,
    elementType,
    t,
  ])

  const dataElementKind = elementKind
  const dataKind = dataElementKind !== 'attachment' ? dataElementKind : null

  const { data: operationalStatusData } =
    useGetElementOperationalStatusMetricsQuery(
      {
        accountId: currentAccount?.id,
        elementType: dataKind,
        id: elementData?.data.id,
        workspaceId: workspaceData?.data.id,
      },
      {
        refetchOnMountOrArgChange: true,
        skip:
          !elementData || elementData.data.administrativeState !== 'deployed',
      }
    )

  if (
    !elementData ||
    (elementType === 'attachments' && (!nodeData[0] || !transportData[0]))
  )
    return

  return (
    <>
      <Divider sx={{ width: '100%', margin: '10px 0 24px' }} />
      {/* NAME */}
      <div className='show__element--content'>
        <div className='show__element--title'>
          {elementKind === 'attachment' ? t('element.INFO') : t('user.NAME')}
        </div>
      </div>
      {elementKind === 'attachment' ? (
        ''
      ) : (
        <ReadOnlyRow title={t('user.NAME')} value={elementData?.data?.name} />
      )}
      <ReadOnlyRow title='UUID' value={elementData.data.id} />

      <Divider sx={{ width: '100%', margin: '10px 0 24px' }} />

      {/* STATUS */}
      <div className='show__element--content'>
        <div className='show__element--title'>{t('workspace.STATUS')}</div>
      </div>

      {/* OPERATIONAL STATUS */}
      <div className='show__element__infos-content'>
        {(elementType === 'cloud' || elementType === 'access') && (
          <span>{t('element.NODE_OP_STATUS')}</span>
        )}
        {elementType === 'transport' && (
          <span>{t('element.TRANSPORT_OP_STATUS')}</span>
        )}
        {elementKind === 'port' && <span>{t('element.PORT_OP_STATUS')}</span>}
        {elementKind !== 'attachment' && (
          <span>
            <OperationalStatus
              status={operationalStatusData?.status}
              showStatusText={true}
            />
          </span>
        )}
      </div>

      {/* ADMINISTRATIVE STATUS */}
      <div className='show__element__infos-content'>
        {elementKind === 'node' && (
          <span>{t('element.NODE_ADMIN_STATUS')}</span>
        )}
        {elementKind === 'transport' && (
          <span>{t('element.TRANSPORT_ADMIN_STATUS')}</span>
        )}
        {elementKind === 'attachment' && (
          <span>{t('element.ATTACHMENT_ADMIN_STATUS')}</span>
        )}
        {elementKind === 'port' && (
          <span>{t('element.PORT_ADMIN_STATUS')}</span>
        )}

        <span>
          {elementKind === 'port' ? (
            <PortsAdministrativeStatus
              status={elementData?.data?.administrativeState}
            />
          ) : (
            <AdministrativeStatus
              status={elementData?.data?.administrativeState}
            />
          )}
        </span>
      </div>
      {/* DATE STATUS */}
      {showElementStatus}

      {elementType === 'attachments' ? (
        ''
      ) : (
        <>
          <Divider sx={{ width: '100%', margin: '24px 0' }} />
          {displayProduct()}
        </>
      )}

      {/* Show LOA Button if this is of Kind Port*/}
      {elementKind === 'port' && (
        <>
          <Divider sx={{ width: '100%', margin: '24px 0' }} />

          <ReadOnlyRow
            fontWheight='bold'
            title='LOA'
            value={t('ports.messages.MONTH', {
              count: elementData.data.product.duration,
            })}
            cta={
              <StyleButton
                variant='contained'
                onClick={(e) => {
                  e.stopPropagation()
                  handleGetLoa(elementData?.data?.id)
                }}>
                {t('element.DOWNLOAD')}
              </StyleButton>
            }
          />
        </>
      )}

      {/* TECHNICAL PARAMETERS */}
      {elementKind === 'node' && elementData?.data?.type === NodeType.Cloud && (
        <>
          <Divider sx={{ width: '100%', margin: '24px 0' }} />

          <div className='show__element--content'>
            <div className='show__element--title'>
              {t('element.TECHNICAL_PARAMETERS')}
            </div>
          </div>

          {displayTechnicalParameters}

          <ReadOnlyRow
            title={t('element.VLAN')}
            value={elementData?.data?.vlan ? elementData?.data?.vlan : '-'}
          />
        </>
      )}
      <Divider sx={{ width: '100%', margin: '10px 0 24px' }} />

      {/* ATTACHMENTS */}
      {/* NODES */}
      {elementKind === 'node' && (
        <div>
          <div className='show__element--content'>
            <div className='show__element--title'>Attachment</div>
          </div>
          <div className='show__element__infos-contents'>
            {elementData?.data?.attachment &&
            elementData?.data?.attachment !== null ? (
              <JumboCardAttachmentCard
                label={t('element.LOCATION')}
                location={transportAttached.product.location}
                icon={attachmentElementIcons(transportAttached?.kind, '', '')}
                type={displayElementType(transportAttached?.kind, '', t)}
                name={transportAttached.name}
                adminStatus={elementData?.data?.attachment?.administrativeState}
                workspaceId={null}
              />
            ) : (
              <span>No attachment</span>
            )}
          </div>
        </div>
      )}
      {/* TRANSPORTS */}
      {elementKind === 'transport' && (
        <div>
          <div className='show__element--content'>
            <div className='show__element--title'>Attachments</div>
          </div>
          <div className='show__element__infos-contents'>
            {elementData?.data?.attachments &&
            elementData?.data?.attachments?.length > 0 ? (
              nodesAttached?.map((node, index) => {
                return (
                  <JumboCardAttachmentCard
                    key={index}
                    label={t('element.LOCATION')}
                    location={node.product.location}
                    icon={attachmentElementIcons(node?.kind, node?.type, '')}
                    type={displayElementType(node?.kind, node?.type, t)}
                    name={node.name}
                    adminStatus={node.administrativeState}
                    workspaceId={null}
                  />
                )
              })
            ) : (
              <span>No attachments</span>
            )}
            {/* TO DO: list of attachments id cards currently using the transport */}
          </div>
        </div>
      )}
      {/* ATTACHMENTS */}
      {elementKind === 'attachment' && (
        <div>
          <div className='show__element--content'>
            <div className='show__element--title'>Attachments</div>
          </div>
          <JumboCardAttachmentCard
            label={t('element.LOCATION')}
            location={nodeData[0].product.location}
            icon={attachmentElementIcons(
              nodeData[0].kind,
              nodeData[0].type,
              ''
            )}
            type={displayElementType(nodeData[0].kind, nodeData[0].type, t)}
            name={nodeData[0].name}
            adminStatus={displayNodeAttachmentState(nodeData[0], 'node')}
            workspaceId={null}
          />
          <JumboCardAttachmentCard
            label={t('element.LOCATION')}
            location={nodeData[0].product.location}
            icon={attachmentElementIcons(transportData[0].kind, '', '')}
            type={displayElementType(transportData[0].kind, '', t)}
            name={transportData[0].name}
            adminStatus={displayNodeAttachmentState(
              transportData[0],
              'transport'
            )}
            workspaceId={null}
          />
        </div>
      )}
      {/* PORTS */}
      {elementKind === 'port' && (
        <div>
          <div className='show__element--content'>
            <div className='show__element--title'>Port usage</div>
          </div>
          <div className='show__element__infos-contents'>
            {elementData.data.nodes.length === 0 ? (
              <span>No access node</span>
            ) : (
              elementData.data.nodes.map((node, index) => {
                return (
                  <JumboCardAttachmentCard
                    key={index}
                    label={t('model.WORKSPACE')}
                    location={null}
                    icon={attachmentElementIcons('node', node.type, '')}
                    type={displayElementType('node', node.type, t)}
                    name={node.name}
                    adminStatus={node.administrativeState}
                    workspaceId={node.workspaceId}
                  />
                )
              })
            )}
          </div>
        </div>
      )}
    </>
  )
}
