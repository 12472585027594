// -- NPM IMPORTS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { Element, User } from '../../slices/types'

// -- COMPONENT IMPORTS

// -- ASSETS IMPORTS
import './style.scss'

export type DeleteModalProps = {
  openDeleteModal?: boolean
  setOpenDeleteModal?: Function
  element?: Element | User
  handleClose?: (newValue?: boolean, row?: Element | User) => void
  deleteDialogMessage?: string
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>>
  setSeverity?: React.Dispatch<React.SetStateAction<string>>
  setSnackbarMessage?: React.Dispatch<React.SetStateAction<string>>
  deleteSnackbarMessage?: string
}

export default function DeleteModal({
  openDeleteModal,
  setOpenDeleteModal,
  handleClose,
  element,
  deleteDialogMessage,
  setOpenSnackbar,
  setSeverity,
  setSnackbarMessage,
  deleteSnackbarMessage,
}: DeleteModalProps) {
  const { t } = useTranslation()

  const onClose = useCallback(() => {
    setOpenDeleteModal(false)
  }, [setOpenDeleteModal])

  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])

  const handleOk = useCallback(() => {
    onClose()
    handleClose(true, element)
    if (setOpenSnackbar) {
      setOpenSnackbar(true)
      setSeverity('success')
      setSnackbarMessage(deleteSnackbarMessage)
    }
  }, [
    deleteSnackbarMessage,
    element,
    handleClose,
    onClose,
    setOpenSnackbar,
    setSeverity,
    setSnackbarMessage,
  ])

  // STYLE MENU
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: '1px',
      border: '1px solid var(--border-secondary)',
      boxShadow: 'none',
      background: 'var(--background-secondary)',
      color: 'var(--content-primary)',
    },
    '& p': {
      color: 'var(--content-primary)',
    },
  }))

  return (
    <div>
      <StyledDialog
        open={openDeleteModal}
        onClose={onClose}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogTitle id='alert-dialog-title'>
          {t('modal.CONFIRM_DELETION')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{deleteDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            {t('modal.CANCEL')}
          </Button>
          <Button onClick={handleOk} color='primary'>
            {t('modal.CONFIRM')}
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  )
}
