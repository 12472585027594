// IMPORT DEPENDENCIES
import { InputLabel } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

// IMPORT STYLES
import {
  ErrorStyledDiv,
  StyledActionButton,
  StyledButton,
  StyledTextField,
} from '../../forms/StyledFormComponents'

// IMPORT ICONS
import AddIcon from '../../../assets/icons-io-v1/add.svg?react'
import EditIcon from '../../../assets/icons-io-v1/edit.svg?react'

// TYPES
import useResizeObserver from 'use-resize-observer'
import { displayElementType } from '../../../hooks/displayElementType'
import { displayCspWithLogo } from '../../../hooks/displayIcons'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { ResourceType } from '../../../slices/authorizationsApiSlice'
import type { CloudCreateFormData, ErrorDetails } from '../../../slices/types'
import {
  useCreateNodeInWorkspaceV2Mutation,
  useValidateNodeMutation,
} from '../../../slices/workspacesApiSliceV2'
import { DOCS_URL } from '../../../views/Login/SetPassword'
import FormatMonthlyPrice from '../../format/monthlyPrice'
import Visible from '../../permissions/Visible'
import type { CreationState } from '../DrawerCreateElementV2'
import ReadOnlyRow from '../ReadOnlyRows'

type ConfigureCloudProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: () => void
}

export default function ConfigureCloud({
  newElementData,
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: ConfigureCloudProps) {
  const { showError, showSuccess, showErrorWithMessage } = useSnackbar()
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { currentAccount } = useCurrentAccount()
  const [createElementNode] = useCreateNodeInWorkspaceV2Mutation()
  const [validateElementNode] = useValidateNodeMutation()
  const { t } = useTranslation()
  const [providerError, setProviderError] = useState<boolean>(false)
  const [nameError, setNameError] = useState<boolean>(false)
  const [canCreateNode, setCanCreateNode] = useState<boolean>(false)

  const handleAddProduct = useCallback(() => {
    setCreationStep(2)
    navigate(`product`)
  }, [navigate, setCreationStep])

  const handleTechnicalInfo = useCallback(() => {
    setCreationStep(3)
    navigate(`technicalInfo`)
  }, [navigate, setCreationStep])

  const validateElement = useCallback(async () => {
    const data = newElementData
    if (!data || !currentAccount) {
      return
    }
    const cloudNode: CloudCreateFormData = {
      type: 'cloud',
      name: data.elementName,
      product: { sku: data.elementProduct?.sku },
      providerConfig: {
        [getProviderConfigName(data.elementProduct?.cspName)]:
          data.elementTechInfo,
      } as CloudCreateFormData['providerConfig'],
    }
    setProviderError(false)
    setNameError(false)
    try {
      await validateElementNode({
        accountId: currentAccount.id,
        workspaceId: workspaceId,
        node: cloudNode,
      }).unwrap()
      setCanCreateNode(true)
    } catch (e) {
      setCanCreateNode(false)
      if (e.status === 400) {
        const errorDetails = e.data.details
        errorDetails.forEach((detail: ErrorDetails) => {
          switch (detail.field) {
            case 'accountId':
            case 'pairingKey':
            case 'serviceKey':
            case 'sku':
              setProviderError(true)

              if (detail.reason === 'ERR_GCP_EQUINIX_PAIRING_KEY_NOT_VALID') {
                showErrorWithMessage(t(`messages.errors.${detail.reason}`))
              } else if (detail.reason !== 'ERR_REQUIRED') {
                showErrorWithMessage(t('messages.errors.ERR_KEY_NOT_VALID'))
              }
              break
            case 'name':
              setNameError(true)
              break
          }
        })
      }
    }
  }, [
    newElementData,
    currentAccount,
    validateElementNode,
    workspaceId,
    showErrorWithMessage,
    t,
  ])

  useEffect(() => {
    if (newElementData?.elementProduct?.sku !== undefined) {
      validateElement()
    }
  }, [newElementData, validateElement])

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  const getProviderConfigName = (cspName: 'AWS' | 'AZURE' | 'GCP') => {
    switch (cspName) {
      case 'AWS':
        return 'accountId'
      case 'AZURE':
        return 'serviceKey'
      case 'GCP':
        return 'pairingKey'
      default:
        break
    }
  }

  const createElement = useCallback(async () => {
    const data = newElementData
    if (!data || !currentAccount) {
      return
    }
    const cloudNode: CloudCreateFormData = {
      type: 'cloud',
      name: data.elementName,
      product: { sku: data.elementProduct?.sku },
      providerConfig: {
        [getProviderConfigName(data.elementProduct?.cspName)]:
          data.elementTechInfo,
      } as CloudCreateFormData['providerConfig'],
    }

    try {
      await createElementNode({
        accountId: currentAccount.id,
        workspaceId: workspaceId,
        node: cloudNode,
      }).unwrap()
      toggleDrawer()
      showSuccess(
        t('element.messages.ELEMENT_HAS_BEEN_CREATED_WITH_SUCCESS', {
          element: displayElementType('node', 'cloud', t),
        })
      )
    } catch (e) {
      showError(e)
    }
  }, [
    newElementData,
    currentAccount,
    createElementNode,
    workspaceId,
    toggleDrawer,
    showSuccess,
    t,
    showError,
  ])

  return (
    <>
      <div className='drawer-create__containers' ref={drawerWidth}>
        <div className='drawer-create__columns-input'>
          <InputLabel
            htmlFor='element-form-name'
            className='drawer__forms--labels'>
            {t('element.ELEMENT_NAME')}
          </InputLabel>
          <ErrorStyledDiv isError={nameError}>
            <StyledTextField
              required
              id='element-form-name'
              variant='outlined'
              size='small'
              value={newElementData?.elementName}
              slotProps={{
                htmlInput: {
                  form: {
                    autocomplete: 'off',
                  },
                },
              }}
              onChange={(e) =>
                setNewElementData({
                  ...newElementData,
                  elementName: e.target.value,
                })
              }
            />
          </ErrorStyledDiv>
        </div>
      </div>

      <div className='drawer-create__spacer-wrapper'>
        {/* ----- PRODUCT ----- */}
        {Object.keys(newElementData?.elementProduct ?? {}).length === 0 ? (
          <div className='drawer-create__containers'>
            <div className='drawer-create__product-rows'>
              <div className='drawer-create__columns-title'>
                {t('element.PRODUCT')}
              </div>
              <div className='drawer-create__columns-button'>
                <StyledButton
                  variant='outlined'
                  startIcon={<AddIcon height='22' width='22' />}
                  onClick={handleAddProduct}>
                  Add
                </StyledButton>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='drawer-create__containers-product-info'>
              <div className='drawer-create__product-rows'>
                <div className='drawer-create__columns-title header'>
                  {t('element.PRODUCT')}
                </div>
                <div className='drawer-create__columns-button'>
                  <StyledButton
                    variant='outlined'
                    startIcon={<EditIcon height='22' width='22' />}
                    onClick={handleAddProduct}>
                    Replace
                  </StyledButton>
                </div>
              </div>

              <ReadOnlyRow
                title={t('element.CSP_NAME')}
                value={displayCspWithLogo(
                  newElementData?.elementProduct?.cspName
                )}
              />

              <ReadOnlyRow
                title={t('element.CSP_REGION')}
                value={newElementData.elementProduct.cspRegion}
              />

              <ReadOnlyRow
                title={t('element.PROVIDER')}
                value={newElementData.elementProduct.provider}
              />

              <ReadOnlyRow
                title={t('element.CSP_CITY')}
                value={newElementData.elementProduct.cspCity}
              />

              <ReadOnlyRow
                title={t('element.LOCATION')}
                value={newElementData.elementProduct.location}
              />

              <ReadOnlyRow
                title={t('element.BANDWIDTH')}
                value={humanizeBandwidth(
                  newElementData.elementProduct.bandwidth
                )}
              />

              <ReadOnlyRow
                title={t('element.SKU')}
                value={newElementData.elementProduct.sku}
              />

              <ReadOnlyRow
                title={t('element.PRICE')}
                value={
                  <FormatMonthlyPrice
                    price={newElementData.elementProduct.priceMrc}
                  />
                }
              />
            </div>
          </>
        )}

        {/* ----- TECHNICAL INFOS ----- */}
        {newElementData?.elementTechInfo === '' ? (
          <div className='drawer-create__containers' ref={drawerWidth}>
            {/*// TODO - INSERT LOCAL */}
            <div className='drawer-create__product-rows'>
              <div className='drawer-create__columns-title'>
                {t('element.TECHNICAL_INFO')}
              </div>
              <div className='drawer-create__columns-button'>
                <StyledButton
                  variant='outlined'
                  startIcon={<AddIcon height='22' width='22' />}
                  onClick={handleTechnicalInfo}
                  disabled={
                    Object.keys(newElementData?.elementProduct ?? {}).length <=
                    0
                  }>
                  Add
                </StyledButton>
              </div>
            </div>
          </div>
        ) : (
          <div className='drawer-create__containers'>
            <div className='drawer-create__product-rows'>
              <div className='drawer-create__columns-title header'>
                {t('element.TECHNICAL_INFO')}
              </div>
              <div className='drawer-create__columns-button'>
                <StyledButton
                  variant='outlined'
                  startIcon={<EditIcon height='22' width='22' />}
                  onClick={handleTechnicalInfo}>
                  Replace
                </StyledButton>
              </div>
            </div>
            <ErrorStyledDiv
              isError={providerError}
              className='drawer-create__product-rows'>
              <div className='drawer-create__columns-title'>
                {newElementData?.elementProduct?.cspName === 'AWS' && (
                  <span>{t('element.CSP_ACCOUNT_ID')}</span>
                )}
                {newElementData?.elementProduct?.cspName === 'GCP' && (
                  <span>{t('element.PAIRING_KEY')}</span>
                )}
                {newElementData?.elementProduct?.cspName === 'AZURE' && (
                  <span>{t('element.SERVICE_KEY')}</span>
                )}
              </div>
              <div className='drawer-create__columns-info'>
                <span>{newElementData?.elementTechInfo}</span>
              </div>
            </ErrorStyledDiv>
          </div>
        )}
      </div>

      {/* --------------------------------- */}
      {/* ------------ ACTIONS ------------ */}
      {/* --------------------------------- */}
      <div className='element__form--btn' style={{ width }}>
        {/* --------------------------------- */}
        {/* ---------- DISCLAIMERS ---------- */}
        {/* --------------------------------- */}
        <div className='element__form--btn-disclaimer'>
          {t('disclaimers.CREATING_ELEMENT')}
          <a href={DOCS_URL} target='_blank' rel='noreferrer'>
            {DOCS_URL}
          </a>
        </div>

        <Visible
          resource={ResourceType.Workspace}
          resourceId={workspaceId}
          action='add_node'>
          <StyledActionButton
            sx={{
              width: '49.5%',
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={toggleDrawer}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            sx={{ width: '49.5%', marginLeft: '1%' }}
            type='submit'
            color='primary'
            variant='contained'
            disabled={!canCreateNode}
            onClick={createElement}>
            {t('element.CREATE')}
          </StyledActionButton>
        </Visible>
      </div>
    </>
  )
}
