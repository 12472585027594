// -- NPM Imports
import type { TooltipProps } from '@mui/material'
import { Tooltip, styled, tooltipClasses } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps'

// -- Import Components
import type { mapCityMarker, mapConfigType } from '../../slices/types'
import EUROPE_MAP from './EuropeMap'
import WORLD_MAP from './WorldMap'

// -- Import Assets
import './style.scss'

type GlobeMapProps = {
  activeState: string
}

const europeMapandConfig: mapConfigType = {
  map: EUROPE_MAP,
  projection: 'geoAzimuthalEqualArea',
  projectionConfig: {
    rotate: [-7.0, -49.0, 0],
    scale: 1100,
  },
}

const worldMapandConfig: mapConfigType = {
  map: WORLD_MAP,
  projection: 'geoMercator',
  projectionConfig: {
    center: [0, 20],
    scale: 150,
  },
}

export default function GlobalMap({ activeState }: GlobeMapProps) {
  const [mapToDisplay, setMapToDisplay] = useState(worldMapandConfig)

  const cityMarkers: mapCityMarker[] = [
    {
      locationId: 1,
      name: 'EQUINIX AM2',
      metro: 'Amsterdam',
      country: 'Netherlands',
      longitude: 4.8447887,
      latitude: 52.2990915,
    },
    {
      locationId: 2,
      name: 'EQUINIX DC2',
      metro: 'Ashburn',
      country: 'USA',
      longitude: -77.5025473,
      latitude: 39.0219927,
    },
    {
      locationId: 3,
      name: 'EQUINIX FR5',
      metro: 'Frankfurt am Main',
      country: 'Germany',
      longitude: 8.63226,
      latitude: 50.09867,
    },
    {
      locationId: 4,
      name: 'EQUINIX HK2',
      metro: 'Hong Kong',
      country: 'China',
      longitude: 114.1192183,
      latitude: 22.3622845,
    },
    {
      locationId: 5,
      name: 'EQUINIX LD5',
      metro: 'London',
      country: 'United Kingdom',
      longitude: -0.6290497,
      latitude: 51.5224625,
    },
    {
      locationId: 6,
      name: 'EQUINIX PA3',
      metro: 'Paris',
      country: 'France',
      longitude: 2.3495518,
      latitude: 48.9274401,
    },
    {
      locationId: 7,
      name: 'EQUINIX SG1',
      metro: 'Singapour',
      country: 'Singapour',
      longitude: 103.7898559,
      latitude: 1.2953133,
    },
    {
      locationId: 8,
      name: 'EQUINIX SV5',
      metro: 'Silicon Valley',
      country: 'USA',
      longitude: -121.7863189,
      latitude: 37.242176,
    },
  ]

  useEffect(() => {
    if (activeState === 'Europe') {
      setMapToDisplay(europeMapandConfig)
    } else if (activeState === 'World') {
      setMapToDisplay(worldMapandConfig)
    }
  }, [activeState])

  const MarkerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'var(--map-tooltip-background)',
      color: 'var(--map-tooltip-color)',
      fontSize: '16px',
      borderRadius: '5px',
      boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.1)',
      padding: '16px',
    },
  }))

  return (
    <Fragment>
      <div className='dashboard__table-content'>
        <ComposableMap
          style={{
            width: 'auto',
          }}
          projection={mapToDisplay.projection}
          projectionConfig={mapToDisplay.projectionConfig}>
          <Geographies geography={mapToDisplay.map}>
            {({ geographies }: { geographies }) =>
              geographies.map((geo: { rsmKey: unknown }) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: 'var(--map-fill-default)',
                      stroke: 'var(--map-stroke-default)',
                      outline: 'none',
                    },
                    hover: {
                      fill: 'var(--map-fill-hover)',
                      stroke: 'var(--map-stroke-hover)',
                      outline: 'none',
                    },
                    pressed: {
                      fill: 'var(--map-fill-pressed)',
                      outline: 'none',
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {cityMarkers?.map(
            ({ name, locationId, longitude, latitude, metro, country }) => {
              return (
                <Marker key={locationId} coordinates={[longitude, latitude]}>
                  <MarkerTooltip
                    leaveDelay={400}
                    title={
                      <Fragment>
                        <div className='map__marker-name'>{name}</div>
                        <div className='map__marker-metro'>
                          <span>{metro}</span> - <span>{country}</span>
                        </div>
                      </Fragment>
                    }>
                    <circle
                      r={mapToDisplay === europeMapandConfig ? 3 : 2}
                      fill='var(--map-dot-fill)'
                      stroke='var(--map-dot-stroke)'
                      strokeWidth={mapToDisplay === europeMapandConfig ? 3 : 2}
                    />
                  </MarkerTooltip>
                </Marker>
              )
            }
          )}
        </ComposableMap>
      </div>
    </Fragment>
  )
}
