// IMPORT DEPENDENCIES
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate, useParams } from 'react-router-dom'

// IMPORT COMPONENTS
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useGetElementByIdV2Query } from '../../slices/workspacesApiSliceV2'

// IMPORT ASSETS
import { IconButton } from '@mui/material'
import useResizeObserver from 'use-resize-observer'
import CloseIcon from '../../assets/icons-io-v1/close.svg?react'
import { useSnackbar } from '../../hooks/useSnackbar'
import { ResourceType } from '../../slices/authorizationsApiSlice'
import { StyledActionButton } from '../forms/StyledFormComponents'
import Visible from '../permissions/Visible'
import { StyledDrawer } from './StyledDrawer'

// TYPES
type DrawerDeleteProps = {
  handleCloseDelete?: (
    newValue?: boolean,
    element?: { id?: string }
  ) => Promise<void>
}

const adaptElementKind = (type: string) => {
  switch (type) {
    case 'cloud':
      return 'nodes'
    case 'access':
      return 'nodes'
    case 'transport':
      return 'transports'
    case 'port':
      return 'ports'
  }
}

export default function DrawerDelete({ handleCloseDelete }: DrawerDeleteProps) {
  const { showError } = useSnackbar()
  const { workspaceId, elementId, type: elementType } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()

  const adaptedType = adaptElementKind(elementType)

  const matchesViewKind = useMatch('/workspaces/:workspaceId/view/:viewKind/*')

  const toggleDrawer = useCallback(() => {
    navigate(matchesViewKind ? matchesViewKind.pathnameBase : '/ports')
  }, [navigate, matchesViewKind])

  // FETCH THE CURRENT ELEMENT DATA
  const { data: elementData, error: errorElement } = useGetElementByIdV2Query(
    {
      accountId: currentAccount?.id,
      workspaceId: workspaceId,
      elementType:
        adaptedType && adaptedType !== 'ports' ? adaptedType : 'nodes',
      elementId: elementId,
    },
    {
      skip: !currentAccount?.id || adaptedType === 'ports',
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (!errorElement) return
    showError(errorElement)
    toggleDrawer()
  }, [showError, errorElement, toggleDrawer])

  const handleConfirm = useCallback(() => {
    handleCloseDelete(true, elementData?.data)
    toggleDrawer()
  }, [elementData?.data, handleCloseDelete, toggleDrawer])

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  if (!elementData) {
    return null
  }

  const dataProductType =
    'product' in elementData.data && 'type' in elementData.data.product
      ? elementData.data.product.type
      : ''
  const dataProductProvider =
    'product' in elementData.data && 'provider' in elementData.data.product
      ? elementData.data.product.provider
      : ''
  const dataCspName =
    'product' in elementData.data && 'cspName' in elementData.data.product
      ? elementData.data.product.cspName
      : null

  const dataName = 'name' in elementData?.data ? elementData?.data.name : ''

  return (
    <div>
      <StyledDrawer anchor='right' open onClose={toggleDrawer}>
        <div className='drawer-create__wrapper' ref={drawerWidth}>
          <div className='show__element__container-close'>
            <IconButton
              onClick={toggleDrawer}
              sx={{ padding: '0', height: '24px' }}>
              <CloseIcon fontSize='small' style={{ width: '22px' }} />
            </IconButton>
          </div>
          <div className='drawer-create__header'>
            {t('modal.CONFIRM_DELETION')}
          </div>
          <div>
            {elementType === 'access' &&
              dataProductType === 'VIRTUAL' &&
              dataProductProvider === 'EQUINIX' && (
                <div className='drawer-create__wrapper-disclaimer'>
                  {t('element.CONFIRM_DISCLAIMER_EQUINIX_SERVICE_KEY_ACCESS')}
                </div>
              )}
            {elementType === 'cloud' &&
              dataProductProvider === 'MEGAPORT' &&
              dataCspName === 'AWS' && (
                <div className='drawer-create__wrapper-disclaimer'>
                  {t('element.CONFIRM_DISCLAIMER_AWS_MEGAPORT_DXCON')}
                </div>
              )}

            <div>
              {t('element.CONFIRM_ELEMENT_TYPE_DELETION', {
                elementType,
                elementName: dataName,
              })}
            </div>
          </div>

          {/* --------------------------------- */}
          {/* ------------ ACTIONS ------------ */}
          {/* --------------------------------- */}
          <div className='element__form--btn' style={{ width }}>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='delete_node'>
              <StyledActionButton
                sx={{
                  width: '49.5%',
                  backgroundColor: 'var(--background-tertiary)',
                  '&:hover': {
                    backgroundColor: 'var(--background-secondary)',
                  },
                }}
                color='primary'
                variant='text'
                onClick={toggleDrawer}>
                {t('element.DISCARD')}
              </StyledActionButton>
              <StyledActionButton
                sx={{ width: '49.5%', marginLeft: '1%' }}
                type='submit'
                color='primary'
                variant='contained'
                onClick={handleConfirm}>
                {t('modal.CONFIRM')}
              </StyledActionButton>
            </Visible>
          </div>
        </div>
      </StyledDrawer>
    </div>
  )
}
