import {
  Accordion,
  Autocomplete,
  Badge,
  Button,
  IconButton,
  InputLabel,
  Select,
  styled,
  Tab,
  TextField,
} from '@mui/material'

export const StyledActionButton = styled(Button)(({ theme }) => ({
  '&.Mui-disabled': {
    fontFamily: 'var(--font-family)',
    color: 'var(--content-inactive)',
    backgroundColor: 'var(--background-action-inactive)',
  },
  boxShadow: 'none',
  width: '100%',
  padding: '0',
}))

export const StyledTextField = styled(TextField)(() => ({
  paddingTop: '-8px',
  width: '100%',
  '& .MuiInputLabel-root': {
    color: 'var(--content-primary)',
  },
  '& .MuiInputBase-root': {
    color: 'var(--color-primary)',
    borderRadius: 'var(--border-radius)',
    outline: 'none',
    '& .MuiInputBase-input': {
      background: 'var(--background-secondary)',
      borderRadius: 'var(--border-radius)',
      border: 0,
    },
  },
  '& .MuiInputBase-root:hover': {
    background: 'var(--background-secondary)',
    borderRadius: 'var(--border-radius)',
  },
}))

export const StyledSelectMenu = styled(Select)(() => ({
  width: '100%',
  backgroundColor: 'var(--root-color-grey-10)',
  '& .MuiInputLabel-root': {
    color: 'var(--content-primary)',
  },
  '& .MuiInputBase-root': {
    color: 'var(--color-primary)',
    borderRadius: 'var(--border-radius)',
    outline: 'none',
    '& .MuiInputBase-input': {
      background: 'var(--background-secondary)',
      borderRadius: 'var(--border-radius)',
      border: 0,
    },
  },
  '& .MuiInputBase-root:hover': {
    background: 'var(--background-secondary)',
    borderRadius: 'var(--border-radius)',
  },
  '& .MuiSelect-select': {
    padding: '8px 10px',
  },
}))

export const selectProps = {
  MenuProps: {
    PaperProps: {
      sx: {
        color: 'var(--content-primary)',
        border: '1px solid var(--border-primary)',
        background: 'var(--background-primary)',
        borderRadius: '1px',
      },
    },
    MenuListProps: {
      disablePadding: true,
    },
  },
}

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: 'var(--border-radius)',
  },
  '& .MuiInputBase-input': {
    borderColor: 'var(--border-primary)',
    borderRadius: 'var(--border-radius)',
    margin: '0',
  },
  '& .MuiSelect-select': {
    color: 'var(--content-primary)',
  },
  '& .MuiPaper-root': {
    '& .MuiMenu-paper': {
      borderRadius: '1px',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'var(--content-secondary)',
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--content-secondary)',
  },
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: 'var(--content-secondary)',
  fontWeight: '600',
  fontSize: '14px',
}))

export const StyledButtonClose = styled(IconButton)(({ theme }) => ({
  padding: '0px',
  height: 'inherit',
}))
export const ErrorStyledDiv = styled('div')<{ isError?: boolean }>(
  ({ isError }) => ({
    border: isError ? '1px solid var(--root-color-semantic-negative)' : '',
    padding: isError ? '4px 10px' : '',
    borderRadius: isError ? '4px' : '',
  })
)

export const StyledButton = styled(Button)<{ selected?: boolean }>(
  ({ selected }) => ({
    boxSizing: 'border-box',
    border: '1px solid var(--border-active)',
    overflow: 'hidden',
    background: selected
      ? 'var(--root-color-basic-brand-alto-dark)'
      : 'var(--background-secondary)',
    color: selected ? 'var(--root-color-grey-10)' : 'var(--content-action)',
    fontVariantNumeric: 'lining-nums',
    marginRight: '6px',

    '& span': {
      maxWidth: '30px',
      '& svg': {
        maxWidth: '25px',
        width: '20px',
      },
    },

    '&.Mui-disabled': {
      border: '1px solid var(--border-inactive)',
      color: 'var(--content-inactive)',
      opacity: '0.6',
    },
    '&.active': {
      color: 'var(--root-color-action)',
      '& svg': {
        '& path': {
          // stroke: 'var(--root-color-action)',
        },
      },
      marginRight: '6px',
    },
    '&:hover': {
      color: 'var(--root-color-grey-10)',
      background: 'var(--root-color-basic-brand-alto-dark)',
      border: '1px solid var(--border-active)',
    },
  })
)

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid var(--border-secondary)',
  background: 'var(--background-secondary)',
  color: 'var(--content-secondary)',
  maxWidth: '30px',
  '&.active': {
    color: 'var(--root-color-action)',
    '& svg': {
      '& path': {
        stroke: 'var(--root-color-action)',
      },
    },
  },
  '&:hover': {
    color: 'var(--root-color-action)',
    border: '1px solid var(--root-color-action)',
    '& svg': {
      '& path': {
        stroke: 'var(--root-color-action)',
      },
    },
  },
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderStyle: 'none',
  '&.MuiPaper-root': {
    border: '1px solid var(--border-primary)',
    boxShadow: 'none',
    marginBottom: '16px',
    backgroundColor: 'var(--background-primary)',
    lineHeight: '1.5',
    borderRadius: 'var(--border-radius)',
  },
  '&.MuiPaper-root:before': {
    height: 0,
  },
  '&.MuiButtonBase-root': {
    padding: '0',
  },
}))

interface StyledTabs {
  to: string
}

export const StyledTab = styled(Tab)<StyledTabs>(({ to }) => ({
  to,
  '& svg': {
    minWidth: '20px',
    maxWidth: '20px',
    maxHeight: '20px',
    minHeight: '20px',
  },
  '&.MuiTab-root': {
    color: 'var(--content-primary)',
  },
  '&.Mui-selected': {
    color: 'var(--content-active)',
  },
}))

export const StyledBadge = styled(Badge)(({ theme }) => ({
  border: '1px solid var(--border-primary)',
  borderRadius: 'var(--border-radius)',
}))
