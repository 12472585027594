// -- NPM IMPORTS
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback } from 'react'

// -- COMPONENT IMPORTS
import AddWorkspace from '../../views/Workspace/Add'

// -- ASSETS IMPORTS
import './style.scss'

// STYLE MENU
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '50%',
    borderRadius: '1px',
    border: '1px solid var(--border-secondary)',
    boxShadow: 'none',
    background: 'var(--background-secondary)',
    color: 'var(--content-primary)',
  },
  '& p': {
    color: 'var(--content-primary)',
  },
}))

export type CreateItemModalProps = {
  openModal?: boolean
  setOpenModal?: Dispatch<SetStateAction<boolean>>
  dialogMessage?: string
  model?: string
}

export default function CreateItemModal({
  openModal = false,
  setOpenModal,
  dialogMessage,
  model,
}: CreateItemModalProps) {
  const switchElement = () => {
    switch (model) {
      case 'workspace':
        return (
          <AddWorkspace
            handleCancel={handleCancel}
            setOpenModal={setOpenModal}
            onClose={onClose}
          />
        )

      default:
        break
    }
  }

  const onClose = useCallback(() => {
    setOpenModal(false)
  }, [setOpenModal])

  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <div>
      <StyledDialog
        open={openModal}
        onClose={onClose}
        fullWidth={true}
        maxWidth={'md'}>
        <DialogTitle id='alert-dialog-title'>{dialogMessage}</DialogTitle>
        {switchElement()}
      </StyledDialog>
    </div>
  )
}
