// -- Import NP%M
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { Fragment } from 'react'
import SwitchAccountList from '../../views/Sidebar/SwitchAccountList'
import { StyledDrawer } from './StyledDrawer'

// -- Import Components

// -- Import Assets

type ActionDrawerProps = {
  openDrawer?: boolean
  setOpenDrawer?: Dispatch<SetStateAction<boolean>>
  pageView?: string
  model?: string
  addForm?: ReactNode
  editForm?: ReactNode
  params?: Record<string, string>
}

export default function DrawerAction({
  pageView,
  openDrawer,
  setOpenDrawer,
  model,
  addForm,
  editForm,
  params,
}: ActionDrawerProps) {
  const displayPage = () => {
    switch (pageView) {
      case `show${model}`:
        return "Ici je retourne le détail d'un user"
      case `edit${model}`:
        return editForm
      case `add${model}`:
        return addForm
      case 'showAccounts':
        return <SwitchAccountList setOpenDrawer={setOpenDrawer} />
      // case 'showCreateToken':
      //   const createParam = params as CreateTokenParams
      //   return (
      //     <CreatePersonalToken {...createParam} setOpenDrawer={setOpenDrawer} />
      //   )
      // case 'showRevoke':
      //   const revokeParam = params as RevokeTokenParams
      //   return <RevokeToken {...revokeParam} setOpenDrawer={setOpenDrawer} />
      default:
        break
    }
  }

  const toggleDrawer = (open: boolean) => () => {
    setOpenDrawer(open)
  }

  return (
    <div>
      <Fragment key='right'>
        <StyledDrawer
          anchor='right'
          open={openDrawer}
          onClose={toggleDrawer(false)}>
          <div role='presentation'>{displayPage()}</div>
        </StyledDrawer>
      </Fragment>
    </div>
  )
}
