// -- Import NPMS
import {
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// -- Import Components
import {
  StyledInputLabel,
  StyledTextField,
} from '../../components/forms/StyledFormComponents'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useCreateWorkspaceV2Mutation } from '../../slices/workspacesApiSliceV2'

// -- Import Assets

type AddworkspaceProps = {
  onClose?: () => void
  handleCancel?: () => void
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}

interface formInput {
  name: string
  description: string
}

export default function AddWorkspace({
  onClose,
  handleCancel,
}: AddworkspaceProps) {
  const { showError, showSuccess } = useSnackbar()
  const { t } = useTranslation()
  const [createWorkspace, result] = useCreateWorkspaceV2Mutation()
  const { currentAccount } = useCurrentAccount()
  const { register, handleSubmit } = useForm<formInput>({
    shouldUseNativeValidation: true,
  })
  const [loading, setLoading] = useState(false)

  const onSubmit: SubmitHandler<formInput> = useCallback(
    async (data) => {
      const { ...newData } = data
      try {
        await createWorkspace({
          accountId: currentAccount?.id,
          workspace: newData,
        }).unwrap()
        onClose()
        showSuccess(
          t('workspace.messages.WORKSPACE_HAS_BEEN_CREATED_WITH_SUCCESS')
        )
      } catch (error) {
        showError(error)
      }
    },
    [createWorkspace, currentAccount?.id, showError, showSuccess, onClose, t]
  )

  useEffect(() => {
    if (result?.isLoading) {
      setLoading(true)
    }
    if (result?.isSuccess) {
      setLoading(false)
    }
  }, [result])

  return (
    <>
      <Backdrop
        sx={{ color: 'var(--content-primary)', zIndex: '9999' }}
        open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form
        className='workspace__form-container'
        onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <StyledInputLabel shrink htmlFor='workspace-form-name'>
            {t('workspace.NAME')}
          </StyledInputLabel>
          <StyledTextField
            required
            id='workspace-form-name'
            variant='outlined'
            size='small'
            {...register('name', {
              required: t('workspace.NAME_REQUIRED'),
            })}
          />
          <StyledInputLabel shrink htmlFor='workspace-form-description'>
            {t('workspace.DESCRIPTION')}
          </StyledInputLabel>
          <StyledTextField
            id='workspace-form-description'
            variant='outlined'
            size='small'
            {...register('description')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant='outlined' color='primary'>
            {t('modal.CANCEL')}
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            {t('modal.ADD')}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
