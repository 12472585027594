const administrativeStatesError = ['creation_error', 'delete_error']

const administrativeStates = [
  'creation_proceed',
  'creation_pending',
  'delete_proceed',
  'delete_pending',
]

export const disableAdministrativeState = (state: string) => {
  return administrativeStates.includes(state)
}

export const disableActionsForErrorStates = (state: string) => {
  return administrativeStatesError.includes(state)
}
