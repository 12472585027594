// IMPORT DEPENDENCIES
import { Button } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { CreationState } from '../DrawerCreateElementV2'

// IMPORT ICONS
import { Controller, useForm } from 'react-hook-form'
import ChevronLeftIcon from '../../../assets/icons-io-v1/chevron_left_2.svg?react'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useSnackbar } from '../../../hooks/useSnackbar'
import {
  useCheckAvailableVlanQuery,
  useGetAccessVlanQuery,
} from '../../../slices/portsApiSlice'
import {
  StyledAutocomplete,
  StyledInputLabel,
  StyledTextField,
} from '../../forms/StyledFormComponents'

// TYPES
type ConfigureAccessTechnicalInfoProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: () => void
}

interface formInput {
  vlan: number
}

const filter = createFilterOptions<number>()

export default function ConfigureAccessTechnicalInfo({
  newElementData,
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: ConfigureAccessTechnicalInfoProps) {
  const { showError } = useSnackbar()
  const { currentAccount } = useCurrentAccount()
  const { t } = useTranslation()
  const handClickBack = useCallback(
    (creationStep: number) => {
      setNewElementData((previous) => ({
        ...previous,
        vlan: null,
      }))
      setCreationStep(creationStep)
      toggleDrawer()
    },
    [setCreationStep, setNewElementData, toggleDrawer]
  )
  // ---------------------- //
  // ----- VLAN CHECK ----- //
  // ---------------------- //

  const { control, watch } = useForm<formInput>({
    shouldUseNativeValidation: true,
    defaultValues: {
      vlan: null,
    },
  })
  const vlanValue = watch('vlan')

  // check if the manual input vlan is available
  // if yes, no errors or thrown, if error message thrown => vlan not available or improperly entered
  const { error: errorCheckVlan } = useCheckAvailableVlanQuery(
    {
      accountId: currentAccount?.id,
      portId: newElementData?.accessPort?.id,
      vlan: vlanValue,
    },
    {
      skip:
        !currentAccount?.id || !newElementData?.accessPort?.id || !vlanValue,
      refetchOnMountOrArgChange: true,
    }
  )

  // the the list of first 50 available vlans
  const { data: vlanData = [], error: errorVlanData } = useGetAccessVlanQuery(
    {
      accountId: currentAccount?.id,
      portId: newElementData?.accessPort?.id,
    },
    {
      skip: !currentAccount?.id || !newElementData?.accessPort?.id,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (
      errorCheckVlan &&
      'status' in errorCheckVlan &&
      errorCheckVlan.status === 409
    ) {
      return
    }

    const error = errorCheckVlan || errorVlanData
    if (!error) return
    showError(error)
  }, [showError, errorCheckVlan, errorVlanData])

  const setTechnicalInfo = useCallback(() => {
    setNewElementData((previous) => ({
      ...previous,
      vlan: vlanValue,
    }))
    setCreationStep(1)
  }, [vlanValue, setCreationStep, setNewElementData])

  return (
    <>
      <div className='drawer-create__wrapper'>
        <div>
          <div className='drawer-create__header'>
            <div
              className='drawer-create__header--link'
              onClick={() => handClickBack(1)}>
              <ChevronLeftIcon />
            </div>
            {/* // TOTO - ADD LOCALS*/}
            {t('element.TECHNICAL_INFO')}
          </div>
        </div>
        <>
          <>
            <StyledInputLabel shrink htmlFor='element-form-port'>
              {t('workspace.elements.SELECT_VLAN')}
            </StyledInputLabel>
            <Controller
              name='vlan'
              control={control}
              render={({
                field: { onChange, onBlur, value, ...fieldProps },
              }) => {
                return (
                  <StyledAutocomplete
                    {...fieldProps}
                    onBlur={onBlur}
                    onChange={(e, v: number) => {
                      onChange(v)
                    }}
                    value={value || ''}
                    getOptionLabel={(n: number) => `${n ?? ''}`}
                    filterOptions={(options: number[], params): number[] => {
                      let filtered = filter(options, params)
                      const { inputValue } = params
                      // Suggest the creation of a new value
                      const v = parseInt(inputValue, 10)

                      if (
                        !Number.isNaN(v) &&
                        v >= 2 &&
                        v <= 4000 &&
                        !options.some((option) => v === option)
                      ) {
                        filtered = [parseInt(inputValue, 10), ...filtered]
                      }

                      return filtered
                    }}
                    selectOnFocus
                    clearOnBlur
                    options={vlanData}
                    fullWidth
                    renderInput={({
                      inputProps,
                      InputProps,
                      InputLabelProps,
                      ...params
                    }) => {
                      return (
                        <StyledTextField
                          type='number'
                          {...params}
                          slotProps={{
                            inputLabel: {
                              ...InputLabelProps,
                            },
                            htmlInput: {
                              ...inputProps,
                            },
                            input: {
                              ...InputProps,
                              //@ts-expect-error-line // NumberInput
                              min: '2',
                              max: '4000',
                              step: '1',
                            },
                          }}
                        />
                      )
                    }}
                  />
                )
              }}
            />
            {errorCheckVlan && (
              <div className='vlan__container-error'>
                {'status' in errorCheckVlan && errorCheckVlan.status === 409
                  ? t('element.messages.VLAN_ALREADY_IN_USE')
                  : t('element.messages.ERROR_WHILE_VALIDATING_VLAN')}
              </div>
            )}
          </>
        </>
        <div className='drawer-create__techInfo-save'>
          <Button
            variant='contained'
            disabled={!!errorCheckVlan || !vlanValue}
            onClick={() => setTechnicalInfo()}>
            {t('element.SAVE')}
          </Button>
        </div>
      </div>
    </>
  )
}
