import styled from '@emotion/styled'
import { LinearProgress } from '@mui/material'

export const StyleSnackbarProgressBar = styled(LinearProgress)(({ theme }) => ({
  minHeight: '6px',
  backgroundColor: 'transparent',
  '& .MuiLinearProgress-bar': {
    backgroundColor: 'white',
    opacity: '.5',
  },
}))
