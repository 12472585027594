import { Button, styled } from '@mui/material'

export const StyleButton = styled(Button)(({ theme }) => ({
  borderStyle: 'none',
  '&.MuiButton-root': {
    minWidth: 0,
    padding: '5px 10px',
    height: 'unset',
    color: 'var(--root-color-grey-10)',
    fontSize: '.9rem',
    textTransform: 'uppercase',
  },
}))
