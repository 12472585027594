import { API_PATH_METRICS } from '../environments/env'
import { apiSlice } from './apiSlice'
import type { Kind, MetricsData, OperationalStatus } from './types'

const metricsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getElementBandwidthMetrics: builder.query<
      MetricsData<number>,
      {
        id: string
        accountId: string
        workspaceId: string
        params?: { start_d?: string; end_d?: string; range?: string }
        elementType: 'nodes' | 'ports'
      }
    >({
      query: ({ params, id, elementType, workspaceId, accountId }) => ({
        url: `${API_PATH_METRICS}/accounts/${accountId}/workspaces/${workspaceId}/${elementType}/${id}/bandwidth`,
        params,
      }),
      providesTags: (result, error, { id: nodeId }) =>
        result ? [{ type: 'Metrics', id: `bandwidth-${nodeId}` }] : [],
    }),
    getElementStatusMetrics: builder.query<
      MetricsData<boolean>,
      {
        id: string
        accountId: string
        workspaceId: string
        params?: { start_d?: string; end_d?: string; range?: string }
        elementType: Exclude<
          Kind,
          'attachment' | 'service-provider' | 'transport'
        >
      }
    >({
      query: ({ params, id, elementType, workspaceId, accountId }) => ({
        url: `${API_PATH_METRICS}/accounts/${accountId}/workspaces/${workspaceId}/${elementType}s/${id}/status`,
        params,
      }),
      providesTags: (result, error, { id: nodeId }) =>
        result ? [{ type: 'Metrics', id: `status-${nodeId}` }] : [],
    }),
    getElementOperationalStatusMetrics: builder.query<
      { status: OperationalStatus },
      {
        id: string
        accountId: string
        workspaceId: string
        elementType: Exclude<Kind, 'service-provider'>
      }
    >({
      query: ({ id, elementType, workspaceId, accountId }) => ({
        url: `${API_PATH_METRICS}/accounts/${accountId}/workspaces/${workspaceId}/${elementType}s/${id}/operational-status`,
      }),
      providesTags: (result, error, { id: nodeId }) =>
        result ? [{ type: 'Metrics', id: `op-status-${nodeId}` }] : [],
    }),
  }),
})

export const {
  useGetElementBandwidthMetricsQuery,
  useGetElementOperationalStatusMetricsQuery,
  useGetElementStatusMetricsQuery,
} = metricsApiSlice
