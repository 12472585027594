import { LinearProgress } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  calculateStrength,
  passwordStrengthGetColor,
  passwordStrengthIcon,
} from '../../hooks/passwordStrength'

type PasswordStrengthMeterProps = {
  password: string
  strength: number
  setStrength: Dispatch<SetStateAction<number>>
  errors: [{ field: string; reason: string }]
}

const conditions = [
  'ERR_TOO_SHORT', // Minimum 8 characters
  'ERR_PASSWORD_ONE_UPPER', // Uppercase letters
  'ERR_PASSWORD_ONE_LOWER', // Lowercase letters
  'ERR_PASSWORD_ONE_DIGIT', // Numbers
  'ERR_PASSWORD_SPECIAL_CHAR', // Special characters
]

export default function PasswordStrengthMeter({
  password,
  strength,
  setStrength,
  errors,
}: PasswordStrengthMeterProps) {
  const { t } = useTranslation()

  useEffect(() => {
    setStrength(calculateStrength(errors, conditions))
  }, [password, setStrength, errors])

  const displayStrengthColor = useCallback(
    (strength: number) => {
      switch (strength) {
        case 0:
          return <span />
        case 20:
          return (
            <div
              className='password__strength--meter-name'
              style={{ color: 'var(--decorative-password-error)' }}>
              {t('login.VERY_WEAK')}
            </div>
          )
        case 40:
          return (
            <div
              className='password__strength--meter-name'
              style={{ color: 'var(--decorative-password-error)' }}>
              {t('login.WEAK')}
            </div>
          )
        case 60:
          return (
            <div
              className='password__strength--meter-name'
              style={{ color: 'var(--decorative-password-warning)' }}>
              {t('login.FAIR')}
            </div>
          )
        case 80:
          return (
            <div
              className='password__strength--meter-name'
              style={{ color: 'var(--decorative-password-warning)' }}>
              {t('login.GOOD')}
            </div>
          )
        case 100:
          return (
            <div
              className='password__strength--meter-name'
              style={{ color: 'var(--decorative-password-success)' }}>
              {t('login.STRONG')}
            </div>
          )
        default:
          break
      }
    },
    [t]
  )

  return (
    <div className='password__strength--meter'>
      <LinearProgress
        color={passwordStrengthGetColor(strength)}
        value={strength}
        variant='determinate'
        className='password__strength--meter-linear'
      />
      {displayStrengthColor(strength)}

      <div className='password__strength--meter-rules'>
        <div className='password__strength--meter-rules-title'>
          {t('messages.OUR_MIN_REQUIREMENTS')}
        </div>

        <div className='password__strength--meter-rule'>
          <span>{passwordStrengthIcon(errors, 'ERR_TOO_SHORT')}</span>
          <span className='password__strength--meter-text'>
            {t('messages.AT_LEAST_8_CHARS')}
          </span>
        </div>

        <div className='password__strength--meter-rule'>
          <span>{passwordStrengthIcon(errors, 'ERR_PASSWORD_ONE_UPPER')}</span>
          <span className='password__strength--meter-text'>
            {t('messages.AT_LEAST_ONE_UP_LETTER')}
          </span>
        </div>

        <div className='password__strength--meter-rule'>
          <span>{passwordStrengthIcon(errors, 'ERR_PASSWORD_ONE_LOWER')}</span>
          <span className='password__strength--meter-text'>
            {t('messages.AT_LEAST_ONE_LOW_LETTER')}
          </span>
        </div>

        <div className='password__strength--meter-rule'>
          <span>{passwordStrengthIcon(errors, 'ERR_PASSWORD_ONE_DIGIT')}</span>
          <span className='password__strength--meter-text'>
            {t('messages.AT_LEAST_ONE_NUMBER')}
          </span>
        </div>

        <div className='password__strength--meter-rule'>
          <span>
            {passwordStrengthIcon(errors, 'ERR_PASSWORD_SPECIAL_CHAR')}
          </span>
          <span className='password__strength--meter-text'>
            {t('messages.AT_LEAST_ONE_SPEC_CHAR')}
          </span>
        </div>
      </div>
    </div>
  )
}
