import type { ReactNode } from 'react'

interface Props {
  fontWheight?: string
  title: string
  value: string | number | React.ReactNode
  cta?: ReactNode
}
export default function ReadOnlyRow({ title, value, fontWheight, cta }: Props) {
  return (
    <div className='show__element__infos-content'>
      <span style={{ fontWeight: fontWheight }}>{title}</span>
      {!cta && <span>{value}</span>}
      {cta && cta}
    </div>
  )
}
