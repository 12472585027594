// IMPORT DEPENDENCIES
import type { Dispatch, MouseEventHandler, SetStateAction } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

// IMPORT ASSETS
// import useCurrentAccount from '../../hooks/useCurrentAccount'
import { IconButton } from '@mui/material'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'
import { displayElementType } from '../../../hooks/displayElementType'
import { DisplayElementIcon } from '../../../hooks/displayIcons'
import type { CreationState } from '../DrawerCreateElementV2'

// TYPES
type SelectElementProps = {
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: MouseEventHandler<HTMLButtonElement>
}

export default function SelectElement({
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: SelectElementProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()

  const handleClickTypeSelection = useCallback(
    (elementType: string, creationStep: number) => {
      setCreationStep(creationStep)
      setNewElementData((previous) => ({
        ...previous,
        elementType: elementType,
      }))
      navigate(`${location?.pathname}/${elementType}`)
    },
    [location?.pathname, navigate, setCreationStep, setNewElementData]
  )

  return (
    <div className='drawer-create__wrapper'>
      <div className='show__element__container-close'>
        <IconButton
          onClick={toggleDrawer}
          sx={{ padding: '0', height: '24px' }}>
          <CloseIcon fontSize='small' style={{ width: '22px' }} />
        </IconButton>
      </div>
      <div className='drawer-create__header'>New Element</div>
      <div
        className='drawer-create__card'
        onClick={() => handleClickTypeSelection('access', 1)}>
        <div>
          <DisplayElementIcon kind='node' type='access' />
        </div>
        <div className='drawer-create__card--text'>
          {displayElementType('node', 'access', t)}
        </div>
      </div>
      <div
        className='drawer-create__card'
        onClick={() => handleClickTypeSelection('cloud', 1)}>
        <div>
          <DisplayElementIcon kind='node' type='cloud' />
        </div>
        <div className='drawer-create__card--text'>
          {displayElementType('node', 'cloud', t)}
        </div>
      </div>
      <div
        className='drawer-create__card'
        onClick={() => handleClickTypeSelection('transport', 1)}>
        <div>
          <DisplayElementIcon kind='transport' />
        </div>
        <div className='drawer-create__card--text'>
          {displayElementType('transport', '', t)}
        </div>
      </div>
    </div>
  )
}
