import { Button, IconButton, styled } from '@mui/material'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'var(--content-secondary)',
  maxWidth: '28px',
  padding: '4px',
  '&.active': {
    color: 'var(--root-color-action)',
    '& svg': {
      '& path': {
        stroke: 'var(--root-color-action)',
      },
    },
  },
  '&:hover': {
    color: 'var(--root-color-action)',
    backgroundColor: 'transparent',
    '& svg': {
      '& path': {
        stroke: 'var(--root-color-action)',
      },
    },
  },
}))

export const StyledMapIconButton = styled(Button)(() => ({
  // maxWidth: 'unset',
  // maxHeight: 'unset',
  // padding: '10px',
  height: '40px',
  overflow: 'hidden',
  // '& svg': {
  //   width: '26px',
  //   height: '26px',
  // },
  '&:hover': {
    color: 'var(--root-color-grey-10)',
    background: 'var(--root-color-basic-brand-alto-dark)',
  },
}))

export const StyledIdCardIconButton = styled(IconButton)(({ theme }) => ({
  color: 'var(--root-color-action)',
  maxWidth: '28px',
  padding: '4px',
  '&:disabled': {
    color: 'var( --content-tertiary)',
    '& svg': {
      '& path': {
        stroke: 'var( --content-tertiary)',
      },
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'var(--root-color-basic-brand-alto)',
  },
}))

export const StyledAccordionAttachButton = styled(IconButton)(() => ({
  color: 'var(--root-color-action)',
  maxWidth: '28px',
  padding: '4px',
  '&:disabled': {
    color: 'var( --content-tertiary)',
    '& svg': {
      '& path': {
        stroke: 'var( --content-tertiary)',
      },
    },
  },
  '&:hover': {
    color: 'var(--root-color-action)',
    backgroundColor: 'transparent',
    '& svg': {
      '& path': {
        stroke: 'var(--root-color-action)',
      },
    },
  },
}))
