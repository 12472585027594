import styled from '@emotion/styled'
import {
  Backdrop,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
} from '@mui/material'
import { type FC, type ReactNode, useCallback } from 'react'
import CloseIcon from '../../assets/icons-io-v1/close.svg?react'

const StyleButtonClose = styled(IconButton)(() => ({
  width: '24px',
  height: '24px',
  padding: 0,
  '& svg': {
    width: '24px',
    height: '24px',
  },
}))

const StyledDrawerUser = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    background: 'var(--background-primary )',
    minWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    height: '100%',
  },
}))

interface MainDrawerProps {
  onClose: () => void
  closeButton?: boolean
  body: ReactNode
  footer?: ReactNode
  withBackdrop?: boolean
  backdropLoading?: boolean
}

export const MainDrawer: FC<MainDrawerProps> = ({
  body,
  closeButton = true,
  footer = null,
  onClose,
  withBackdrop = false,
  backdropLoading = false,
}) => {
  const onCloseDrawer = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <>
      {withBackdrop && (
        <Backdrop
          sx={{ color: 'var(--root-color-action)', zIndex: '9999' }}
          open={backdropLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <StyledDrawerUser
        open
        anchor='right'
        variant='temporary'
        ModalProps={{
          slotProps: {
            backdrop: {
              onClick: onClose,
            },
          },
        }}>
        {closeButton && (
          <Box display='flex' justifyContent='end'>
            <StyleButtonClose onClick={onCloseDrawer}>
              <CloseIcon fontSize='small' />
            </StyleButtonClose>
          </Box>
        )}
        <Box flexGrow={1}>{body}</Box>
        <Box
          position={'sticky'}
          bottom={0}
          display='flex'
          alignItems='center'
          justifyContent='center'>
          {footer}
        </Box>
      </StyledDrawerUser>
    </>
  )
}
