export const humanizeBandwidth = (bandwidth: number) => {
  if (bandwidth >= 1000) {
    return bandwidth / 1000 + ' Gbps'
  } else {
    return bandwidth + ' Mbps'
  }
}

export const humanizeBandwidthInMetrics = (bandwidth: number) => {
  if (bandwidth >= 1000000000) {
    return (bandwidth / 1000000000).toFixed(2) + ' Gbps'
  } else if (bandwidth >= 1000000) {
    return (bandwidth / 1000000).toFixed(2) + ' Mbps'
  } else if (bandwidth >= 1000) {
    return (bandwidth / 1000).toFixed(2) + ' Kbps'
  } else {
    return bandwidth.toFixed(2) + ' Bps'
  }
}

export const humanizeBandwidthInTickChart = (bandwidth: number) => {
  if (bandwidth >= 1000000000) {
    return (bandwidth / 1000000000).toFixed(0) + ' Gbps'
  } else if (bandwidth >= 1000000) {
    return (bandwidth / 1000000).toFixed(0) + ' Mbps'
  } else if (bandwidth >= 1000) {
    return (bandwidth / 1000).toFixed(0) + ' Kbps'
  } else {
    return bandwidth.toFixed(0) + ' Bps'
  }
}
