// IMPORT DEPENDENCIES
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

// IMPORT COMPONENTS
import useCurrentAccount from '../../hooks/useCurrentAccount'

// IMPORT ASSETS
import { IconButton } from '@mui/material'
import useResizeObserver from 'use-resize-observer'
import CloseIcon from '../../assets/icons-io-v1/close.svg?react'
import { ResourceType } from '../../slices/authorizationsApiSlice'
import { useGetPortByIdV2Query } from '../../slices/portsApiSlice'
import { StyledActionButton } from '../forms/StyledFormComponents'
import Visible from '../permissions/Visible'
import { StyledDrawer } from './StyledDrawer'

// TYPES
type DrawerDeleteItemProps = {
  handleClose?: (newValue?: boolean, element?: { id?: string }) => Promise<void>
}

export default function DrawerDeleteItem({
  handleClose,
}: DrawerDeleteItemProps) {
  const { workspaceId, elementId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()

  // FETCH THE CURRENT ELEMENT DATA
  const { data: elementData } = useGetPortByIdV2Query(
    {
      accountId: currentAccount?.id,
      portId: elementId,
    },
    {
      skip: !currentAccount?.id || !elementId,
      refetchOnMountOrArgChange: true,
    }
  )

  const toggleDrawer = useCallback(() => {
    navigate('/ports')
  }, [navigate])

  const handleConfirm = useCallback(() => {
    handleClose(true, elementData?.data)
    navigate('/ports')
  }, [elementData, handleClose, navigate])

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  return (
    <div key='right'>
      <StyledDrawer anchor='right' open onClose={toggleDrawer}>
        <div className='drawer-create__wrapper' ref={drawerWidth}>
          <div className='show__element__container-close'>
            <IconButton
              onClick={toggleDrawer}
              sx={{ padding: '0', height: '24px' }}>
              <CloseIcon fontSize='small' style={{ width: '22px' }} />
            </IconButton>
          </div>
          <div className='drawer-create__header'>
            {t('modal.CONFIRM_DELETION')}
          </div>
          <div>
            <div>
              {t('element.CONFIRM_ELEMENT_TYPE_DELETION', {
                elementType: 'ports',
                elementName: elementData?.data?.name,
              })}
            </div>
          </div>

          {/* --------------------------------- */}
          {/* ------------ ACTIONS ------------ */}
          {/* --------------------------------- */}
          <div className='element__form--btn' style={{ width }}>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='delete_node'>
              <StyledActionButton
                sx={{
                  width: '49.5%',
                  backgroundColor: 'var(--background-tertiary)',
                  '&:hover': {
                    backgroundColor: 'var(--background-secondary)',
                  },
                }}
                color='primary'
                variant='text'
                onClick={toggleDrawer}>
                {t('element.DISCARD')}
              </StyledActionButton>
              <StyledActionButton
                sx={{ width: '49.5%', marginLeft: '1%' }}
                type='submit'
                color='primary'
                variant='contained'
                onClick={handleConfirm}>
                {t('modal.CONFIRM')}
              </StyledActionButton>
            </Visible>
          </div>
        </div>
      </StyledDrawer>
    </div>
  )
}
