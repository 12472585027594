import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { switchAccountAction } from './accountActions'
import { logoutAction } from './authActions'
import type { Account } from './types'

type AccountSliceState = {
  selectedAccount?: Account
}

const initialState: AccountSliceState = {}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(switchAccountAction, (state, { payload }) => {
        state.selectedAccount = payload
      })
      .addCase(logoutAction, (state) => {
        delete state.selectedAccount
      })
  },
})

const selectAccount = (state: RootState) => state.account
export const selectSelectedAccount = createSelector(
  selectAccount,
  (s) => s.selectedAccount
)
