import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { enqueueSnackbar } from 'notistack' // Assuming you're using notistack for notifications
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next' // Assuming you're using react-i18next for translations
import type { ErrorData } from '../slices/types'

export const useSnackbar = () => {
  const { t } = useTranslation()

  const showError = useCallback(
    (e: FetchBaseQueryError | SerializedError, defaultMessage?: string) => {
      if (e && 'data' in e) {
        const err = e.data as ErrorData
        enqueueSnackbar(t(`messages.errors.${err.code}`), {
          variant: 'snackbarError',
          error: err,
        })
      } else {
        enqueueSnackbar(defaultMessage ?? t('messages.errors.ERR_GEN'), {
          variant: 'error',
        })
      }
    },
    [t]
  )

  const showErrorWithMessage = useCallback((message: string) => {
    enqueueSnackbar(message, {
      variant: 'snackbarProgress',
      color: 'error',
    })
  }, [])

  const showSuccess = useCallback((message: string) => {
    enqueueSnackbar(message, {
      variant: 'snackbarProgress',
      color: 'success',
    })
  }, [])

  return { showError, showSuccess, showErrorWithMessage }
}
