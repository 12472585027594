// IMPORT DEPENDENCIES
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// IMPORT ASSETS
// import useCurrentAccount from '../../hooks/useCurrentAccount'
import { smallElementTypeCard } from '../../../hooks/displayIcons'
import ConfigureAcceess from './ConfigureAccess'
import ConfigureCloud from './ConfigureCloud'
import ConfigureTransport from './ConfigureTransport'

// IMPORT ICONS
import ChevronLeftIcon from '../../../assets/icons-io-v1/chevron_left_2.svg?react'

// TYPES
import { IconButton } from '@mui/material'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'
import type { CreationState } from '../DrawerCreateElementV2'
type ConfigureElementProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: () => void
}

export default function ConfigureElement({
  newElementData,
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: ConfigureElementProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handClickBack = (creationStep: number) => {
    setCreationStep(creationStep)
    setNewElementData({
      creationStep: 0,
      elementType: '',
      elementName: '',
      elementProduct: null,
      elementTechInfo: '',
      accessPort: null,
      cspName: '',
      cspCity: '',
      cspRegion: '',
      bandwidth: '',
      provider: '',
      location: '',
      locationTo: '',
    })
    navigate(-1)
  }

  const showConfigureForm = () => {
    switch (newElementData?.elementType) {
      case 'cloud':
        return (
          <ConfigureCloud
            newElementData={newElementData}
            setCreationStep={setCreationStep}
            setNewElementData={setNewElementData}
            toggleDrawer={toggleDrawer}
          />
        )
      case 'access':
        return (
          <ConfigureAcceess
            newElementData={newElementData}
            setCreationStep={setCreationStep}
            setNewElementData={setNewElementData}
            toggleDrawer={toggleDrawer}
          />
        )
      case 'transport':
        return (
          <ConfigureTransport
            newElementData={newElementData}
            setCreationStep={setCreationStep}
            setNewElementData={setNewElementData}
            toggleDrawer={toggleDrawer}
          />
        )
    }
  }
  return (
    <div className='drawer-create__wrapper'>
      <div className='show__element__container-close'>
        <IconButton
          onClick={toggleDrawer}
          sx={{ padding: '0', height: '24px' }}>
          <CloseIcon fontSize='small' style={{ width: '22px' }} />
        </IconButton>
      </div>
      <div>
        <div className='drawer-create__header'>
          <div
            className='drawer-create__header--link'
            onClick={() => handClickBack(0)}>
            <ChevronLeftIcon />
          </div>
          New Element
        </div>
      </div>

      <div className='drawer-create__id-card'>
        {smallElementTypeCard(newElementData?.elementType, t)}
      </div>
      {showConfigureForm()}
    </div>
  )
}
