// IMPORT DEPENDENCIES
import type { Dispatch, MouseEventHandler, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

// IMPORT ICONS
import ChevronLeftIcon from '../../../assets/icons-io-v1/chevron_left_2.svg?react'

// TYPES
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'
import type { CreationState } from '../DrawerCreateElementV2'
import ConfigureAccessProduct from './ConfigureAccessProduct'
import ConfigureCloudProduct from './ConfigureCloudProduct'
import ConfigureTransportProduct from './ConfigureTransportProduct'
type ConfigureProductProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: MouseEventHandler<HTMLButtonElement>
}

export default function ConfigureProduct({
  newElementData,
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: ConfigureProductProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handClickBack = (creationStep: number) => {
    setNewElementData({
      ...newElementData,
      cspName: '',
      cspCity: '',
      cspRegion: '',
      bandwidth: '',
      provider: '',
      location: '',
      locationTo: '',
      type: '',
    })
    setCreationStep(creationStep)
    navigate(-1)
  }

  const displayProductList = () => {
    switch (newElementData?.elementType) {
      case 'cloud':
        return (
          <ConfigureCloudProduct
            newElementData={newElementData}
            setNewElementData={setNewElementData}
            setCreationStep={setCreationStep}
          />
        )
      case 'access':
        return (
          <ConfigureAccessProduct
            newElementData={newElementData}
            setNewElementData={setNewElementData}
            setCreationStep={setCreationStep}
          />
        )
      case 'transport':
        return (
          <ConfigureTransportProduct
            newElementData={newElementData}
            setNewElementData={setNewElementData}
            setCreationStep={setCreationStep}
          />
        )
    }
  }

  return (
    <>
      <div className='drawer-create__wrapper'>
        <div className='show__element__container-close'>
          <IconButton
            onClick={toggleDrawer}
            sx={{ padding: '0', height: '24px' }}>
            <CloseIcon fontSize='small' style={{ width: '22px' }} />
          </IconButton>
        </div>
        <div>
          <div className='drawer-create__header'>
            <div
              className='drawer-create__header--link'
              onClick={() => handClickBack(1)}>
              <ChevronLeftIcon />
            </div>

            {t('element.ADD_ELEMENT_PRODUCT', {
              elementType: newElementData?.elementType,
            })}
          </div>
        </div>

        {displayProductList()}
      </div>
    </>
  )
}
