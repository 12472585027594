// IMPORT DEPENDENCIES
import { InputLabel, TextField } from '@mui/material'
import type { ChangeEventHandler, Dispatch, SetStateAction } from 'react'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

// IMPORT STYLES
import {
  StyledActionButton,
  StyledButton,
} from '../../forms/StyledFormComponents'

// IMPORT ICONS
import AddIcon from '../../../assets/icons-io-v1/add.svg?react'
import EditIcon from '../../../assets/icons-io-v1/edit.svg?react'

// TYPES
import useResizeObserver from 'use-resize-observer'
import { displayElementType } from '../../../hooks/displayElementType'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { ResourceType } from '../../../slices/authorizationsApiSlice'
import type { AccessCreateFormData } from '../../../slices/types'
import { useCreateNodeInWorkspaceV2Mutation } from '../../../slices/workspacesApiSliceV2'
import { DOCS_URL } from '../../../views/Login/SetPassword'
import FormatMonthlyPrice from '../../format/monthlyPrice'
import Visible from '../../permissions/Visible'
import type { CreationState } from '../DrawerCreateElementV2'
import ReadOnlyRow from '../ReadOnlyRows'

type ConfigureCloudProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: () => void
}

export default function ConfigureAccess({
  newElementData,
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: ConfigureCloudProps) {
  const { showError, showSuccess } = useSnackbar()
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { currentAccount } = useCurrentAccount()
  const [createElementNode] = useCreateNodeInWorkspaceV2Mutation()
  const { t } = useTranslation()

  const handleAddProduct = () => {
    setCreationStep(2)
    navigate(`product`)
  }

  const handleAccessPort = () => {
    setCreationStep(4)
    navigate(`port`)
  }

  const handleTechnicalInfo = () => {
    setCreationStep(5)
    navigate(`technicalInfo`)
  }

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  const createElement = useCallback(async () => {
    const data = newElementData
    let accessNode: AccessCreateFormData

    if (!data) {
      return
    }

    if (data.elementProduct.type === 'PHYSICAL') {
      accessNode = {
        type: 'access',
        name: data.elementName,
        product: { sku: data.elementProduct.sku },
        physicalPortId: data.accessPort.id,
        vlan: data.vlan,
      }
    } else {
      accessNode = {
        type: 'access',
        name: data.elementName,
        product: { sku: data.elementProduct.sku },
      }
    }

    try {
      await createElementNode({
        accountId: currentAccount?.id,
        workspaceId: workspaceId,
        node: accessNode,
      }).unwrap()
      toggleDrawer()
      showSuccess(
        t('element.messages.ELEMENT_HAS_BEEN_CREATED_WITH_SUCCESS', {
          element: displayElementType('node', 'access', t),
        })
      )
    } catch (e) {
      showError(e)
    }
  }, [
    newElementData,
    createElementNode,
    currentAccount?.id,
    workspaceId,
    toggleDrawer,
    showSuccess,
    t,
    showError,
  ])

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) =>
      setNewElementData((previous) => ({
        ...previous,
        elementName: e.target.value,
      })),
    [setNewElementData]
  )

  const isValidated = () => {
    if (
      (newElementData?.elementProduct?.type === 'PHYSICAL' &&
        newElementData?.elementName !== '' &&
        newElementData?.elementProduct?.sku !== undefined &&
        newElementData?.vlan !== null) ||
      (newElementData?.elementProduct?.type === 'VIRTUAL' &&
        newElementData?.elementName !== '' &&
        newElementData?.elementProduct?.sku !== undefined)
    )
      return true
    else return false
  }

  return (
    <>
      <div className='drawer-create__containers' ref={drawerWidth}>
        <div className='drawer-create__columns-input'>
          <InputLabel
            htmlFor='element-form-name'
            className='drawer__forms--labels'>
            {t('element.ELEMENT_NAME')}
          </InputLabel>
          <TextField
            required
            id='element-form-name'
            variant='outlined'
            size='small'
            value={newElementData?.elementName}
            sx={{
              border: '1px solid var(--border-primary)',
              borderRadius: 'var(--border-radius)',
            }}
            onChange={onChange}
            slotProps={{
              htmlInput: {
                form: {
                  autocomplete: 'off',
                },
              },
            }}
          />
        </div>
      </div>
      <div className='drawer-create__spacer-wrapper'>
        {/* ----- PRODUCT ----- */}
        {Object.keys(newElementData?.elementProduct ?? {}).length === 0 ? (
          <div className='drawer-create__containers-product-info'>
            <div className='drawer-create__product-rows'>
              <div className='drawer-create__columns-title'>
                {t('element.PRODUCT')}
              </div>
              <div className='drawer-create__columns-button'>
                <StyledButton
                  variant='outlined'
                  startIcon={<AddIcon height='22' width='22' />}
                  onClick={() => handleAddProduct()}>
                  Add
                </StyledButton>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='drawer-create__containers-product-info'>
              <div className='drawer-create__product-rows'>
                <div className='drawer-create__columns-title header'>
                  {t('element.PRODUCT')}
                </div>
                <div className='drawer-create__columns-button'>
                  <StyledButton
                    variant='outlined'
                    startIcon={<EditIcon height='22' width='22' />}
                    onClick={() => handleAddProduct()}>
                    Replace
                  </StyledButton>
                </div>
              </div>

              <ReadOnlyRow
                title={t('element.PROVIDER')}
                value={newElementData.elementProduct.provider}
              />

              <ReadOnlyRow
                title={t('element.LOCATION')}
                value={newElementData.elementProduct.location}
              />

              <ReadOnlyRow
                title={t('element.BANDWIDTH')}
                value={humanizeBandwidth(
                  newElementData.elementProduct.bandwidth
                )}
              />

              <ReadOnlyRow
                title={t('element.SKU')}
                value={newElementData.elementProduct.sku}
              />

              <ReadOnlyRow
                title={t('element.PRICE')}
                value={
                  <FormatMonthlyPrice
                    price={newElementData.elementProduct.priceMrc}
                  />
                }
              />
            </div>
          </>
        )}
        {/* ----- PORT ----- */}
        {newElementData.elementProduct?.type === 'PHYSICAL' && (
          <>
            {Object.keys(newElementData?.accessPort ?? {}).length === 0 ? (
              <div className='drawer-create__containers' ref={drawerWidth}>
                {/*// TODO - INSERT LOCAL */}
                <div className='drawer-create__product-rows'>
                  <div className='drawer-create__columns-title'>
                    {t('element.PORT')}
                  </div>
                  <div className='drawer-create__columns-button'>
                    <StyledButton
                      variant='outlined'
                      startIcon={<AddIcon height='22' width='22' />}
                      onClick={() => handleAccessPort()}
                      disabled={
                        Object.keys(newElementData?.elementProduct ?? {})
                          .length <= 0
                      }>
                      Add
                    </StyledButton>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='drawer-create__containers-product-info'>
                  <div className='drawer-create__product-rows '>
                    <div className='drawer-create__columns-title header'>
                      {t('element.PORT')}
                    </div>
                    <div className='drawer-create__columns-button'>
                      <StyledButton
                        variant='outlined'
                        startIcon={<EditIcon height='22' width='22' />}
                        onClick={() => handleAccessPort()}>
                        Replace
                      </StyledButton>
                    </div>
                  </div>

                  <div className='drawer-create__product-rows'>
                    <div className='drawer-create__columns-title'>
                      {t('element.PORT_NAME')}
                    </div>
                    <div className='drawer-create__columns-info'>
                      {newElementData?.accessPort?.name}
                    </div>
                  </div>

                  <div className='drawer-create__product-rows'>
                    <div className='drawer-create__columns-title'>
                      {t('element.BANDWIDTH')}
                    </div>
                    <div className='drawer-create__columns-info'>
                      {humanizeBandwidth(
                        newElementData?.accessPort?.product?.bandwidth
                      )}
                    </div>
                  </div>

                  <div className='drawer-create__product-rows'>
                    <div className='drawer-create__columns-title'>
                      {t('element.AVAILABLE_CAPACITY')}
                    </div>
                    <div className='drawer-create__columns-info'>
                      {humanizeBandwidth(
                        newElementData?.accessPort?.availableBandwidth
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {/* ----- TECHNICAL INFOS ----- */}
        {newElementData.elementProduct?.type === 'PHYSICAL' && (
          <>
            {newElementData?.vlan === null ? (
              <div className='drawer-create__containers' ref={drawerWidth}>
                {/*// TODO - INSERT LOCAL */}
                <div className='drawer-create__product-rows'>
                  <div className='drawer-create__columns-title'>
                    {t('element.TECHNICAL_INFO')}
                  </div>
                  <div className='drawer-create__columns-button'>
                    <StyledButton
                      variant='outlined'
                      startIcon={<AddIcon height='22' width='22' />}
                      onClick={() => handleTechnicalInfo()}
                      disabled={
                        Object.keys(newElementData?.accessPort ?? {}).length <=
                        0
                      }>
                      Add
                    </StyledButton>
                  </div>
                </div>
              </div>
            ) : (
              <div className='drawer-create__containers'>
                {/*// TODO - INSERT LOCAL */}
                <div className='drawer-create__product-rows'>
                  <div className='drawer-create__columns-title header'>
                    {t('element.TECHNICAL_INFO')}
                  </div>
                </div>
                <div className='drawer-create__product-rows'>
                  <div className='drawer-create__columns-title'>
                    <span>{t('element.VLAN')}</span>
                  </div>
                  <div className='drawer-create__columns-info'>
                    <span>{newElementData?.vlan}</span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {/* ------------ ACTIONS ------------ */}
        <div className='element__form--btn' style={{ width }}>
          {/* ---------- DISCLAIMERS ---------- */}
          <div className='element__form--btn-disclaimer'>
            {t('disclaimers.CREATING_ELEMENT')}
            <a href={DOCS_URL} target='_blank' rel='noreferrer'>
              {DOCS_URL}
            </a>
          </div>

          <Visible
            resource={ResourceType.Workspace}
            resourceId={workspaceId}
            action='add_node'>
            <StyledActionButton
              sx={{
                width: '49.5%',
                backgroundColor: 'var(--background-tertiary)',
                '&:hover': {
                  backgroundColor: 'var(--background-secondary)',
                },
              }}
              color='primary'
              variant='text'
              onClick={toggleDrawer}>
              {t('element.DISCARD')}
            </StyledActionButton>
            <StyledActionButton
              sx={{ width: '49.5%', marginLeft: '1%' }}
              type='submit'
              color='primary'
              variant='contained'
              disabled={!isValidated()}
              onClick={createElement}>
              {t('element.CREATE')}
            </StyledActionButton>
          </Visible>
        </div>
      </div>
    </>
  )
}
