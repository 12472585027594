import { ArrowBackIos } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
  styled,
} from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { StyledActionButton } from '../../components/forms/StyledFormComponents'
import { switchAccountAction } from '../../slices/accountActions'
import { useGetAccountsQuery } from '../../slices/accountApiSlice'
import { apiSlice } from '../../slices/apiSlice'
import type { Account } from '../../slices/types'
import UserAvatar from '../User/Avatar'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  color: var(--content-primary);
`

const Header = styled(Box)`
  font-family: Raleway;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 88px;
  padding-left: 24px;
`

const AccountRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 525;
  line-height: 20px;
  padding: 24px 18px;
`

const RowDivider = styled(Box)<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: ${({ selected }) =>
    selected ? 'var(--background-secondary)' : 'var(--background-primary)'};
`

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`

const BackIcon = styled(ArrowBackIos)`
  color: var(--content-primary);
`

const ButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 68px;
`

const FooterWrapper = styled(Box)`
  height: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FooterSeparatorWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const AccountsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
  overflow: auto;
`

const ColumnDivider = styled(Divider)`
  width: 190px;
  height: 0;
`

type SwitchAccountListProps = {
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
}

const SwitchAccountList = ({ setOpenDrawer }: SwitchAccountListProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>()

  const { data: accounts, isLoading } = useGetAccountsQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const handleRowClick = useCallback(
    (account: Account) => {
      setSelectedAccount((prevSelectedAccount) => {
        if (prevSelectedAccount?.id === account.id) {
          return prevSelectedAccount
        } else {
          dispatch(apiSlice.util.invalidateTags([]))
          return account
        }
      })
    },
    [dispatch]
  )

  const handleSwitchClick = useCallback(() => {
    dispatch(switchAccountAction(selectedAccount))
    setOpenDrawer(false)
  }, [selectedAccount, setOpenDrawer, dispatch])

  return (
    <Container>
      <Header>
        <IconButton onClick={() => setOpenDrawer(false)}>
          <BackIcon />
        </IconButton>
        {t('element.SWITCH_ACCOUNT')}
      </Header>
      <Divider />
      {isLoading && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}
      <AccountsWrapper>
        {accounts?.map((account) => (
          <RowDivider
            key={account.id}
            selected={account.id === selectedAccount?.id}
            onClick={() => handleRowClick(account)}>
            <AccountRow key={account.id}>
              <UserAvatar userName={account.name} />
              <Typography component={'div'}>{account.name}</Typography>
            </AccountRow>
            <Divider />
          </RowDivider>
        ))}
      </AccountsWrapper>

      {!isLoading && (
        <FooterWrapper>
          <FooterSeparatorWrapper>
            <ColumnDivider />
            <ColumnDivider />
          </FooterSeparatorWrapper>
          <ButtonsWrapper>
            <StyledActionButton
              sx={{
                width: 142,
                color: 'var(--content-primary)',
              }}
              color='primary'
              variant='text'
              onClick={() => setOpenDrawer(false)}>
              {t('element.DISCARD')}
            </StyledActionButton>
            <StyledActionButton
              style={{ width: 142, color: '#fff' }}
              type='submit'
              disabled={!selectedAccount}
              variant='contained'
              onClick={handleSwitchClick}>
              {t('element.SWITCH')}
            </StyledActionButton>
          </ButtonsWrapper>
        </FooterWrapper>
      )}
    </Container>
  )
}

export default SwitchAccountList
