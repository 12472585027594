import { API_PATH_ACCOUNTS } from '../environments/env'
import { apiSlice } from './apiSlice'
import type {
  Account,
  CreateTokenData,
  CreateTokenParams,
  PersonalToken,
  RevokeTokenParams,
} from './types'

export const accountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query<Account[], string>({
      query: () => `${API_PATH_ACCOUNTS}`,
      providesTags: ['Accounts'],
      keepUnusedDataFor: 5,
    }),
    getAccountById: builder.query<Account, string>({
      query: (accountId) => `${API_PATH_ACCOUNTS}/${accountId}`,
      providesTags: ['Accounts'],
      keepUnusedDataFor: 5,
    }),
    getPersonalTokens: builder.query<
      PersonalToken[],
      { accountId: string; userId: string }
    >({
      query: ({ accountId, userId }) =>
        `${API_PATH_ACCOUNTS}/${accountId}/users/${userId}/pats`,
      keepUnusedDataFor: 5,
    }),
    createPersonalToken: builder.mutation<
      PersonalToken,
      { accountId: string; userId: string; body: CreateTokenData }
    >({
      query({ accountId, userId, body }) {
        return {
          url: `${API_PATH_ACCOUNTS}/${accountId}/users/${userId}/pats`,
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(
        { accountId, userId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            accountApiSlice.util.updateQueryData(
              'getPersonalTokens',
              { accountId, userId },
              (personalTokens) => {
                personalTokens.push(data)
              }
            )
          )
        } catch {}
      },
    }),
    revokePersonalToken: builder.mutation<void, RevokeTokenParams>({
      query: ({ accountId, userId, tokenId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users/${userId}/pats/${tokenId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { accountId, userId, tokenId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled
          dispatch(
            accountApiSlice.util.updateQueryData(
              'getPersonalTokens',
              { accountId, userId },
              (personalTokens) => {
                return personalTokens.filter((token) => token.id !== tokenId)
              }
            )
          )
        } catch {}
      },
    }),
    revokePersonalTokens: builder.mutation<void, CreateTokenParams>({
      query: ({ accountId, userId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/users/${userId}/pats`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAccountByIdQuery,
  useGetAccountsQuery,
  useGetPersonalTokensQuery,
  useCreatePersonalTokenMutation,
  useRevokePersonalTokenMutation,
  useRevokePersonalTokensMutation,
} = accountApiSlice
