import { API_PATH_ACCOUNTS } from '../environments/env'
import { apiSlice } from './apiSlice'
import type {
  AccessCreateFormData,
  AccessElement,
  AccessElementDataV2,
  AccessElementV2,
  Attachment,
  AttachmentCreateFormData,
  AttachmentElementData,
  CloudCreateFormData,
  CloudElement,
  CloudElementDataV2,
  CloudElementV2,
  NodeElement,
  TransportCreateFormData,
  TransportElementV2,
  TransportV2,
  WorkspaceDataAPI,
  WorkspaceSetting,
  WorkspaceV2,
  WorkspaceV2API,
} from './types'

export const workspacesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -----------------------------------
    // ----- ENDPOINTS -> WORKSPACES ----- V2 ok
    // -----------------------------------
    getWorkspacesV2: builder.query({
      query: (accountId) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces`,
      }),
      providesTags: ['Workspaces'],
      keepUnusedDataFor: 5,
    }),

    getWorkspaceByIdV2: builder.query<
      WorkspaceV2,
      { accountId: string; workspaceId: string }
    >({
      query: ({ accountId, workspaceId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}`,
      }),
      transformResponse: (responseAPI: WorkspaceDataAPI): WorkspaceV2 => {
        var response: WorkspaceV2 = {
          terms: responseAPI.terms,
          data: {
            ...responseAPI.data,
            transports: responseAPI.data.transports.map((t) => ({
              ...t,
              kind: 'transport',
            })),
            nodes: responseAPI.data.nodes.map((t) => {
              return {
                ...t,
                kind: 'node',
              }
            }),
            attachments: responseAPI.data.attachments.map((t) => ({
              ...t,
              kind: 'attachment',
            })),
          },
        }

        return response
      },
      providesTags: (result, error, { workspaceId: id }) =>
        result ? [{ type: 'Workspaces' as const, id }] : ['Workspaces'],
      keepUnusedDataFor: 5,
    }),

    createWorkspaceV2: builder.mutation<
      WorkspaceDataAPI,
      { accountId: string; workspace: { name: string; description: string } }
    >({
      query: ({ accountId, workspace: body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Workspaces'],
    }),

    deleteWorkspaceV2: builder.mutation<
      WorkspaceDataAPI,
      { accountId: string; id: string }
    >({
      query: ({ accountId, id }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Workspaces', 'Nodes', 'Attachments', 'Transports'],
    }),

    updateWorkspaceV2: builder.mutation<
      WorkspaceDataAPI,
      { accountId: string; workspace: WorkspaceSetting }
    >({
      query({ accountId, workspace: body }) {
        return {
          url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${body.id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: (result, error, { workspace: { id } }) =>
        result ? [{ type: 'Workspaces' as const, id }] : ['Workspaces'],
    }),

    // ---------------------------------
    // ----- ENDPOINTS -> ELEMENTS -----
    // ---------------------------------
    getElementByIdV2: builder.query<
      { data: NodeElement | AttachmentElementData | TransportElementV2 }, //@refacto
      {
        accountId: string
        workspaceId: string
        elementType: 'nodes' | 'transports' | 'attachments'
        elementId: string
      }
    >({
      query: ({ accountId, workspaceId, elementType, elementId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/${elementType}/${elementId}`,
      }),
      providesTags: (result, error, { elementId: id, elementType }) => {
        if (!result) {
          return []
        }

        switch (elementType) {
          case 'attachments':
            return [{ type: 'Attachments' as const, id }]
          case 'nodes':
            return [{ type: 'Nodes' as const, id }]
          case 'transports':
            return [{ type: 'Transports' as const, id }]
        }
      },
      keepUnusedDataFor: 5,
      transformResponse: (
        response: {
          data:
            | CloudElement
            | AccessElement
            | TransportElementV2
            | AttachmentElementData
        }, //@refacto
        _,
        { elementType }
      ) => {
        switch (elementType) {
          case 'attachments':
            return {
              ...response,
              data: {
                ...response.data,
                kind: 'attachment',
              } as AttachmentElementData,
            }
          case 'nodes':
            return {
              ...response,
              data: {
                ...response.data,
                kind: 'node',
              } as CloudElement | AccessElement,
            }
          case 'transports':
            return {
              ...response,
              data: {
                ...response.data,
                kind: 'transport',
              } as TransportElementV2,
            }
        }
      },
    }),

    validateNode: builder.mutation<
      CloudElementV2 | AccessElementV2,
      {
        accountId: string
        workspaceId: string
        node: CloudCreateFormData | AccessCreateFormData
      }
    >({
      query: ({ accountId, workspaceId, node: body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/nodes/validate`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, { workspaceId }) => [
        { type: 'Workspaces', id: workspaceId },
        'Nodes',
      ],
    }),

    createNodeInWorkspaceV2: builder.mutation<
      CloudElementV2 | AccessElementV2,
      {
        accountId: string
        workspaceId: string
        node: CloudCreateFormData | AccessCreateFormData
      }
    >({
      query: ({ accountId, workspaceId, node: body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/nodes`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, { workspaceId }) => [
        { type: 'Workspaces', id: workspaceId },
        'Nodes',
      ],
    }),

    deleteNodeInWorkspaceV2: builder.mutation<
      void,
      { accountId: string; id: string; workspaceId: string }
    >({
      query: ({ accountId, workspaceId, id }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/nodes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { workspaceId, id }) => [
        { type: 'Workspaces', id: workspaceId },
        { type: 'Nodes', id },
      ],
    }),

    updateNodeInWorkspaceV2: builder.mutation<
      CloudElementDataV2 | AccessElementDataV2,
      {
        accountId: string
        nodeId: string
        workspaceId: string
        node: { name: string }
      }
    >({
      query({ accountId, workspaceId, node: body, nodeId }) {
        return {
          url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/nodes/${nodeId}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: (result, error, { workspaceId, nodeId }) => [
        { type: 'Workspaces', id: workspaceId },
        { type: 'Nodes', id: nodeId },
      ],
    }),

    // -------------------------------
    // ---------- TRANSPORT ----------
    // -------------------------------
    createTransportInWorkspaceV2: builder.mutation<
      TransportV2,
      {
        accountId: string
        workspaceId: string
        transport: TransportCreateFormData
      }
    >({
      query: ({ accountId, workspaceId, transport: body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/transports`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspaces'],
    }),

    updateTransportInWorkspaceV2: builder.mutation<
      TransportV2,
      {
        accountId: string
        workspaceId: string
        transport: { name: string }
        transportId: string
      }
    >({
      query({ accountId, workspaceId, transport: body, transportId }) {
        return {
          url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/transports/${transportId}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: (result, error, { workspaceId, transportId }) => [
        { type: 'Workspaces', id: workspaceId },
        { type: 'Transports', id: transportId },
      ],
    }),

    getTransportIdInWorkspaceV2: builder.query<
      TransportV2,
      { accountId: string; workspaceId: string; transportId: string }
    >({
      query: ({ accountId, workspaceId, transportId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/transports/${transportId}`,
      }),
      providesTags: (result, error, { workspaceId, transportId }) =>
        result ? [{ type: 'Transports', id: result.data.id }] : [],
      keepUnusedDataFor: 5,
      transformResponse: (response: Omit<TransportV2, 'kind'>) => {
        return {
          ...response,
          data: {
            ...response.data,
            kind: 'transport',
          },
        }
      },
    }),

    deleteTransportInWorkspaceV2: builder.mutation<
      WorkspaceV2API,
      { accountId: string; id: string; workspaceId: string }
    >({
      query: ({ accountId, workspaceId, id }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/transports/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { workspaceId, id }) => [
        'Workspaces',
        { type: 'Transports', id },
      ],
    }),

    // -------------------------------
    // ---------- ATTACHMENT ----------
    // -------------------------------
    getAttachments: builder.query({
      query: ({ accountId, workspaceId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/attachments`,
      }),
      providesTags: ['Attachments'],
      keepUnusedDataFor: 5,
    }),

    availableAttachments: builder.query<
      {
        data:
          | {
              nodes: (Pick<CloudElement, 'id'> | Pick<AccessElement, 'id'>)[]
            }
          | { transports: Pick<TransportElementV2, 'id'>[] }
      },
      {
        accountId: string
        workspaceId: string
        elementType: string
        elementId: string
      }
    >({
      query: ({ accountId, workspaceId, elementType, elementId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/${elementType}/${elementId}/available-attachments`,
      }),
      keepUnusedDataFor: 5,
    }),

    createAttachment: builder.mutation<
      Attachment,
      {
        accountId: string
        workspaceId: string
        attachment: AttachmentCreateFormData
      }
    >({
      query: ({ accountId, workspaceId, attachment: body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/attachments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) =>
        result
          ? [
              'Workspaces',
              { type: 'Transports', id: result.data.transportId },
              { type: 'Nodes', id: result.data.nodeId },
            ]
          : [],
    }),

    getAttachmentId: builder.query<
      Attachment,
      { accountId: string; workspaceId: string; attachmentId: string }
    >({
      query: ({ accountId, workspaceId, attachmentId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/attachments/${attachmentId}`,
      }),
      providesTags: (result, error, { attachmentId }) =>
        result ? [{ type: 'Attachments', id: attachmentId }] : [],
      keepUnusedDataFor: 5,
    }),

    deleteAttachment: builder.mutation<
      void,
      { accountId: string; workspaceId: string; attachmentId: string }
    >({
      query: ({ accountId, workspaceId, attachmentId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/attachments/${attachmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { attachmentId }) => [
        'Workspaces',
        'Attachments',
        'Nodes',
        'Transports',
      ],
    }),

    // ------------------------------
    // ----- ENDPOINTS -> USERS -----
    // ------------------------------
    createUserProfileInWorkspaceV2: builder.mutation({
      query: ({ accountId, workspaceId, userProfile: body }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/members`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Workspaces', 'WorkspacesUsers'],
    }),

    deleteUserProfileInWorkspaceV2: builder.mutation({
      query: ({ accountId, workspaceId, userId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/members/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Workspaces', 'WorkspacesUsers'],
    }),

    getWorkspaceAllowedUsersV2: builder.query({
      query: ({ accountId, workspaceId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/workspaces/${workspaceId}/members`,
      }),
      providesTags: ['WorkspacesUsers'],
    }),
  }),
})

export const {
  useGetWorkspacesV2Query,
  useGetElementByIdV2Query,
  useGetWorkspaceByIdV2Query,
  useCreateWorkspaceV2Mutation,
  useDeleteWorkspaceV2Mutation,
  useUpdateWorkspaceV2Mutation,
  useCreateUserProfileInWorkspaceV2Mutation,
  useDeleteUserProfileInWorkspaceV2Mutation,
  useGetWorkspaceAllowedUsersV2Query,
  useValidateNodeMutation,
  useCreateNodeInWorkspaceV2Mutation,
  useDeleteNodeInWorkspaceV2Mutation,
  useUpdateNodeInWorkspaceV2Mutation,
  useCreateTransportInWorkspaceV2Mutation,
  useDeleteTransportInWorkspaceV2Mutation,
  useGetTransportIdInWorkspaceV2Query,
  useUpdateTransportInWorkspaceV2Mutation,
  useCreateAttachmentMutation,
  useDeleteAttachmentMutation,
  useGetAttachmentIdQuery,
  useGetAttachmentsQuery,
  useAvailableAttachmentsQuery,
} = workspacesApiSlice
