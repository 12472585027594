// -- Import NPM
import { useTranslation } from 'react-i18next'

// Import Components
import { Fragment, useCallback, useMemo } from 'react'
import AttachmentIcon from '../../../assets/icons-io-v1/attachment.svg?react'
import DetachmentIcon from '../../../assets/icons-io-v1/detachment.svg?react'
import AdministrativeStatus from '../../../components/chips/administrativeStatus'
import { displayElementType } from '../../../hooks/displayElementType'
import { attachmentElementIcons } from '../../../hooks/displayIcons'
import type {
  AttachmentElementData,
  CloudElement,
  WorkspaceV2,
} from '../../../slices/types'
import { AttachmentIsDetachable } from './Attachments'

//Import Icons
import { useNavigate } from 'react-router-dom'
import ShowIcon from '../../../assets/icons-io-v1/show.svg?react'
import { StyledButton } from '../../../components/forms/StyledFormComponents'
import Visible from '../../../components/permissions/Visible'
import { usePermissions } from '../../../hooks/usePermissions'
import { ResourceType } from '../../../slices/authorizationsApiSlice'

type AccordionCloudProps = {
  data: CloudElement
  workspaceData?: WorkspaceV2
}

const DisplayAttachmentButton = ({
  attachment,
  workspaceData,
  data,
}: {
  attachment: AttachmentElementData
  workspaceData: AccordionCloudProps['workspaceData']
  data: AccordionCloudProps['data']
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const nodeAttached = workspaceData?.data?.transports?.find(
    (element) => element?.id === attachment?.transportId
  )

  const showDetailsAttachment = useCallback(() => {
    navigate(`details/attachments/${attachment?.id}/properties`)
  }, [attachment?.id, navigate])

  const openAttachmentConfiguration = useCallback(() => {
    navigate(`attachment/cloud/${data?.id}/${attachment?.side}`)
  }, [navigate, data?.id, attachment?.side])

  return (
    <div className='product__container--current-prod'>
      {attachmentElementIcons(nodeAttached?.kind, '', '')}
      <span className='product__container--attachments-type'>
        {displayElementType(nodeAttached?.kind, '', t)}
      </span>
      <span className='product__container--attachments-name'>
        {nodeAttached?.name}
      </span>
      <span className='product__container--attachments-status'>
        <AdministrativeStatus
          status={attachment?.administrativeState}
          attachment={true}
        />
      </span>

      <Visible
        resource={ResourceType.Workspace}
        resourceId={data?.id}
        action='get_attachment'>
        <StyledButton
          sx={{
            margin: 0,
            marginLeft: '10px',
            padding: '0 0 0 12px',
            minWidth: 'unset',
          }}
          variant='outlined'
          disableRipple
          startIcon={<ShowIcon />}
          onClick={showDetailsAttachment}
        />
      </Visible>

      <Visible
        resource={ResourceType.Workspace}
        resourceId={data?.id}
        action='delete_attachment'>
        <StyledButton
          sx={{ marginLeft: '12px' }}
          variant='outlined'
          startIcon={<DetachmentIcon />}
          disabled={!AttachmentIsDetachable(attachment?.administrativeState)}
          onClick={openAttachmentConfiguration}>
          Detach
        </StyledButton>
      </Visible>
    </div>
  )
}

export default function CloudDetails({
  data,

  workspaceData,
}: AccordionCloudProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { allowed } = usePermissions(
    ResourceType.Workspace,
    workspaceData.data.id,
    'add_attachment'
  )

  const openAttachmentConfiguration = useCallback(() => {
    navigate(`attachment/cloud/${data?.id}`)
  }, [navigate, data?.id])

  const attachment = useMemo(() => {
    return workspaceData?.data?.attachments?.find(
      (attachment) => attachment?.nodeId === data?.id
    )
  }, [data?.id, workspaceData?.data?.attachments])

  return (
    <>
      <div className='accordion__container-open'>
        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>CSP</div>
          <div className='accordion__open--details-info'>
            {data?.product?.cspName ? data?.product?.cspName : ' - '}
          </div>
        </div>

        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>Provider</div>
          <div className='accordion__open--details-info'>
            {data?.product?.provider ? data?.product?.provider : ' - '}
          </div>
        </div>

        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>Location</div>
          <div className='accordion__open--details-info'>
            {data?.product?.location ? data?.product?.location : ' - '}
          </div>
        </div>

        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>CSP City</div>
          <div className='accordion__open--details-info'>
            {data?.product?.cspCity ? data?.product?.cspCity : ' - '}
          </div>
        </div>

        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>
            {t('element.VLAN')}
          </div>
          <div className='accordion__open--details-info'>
            {data?.vlan ? data?.vlan : ' - '}
          </div>
        </div>
      </div>

      <div className='accordion__container-open'>
        <Fragment>
          <div className='accordion__container-open--details'>
            <div className='accordion__open--details-info'>
              <div className='accordion__open--details-info-attachment'>
                {t('element.ATTACHMENT')}: {data?.product?.location}
              </div>
              {attachment ? (
                <DisplayAttachmentButton
                  attachment={attachment}
                  data={data}
                  workspaceData={workspaceData}
                />
              ) : (
                <StyledButton
                  sx={{ height: '48px' }}
                  variant='outlined'
                  startIcon={<AttachmentIcon />}
                  disabled={
                    data?.administrativeState !== 'deployed' || !allowed
                  }
                  onClick={openAttachmentConfiguration}>
                  Attach element
                </StyledButton>
              )}
            </div>
          </div>
        </Fragment>
      </div>
    </>
  )
}
