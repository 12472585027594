// IMPORT DEPENDENCIES
import { IconButton, InputLabel } from '@mui/material'
import type { ChangeEventHandler, FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useMatch, useNavigate, useParams } from 'react-router-dom'

// IMPORT STYLES
import { useTranslation } from 'react-i18next'
import useResizeObserver from 'use-resize-observer'
import CloseIcon from '../../assets/icons-io-v1/close.svg?react'
import { displayElementType } from '../../hooks/displayElementType'
import { DisplayElementIcon } from '../../hooks/displayIcons'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import { useSnackbar } from '../../hooks/useSnackbar'
import { ResourceType } from '../../slices/authorizationsApiSlice'
import { useGetElementOperationalStatusMetricsQuery } from '../../slices/MetricsApiSlice'
import {
  useGetElementByIdV2Query,
  useUpdateNodeInWorkspaceV2Mutation,
  useUpdateTransportInWorkspaceV2Mutation,
} from '../../slices/workspacesApiSliceV2'
import OperationalStatus from '../chips/operationalStatus'
import {
  StyledActionButton,
  StyledTextField,
} from '../forms/StyledFormComponents'
import Visible from '../permissions/Visible'
import { StyledDrawer } from './StyledDrawer'

// TYPES
type DrawerEditProps = {}

export type EditiontionState = {
  name: string
}

const adaptElementKind = (type: string) => {
  switch (type) {
    case 'cloud':
      return 'nodes'
    case 'access':
      return 'nodes'
    case 'transport':
      return 'transports'
    default:
      break
  }
}

const DrawerEdit: FC<DrawerEditProps> = () => {
  const { showError, showSuccess } = useSnackbar()
  const { workspaceId, elementId, type: elementType } = useParams()
  const navigate = useNavigate()
  const { currentAccount } = useCurrentAccount()
  const { t } = useTranslation()
  const [updateCloudNode] = useUpdateNodeInWorkspaceV2Mutation()
  const [updateTransport] = useUpdateTransportInWorkspaceV2Mutation()
  const [newElementData, setNewElementData] = useState<EditiontionState>({
    name: '',
  })

  const matchesViewKind = useMatch('/workspaces/:workspaceId/view/:viewKind/*')

  const toggleDrawer = useCallback(() => {
    if (matchesViewKind) navigate(matchesViewKind.pathnameBase)
  }, [navigate, matchesViewKind])

  const discardDrawer = useCallback(() => {
    toggleDrawer()
  }, [toggleDrawer])

  // FETCH THE CURRENT WORKSPACE DATA
  const { data: elementData, error } = useGetElementByIdV2Query(
    {
      accountId: currentAccount?.id,
      workspaceId: workspaceId,
      elementType: adaptElementKind(elementType),
      elementId: elementId,
    },
    {
      skip: !currentAccount?.id,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (!error) return
    showError(error)
    toggleDrawer()
  }, [showError, error, toggleDrawer])

  useEffect(() => {
    if (elementData?.data && 'name' in elementData?.data) {
      const { name } = elementData?.data
      setNewElementData((previous) => ({
        ...previous,
        name,
      }))
    }
  }, [elementData])

  const onChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const name = e.target.value
      setNewElementData((previous) => ({
        ...previous,
        name,
      }))
    },
    []
  )

  const dataKind =
    elementData?.data?.kind === 'transport' ||
    elementData?.data?.kind === 'node'
      ? elementData?.data?.kind
      : null
  const dataType =
    elementData && 'type' in elementData?.data ? elementData?.data?.type : ''

  const { data: operationalStatusData } =
    useGetElementOperationalStatusMetricsQuery(
      {
        accountId: currentAccount?.id,
        elementType: dataKind,
        id: elementId,
        workspaceId: workspaceId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip:
          !elementData || elementData.data.administrativeState !== 'deployed',
      }
    )

  const updateElement = useCallback(async () => {
    try {
      switch (adaptElementKind(elementType)) {
        case 'nodes':
          await updateCloudNode({
            accountId: currentAccount?.id,
            workspaceId,
            node: newElementData,
            nodeId: elementId,
          }).unwrap()

          break
        case 'transports':
          await updateTransport({
            accountId: currentAccount?.id,
            workspaceId,
            transport: newElementData,
            transportId: elementId,
          }).unwrap()
          break
        default:
          break
      }
      toggleDrawer()
      showSuccess(
        t('element.messages.ELEMENT_UPDATED_WITH_SUCCESS', {
          element: displayElementType(dataKind, elementType, t),
        })
      )
    } catch (e) {
      showError(e)
    }
  }, [
    elementType,
    toggleDrawer,
    t,
    dataKind,
    updateCloudNode,
    currentAccount?.id,
    workspaceId,
    newElementData,
    elementId,
    updateTransport,
    showError,
    showSuccess,
  ])

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  const dataCspName =
    elementData &&
    'product' in elementData?.data &&
    'cspName' in elementData?.data?.product
      ? elementData?.data?.product.cspName
      : null
  const dataName =
    elementData && 'name' in elementData?.data ? elementData?.data?.name : ''

  if (!elementData) return
  return (
    <div>
      <StyledDrawer anchor='right' open onClose={toggleDrawer}>
        <div className='drawer-create__wrapper' ref={drawerWidth}>
          <div className='show__element__container-close'>
            <IconButton
              onClick={toggleDrawer}
              sx={{ padding: '0', height: '24px' }}>
              <CloseIcon fontSize='small' style={{ width: '22px' }} />
            </IconButton>
          </div>
          <div>
            <div className='drawer-create__header'>Edit Element</div>
          </div>

          <div className='show__element--header'>
            <div className='show__element--header-icon'>
              {elementType === 'port' ? (
                <DisplayElementIcon kind={'port'} />
              ) : (
                <DisplayElementIcon
                  kind={dataKind}
                  type={dataType}
                  csp={dataCspName}
                />
              )}
            </div>

            <div className='show__element--header-infos'>
              <div className='show__element--header-infos-title'>
                <div className='show__element--header-infos-title-type'>
                  {elementType === 'port'
                    ? displayElementType('port', 'PHYSICAL', t)
                    : displayElementType(elementData?.data?.kind, dataType, t)}
                </div>

                <div className='show__element--header-infos-title-name'>
                  {dataName}
                </div>
              </div>

              <div className='show__element--header-infos-state'>
                <OperationalStatus
                  status={operationalStatusData?.status}
                  showStatusText={true}
                />
              </div>
            </div>
          </div>

          <div className='drawer-create__containers'>
            <div className='drawer-create__columns-input'>
              <InputLabel
                htmlFor='element-form-name'
                className='drawer__forms--labels'>
                {t('element.ELEMENT_NAME')}
              </InputLabel>
              <StyledTextField
                id='element-form-name'
                variant='outlined'
                size='small'
                defaultValue={dataName}
                slotProps={{
                  htmlInput: {
                    form: {
                      autocomplete: 'off',
                    },
                  },
                }}
                onChange={onChangeName}
              />
            </div>
          </div>

          <div className='element__form--btn' style={{ width }}>
            <Visible
              resource={ResourceType.Workspace}
              resourceId={workspaceId}
              action='edit_node'>
              <StyledActionButton
                sx={{
                  width: '49.5%',
                  backgroundColor: 'var(--background-tertiary)',
                  '&:hover': {
                    backgroundColor: 'var(--background-secondary)',
                  },
                }}
                color='primary'
                variant='text'
                onClick={() => discardDrawer()}>
                {t('element.DISCARD')}
              </StyledActionButton>
              <StyledActionButton
                sx={{ width: '49.5%', marginLeft: '1%' }}
                color='primary'
                variant='contained'
                onClick={updateElement}>
                {t('element.SAVE')}
              </StyledActionButton>
            </Visible>
          </div>
        </div>
      </StyledDrawer>
    </div>
  )
}
export default DrawerEdit
