// IMPORT DEPENDENCIES
import { Fragment, useCallback, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

// IMPORT ASSETS
import ConfigureElement from './drawerCreateElement/ConfigureElement'
import ConfigureProduct from './drawerCreateElement/ConfigureProduct'
import SelectElement from './drawerCreateElement/SelectElement'

// IMPORT STYLES
import { StyledDrawer } from './StyledDrawer'
import './styles.scss'

// TYPES
import type { AvailableProduct, PortElementV2 } from '../../slices/types'
import ConfigureAccessPort from './drawerCreateElement/ConfigureAccessPort'
import ConfigureAccessTechnicalInfo from './drawerCreateElement/ConfigureAccessTechnicalInfo'
import ConfigureCloudTechnicalInfo from './drawerCreateElement/ConfigureCloudTechnicalInfo'

export type CreationState = {
  creationStep: number
  elementType: string
  elementName: string
  cspName?: string
  elementProduct: AvailableProduct | null
  elementTechInfo: string
  accessPort: PortElementV2['data'] | null
  provider?: string
  location?: string
  locationTo?: string
  bandwidth?: string
  cspRegion?: string
  cspCity?: string
  vlan?: number
  duration?: number
  portType?: ''
  type?: ''
}

export default function DrawerCreate() {
  const [newElementData, setNewElementData] = useState<CreationState>({
    creationStep: 0,
    elementType: '',
    elementName: '',
    elementProduct: null,
    elementTechInfo: '',
    accessPort: null,
    cspName: '',
    cspCity: '',
    cspRegion: '',
    bandwidth: '',
    provider: '',
    location: '',
    locationTo: '',
    vlan: null,
    type: '',
  })
  const [creationStep, setCreationStep] = useState<number>(0)
  const navigate = useNavigate()
  const matchesViewKind = useMatch(
    '/workspaces/:workspaceId/:tabValue/:viewKind/*'
  )

  const toggleDrawer = useCallback(() => {
    navigate(matchesViewKind?.pathnameBase)
  }, [matchesViewKind, navigate])

  const displayStep = useCallback(
    (creationStep: number) => {
      switch (creationStep) {
        case 0:
          return (
            <SelectElement
              setCreationStep={setCreationStep}
              setNewElementData={setNewElementData}
              toggleDrawer={toggleDrawer}
            />
          )
        case 1:
          return (
            <ConfigureElement
              setCreationStep={setCreationStep}
              newElementData={newElementData}
              setNewElementData={setNewElementData}
              toggleDrawer={toggleDrawer}
            />
          )

        case 2:
          return (
            <ConfigureProduct
              setCreationStep={setCreationStep}
              newElementData={newElementData}
              setNewElementData={setNewElementData}
              toggleDrawer={toggleDrawer}
            />
          )

        case 3:
          return (
            <ConfigureCloudTechnicalInfo
              setCreationStep={setCreationStep}
              newElementData={newElementData}
              setNewElementData={setNewElementData}
              toggleDrawer={toggleDrawer}
            />
          )

        case 4:
          return (
            <ConfigureAccessPort
              setCreationStep={setCreationStep}
              newElementData={newElementData}
              setNewElementData={setNewElementData}
              toggleDrawer={toggleDrawer}
            />
          )

        case 5:
          return (
            <ConfigureAccessTechnicalInfo
              setCreationStep={setCreationStep}
              newElementData={newElementData}
              setNewElementData={setNewElementData}
              toggleDrawer={toggleDrawer}
            />
          )
      }
    },
    [newElementData, toggleDrawer]
  )

  return (
    <div>
      <Fragment key='right'>
        <StyledDrawer anchor='right' open onClose={toggleDrawer}>
          {displayStep(creationStep)}
        </StyledDrawer>
      </Fragment>
    </div>
  )
}
