// IMPORT DEPENDENCIES
import { MenuItem } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useMeiliSearch, { ProductCatalog } from '../../../hooks/useMeiliSearch'

// ASSETS
import {
  displayCspWithLogo,
  displayProviderLogo,
} from '../../../hooks/displayIcons'

// ICONS
import SortIcon from '../../../assets/icons-io-v1/arrow_swap_vertical.svg?react'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'

// TYPES
import type { SearchParams } from 'meilisearch/dist/types/types'
import { useNavigate } from 'react-router-dom'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import type { AvailableProduct, CloudQueryParams } from '../../../slices/types'
import SelectedFilter from '../../fields/SelectedFilter'
import {
  StyledActionButton,
  StyledTextField,
  selectProps,
} from '../../forms/StyledFormComponents'
import { StyledIconButton } from '../../forms/SyledIconButtons'
import type { CreationState } from '../DrawerCreateElementV2'

type ConfigureCloudProductProps = {
  newElementData: CreationState
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  setCreationStep: Dispatch<SetStateAction<number>>
}

interface formInput {
  name: string
  cspName: string
  provider: string
  location: string
  bandwidth: string
  cspCity: string
  cspRegion: string
  cspLocation: string
  accountId: string
  pairingKey: string
  serviceKey: string
}

interface ProductCardProps {
  result: AvailableProduct
  activeRadio: number
  setActiveRadio: Dispatch<SetStateAction<number>>
}

const ProductCard: React.FC<ProductCardProps> = ({
  result,
  activeRadio,
  setActiveRadio,
}) => {
  const handleSetActiveRadio = useCallback(() => {
    setActiveRadio(result.id)
  }, [setActiveRadio, result.id])

  return (
    <div
      key={result.id}
      className='drawer-products__card'
      onClick={handleSetActiveRadio}>
      <div className='drawer-products__card--radio'>
        <input
          type='radio'
          value={result.id}
          checked={result.id === activeRadio}
          onChange={handleSetActiveRadio} // Add onChange handler here
        />
      </div>
      <div className='drawer-products__card--col'>
        <div className='drawer-products__card--provider'>
          {displayProviderLogo(result?.provider)}
        </div>
      </div>
      <div className='drawer-products__card--col'>{result?.location}</div>
      <div className='drawer-products__card--col'>
        {humanizeBandwidth(result?.bandwidth)}
      </div>
      <div className='drawer-products__card--col'>{result?.cspCity}</div>
      <div className='drawer-products__card--col'>{result?.cspRegion}</div>
      <div className='drawer-products__card--col'>{result?.priceMrc} €</div>
    </div>
  )
}

export default function ConfigureCloudProduct({
  newElementData,
  setNewElementData,
  setCreationStep,
}: ConfigureCloudProductProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeRadio, setActiveRadio] = useState<number>(null)
  const { register, reset, control } = useForm<formInput>({
    shouldUseNativeValidation: true,
  })

  const [cloudQueryParams, setCloudQueryParams] = useState<CloudQueryParams>({})
  // MEILI SEARCH CONFIG
  const searchQuery: string = ''
  const [facetSearch, setFacetSearch] = useState<SearchParams>({
    filter: [],
    facets: [
      'cspName',
      'cspRegion',
      'cspCity',
      'location',
      'bandwidth',
      'provider',
    ],
  })

  const {
    data: { hits: searchResults, facetDistribution: facetDistri },
  } = useMeiliSearch(searchQuery, facetSearch, ProductCatalog.Cloud)

  useEffect(() => {
    const filter = Object?.entries(cloudQueryParams ?? {})
      .map(([key, val]) => {
        return `${key} = "${val}"`
      })
      .join(' AND ')

    setFacetSearch((prevFacetSearch) => ({
      ...prevFacetSearch,
      filter,
    }))
  }, [cloudQueryParams])

  const handleChange = useCallback(
    (name: string, value: string) => {
      setNewElementData((previous) => ({ ...previous, [name]: value }))
      setCloudQueryParams((previous) => ({
        ...previous,
        [name]: value,
      }))
    },
    [setNewElementData]
  )

  const handleClearFacet = useCallback(
    (facet: string) => {
      if (facet === 'cspName') {
        setNewElementData((previous) => ({
          ...previous,
          cspName: '',
          cspCity: '',
          cspRegion: '',
          bandwidth: '',
          provider: '',
          location: '',
        }))
        setCloudQueryParams({})
      } else {
        setNewElementData((previous) => ({ ...previous, [facet]: '' }))
        setCloudQueryParams((previous) => {
          const { [facet]: _, ...cloudQueryParamsBis } = previous
          return {
            ...cloudQueryParamsBis,
          }
        })
        reset({ [facet]: '' })
      }
    },
    [reset, setNewElementData]
  )

  // ----------------------------------------------
  // ----- LOGIQUE TO SORT THE DATA BY COLUMN -----
  const [mySortedData, setMySortedData] = useState(searchResults)
  const [mySortedFilter, setMySortedFilter] = useState('')
  const [sortAscDesc, setSortAscDesc] = useState(true)
  useEffect(() => {
    setMySortedData(searchResults)
  }, [searchResults])

  const sortMyData = (sortBy: string) => {
    setMySortedFilter(sortBy)
    setSortAscDesc(!sortAscDesc)
    if (sortBy === 'priceMrc' || sortBy === 'bandwidth') {
      if (sortAscDesc) {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => b[sortBy] - a[sortBy])
        )
      } else {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => a[sortBy] - b[sortBy])
        )
      }
    } else {
      if (sortAscDesc) {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
        )
      } else {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => b[sortBy].localeCompare(a[sortBy]))
        )
      }
    }
  }

  // --------------------------------------------------------------
  // ----- HANDLE THE ADDITION OF THE PRODUCT AND REDIRECTION -----
  const handleAddProduct = useCallback(() => {
    const product = mySortedData.find((data) => data.id === activeRadio)
    setNewElementData((previous) => ({
      ...previous,
      elementProduct: product,
      cspName: '',
      cspCity: '',
      cspRegion: '',
      bandwidth: '',
      provider: '',
      location: '',
      elementTechInfo: '',
    }))
    setCreationStep(1)
    navigate(-1)
  }, [activeRadio, mySortedData, navigate, setCreationStep, setNewElementData])

  return (
    <>
      {/* ----- CSP ----- */}
      <div className='drawer-create__containers'>
        {newElementData?.cspName === '' ? (
          <>
            <div className='drawer-create__titles'>
              {t('element.CSP_NAME')} :{' '}
            </div>
            <div className='drawer-create__columns-cspName'>
              {facetDistri?.cspName !== undefined ? (
                <Controller
                  name='cspName'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      select
                      size='small'
                      slotProps={{
                        select: selectProps,
                      }}
                      {...register('cspName')}>
                      {facetDistri?.cspName !== undefined ? (
                        Object.keys(facetDistri?.cspName).map((cspName) => {
                          return (
                            <MenuItem
                              key={cspName}
                              value={cspName}
                              onClick={() => handleChange('cspName', cspName)}>
                              {displayCspWithLogo(cspName)}
                            </MenuItem>
                          )
                        })
                      ) : (
                        <div />
                      )}
                    </StyledTextField>
                  )}
                />
              ) : (
                <div> No Data Available</div>
              )}

              {newElementData?.cspName && (
                <StyledIconButton
                  onClick={() => handleClearFacet('cspName')}
                  sx={{
                    margin: '0 14px',
                  }}
                  disabled={newElementData?.cspName === '' ? true : false}>
                  <CloseIcon />
                </StyledIconButton>
              )}
            </div>
          </>
        ) : (
          <SelectedFilter
            newElementData={newElementData}
            handleClearFacet={handleClearFacet}
            legend={t('element.CSP_NAME')}
          />
        )}
      </div>

      {/* ----- FILTER ----- */}
      {newElementData?.cspName && (
        <>
          <div className='drawer-create__containers'>
            <>
              <div className='drawer-create__titles'>
                <span>{t('element.FILTERS')} :</span>
              </div>
              <div className='drawer-create__columns-filters'>
                {newElementData?.provider === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='provider'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.PROVIDER')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('provider')}>
                          {facetDistri?.provider !== undefined ? (
                            Object.keys(facetDistri?.provider).map(
                              (provider) => {
                                return (
                                  <MenuItem
                                    key={provider}
                                    value={provider}
                                    onClick={() =>
                                      handleChange('provider', provider)
                                    }>
                                    {provider}
                                  </MenuItem>
                                )
                              }
                            )
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.PROVIDER')}
                  />
                )}
                {newElementData?.location === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='location'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.CSP_LOCATION')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('location')}>
                          {facetDistri?.location !== undefined ? (
                            Object.keys(facetDistri?.location).map(
                              (location) => {
                                return (
                                  <MenuItem
                                    key={location}
                                    value={location}
                                    onClick={() =>
                                      handleChange('location', location)
                                    }>
                                    {location}
                                  </MenuItem>
                                )
                              }
                            )
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.LOCATION')}
                  />
                )}
                {newElementData?.bandwidth === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='bandwidth'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.BANDWIDTH')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('bandwidth')}>
                          {facetDistri?.bandwidth !== undefined ? (
                            Object.keys(facetDistri?.bandwidth).map(
                              (bandwidth) => {
                                return (
                                  <MenuItem
                                    key={bandwidth}
                                    value={bandwidth}
                                    onClick={() =>
                                      handleChange('bandwidth', bandwidth)
                                    }>
                                    {bandwidth}
                                  </MenuItem>
                                )
                              }
                            )
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.BANDWIDTH')}
                  />
                )}

                {newElementData?.cspRegion === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='cspRegion'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.CSP_REGION')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('cspRegion')}>
                          {facetDistri?.cspRegion !== undefined ? (
                            Object.keys(facetDistri?.cspRegion).map(
                              (cspRegion) => {
                                return (
                                  <MenuItem
                                    key={cspRegion}
                                    value={cspRegion}
                                    onClick={() =>
                                      handleChange('cspRegion', cspRegion)
                                    }>
                                    {cspRegion}
                                  </MenuItem>
                                )
                              }
                            )
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.CSP_REGION')}
                  />
                )}

                {newElementData?.cspCity === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='cspCity'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.CSP_CITY')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('cspCity')}>
                          {facetDistri?.cspCity !== undefined ? (
                            Object.keys(facetDistri?.cspCity).map((cspCity) => {
                              return (
                                <MenuItem
                                  key={cspCity}
                                  value={cspCity}
                                  onClick={() =>
                                    handleChange('cspCity', cspCity)
                                  }>
                                  {cspCity}
                                </MenuItem>
                              )
                            })
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.CSP_CITY')}
                  />
                )}
              </div>
            </>
          </div>
        </>
      )}

      {/* ----- FILTERED RESULTS ----- */}
      <div
        className={`drawer-products__containers ${
          Object.keys(cloudQueryParams).length >= 2
            ? 'drawer-products__containers--border-bottom'
            : ''
        }`}>
        {Object?.keys(cloudQueryParams).length >= 2 ? (
          <>
            <div className='drawer-products__card header'>
              <div className='drawer-products__card--radio' />
              <div className='drawer-products__card--header'>
                {t('element.PROVIDER')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'provider'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('provider')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.LOCATION')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'location'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('location')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.BANDWIDTH')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'bandwidth'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('bandwidth')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.CSP_CITY')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'cspCity'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('cspCity')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.CSP_REGION')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'cspRegion'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('cspRegion')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.MRC')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'priceMrc'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('priceMrc')}
                />
              </div>
            </div>
            {mySortedData.map((result) => {
              return (
                <ProductCard
                  key={result.id}
                  result={result}
                  activeRadio={activeRadio}
                  setActiveRadio={setActiveRadio}
                />
              )
            })}
          </>
        ) : (
          <div className='drawer-products__filters-message'>
            {newElementData?.cspName === ''
              ? 'Please select your CSP.'
              : 'Please select another filter.'}{' '}
          </div>
        )}

        {Object?.keys(cloudQueryParams).length >= 2 && (
          <div className='drawer-products__footer'>
            <div> {/* Placeholder for infinite scrool*/}</div>
            <div>
              <StyledActionButton
                variant='contained'
                disabled={!activeRadio}
                onClick={handleAddProduct}>
                Add
              </StyledActionButton>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
