import { CalendarTodayOutlined } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
} from '@mui/material'

import { formatDate } from 'date-fns'
import { useCallback, useState } from 'react'
import DatePicker from 'react-datepicker'
import {
  useController,
  type FieldPath,
  type UseControllerProps,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import './DatePickerInput.scss'
import { StyledActionButton } from './StyledFormComponents'

interface DatePickerInputProps {
  id?: string
  format?: string
  minDate?: Date
  maxDate?: Date
}

type FieldValues = {
  [x: string]: string
}

const DatePickerInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  id,
  format = 'dd/MM/yyyy',
  minDate,
  maxDate,
  ...controllerProps
}: UseControllerProps<TFieldValues, TName> & DatePickerInputProps) => {
  const { field } = useController(controllerProps)
  const { t } = useTranslation()
  const [date, setDate] = useState<FieldValues[TName]>(null)

  const [open, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onChange = useCallback(
    (date: Date) => {
      setDate(formatDate(date, format))
    },
    [format]
  )

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const onConfirm = useCallback(() => {
    field.onChange(date)
    setOpen(false)
  }, [date, field])

  return (
    <Box id={id}>
      <IconButton
        sx={{
          border: '1px solid var(--border-primary)',
          background: 'var(--background-secondary)',
          color: 'var(--content-primary) !important',
          padding: '1rem',
          minHeight: '40px',
          fontSize: 12,
          fontWeight: 450,
        }}
        onClick={onOpen}>
        <CalendarTodayOutlined />
        {field.value || t('settings.tokens.CREATE_DATE_LABEL')}
      </IconButton>
      <Dialog
        open={open}
        className='date-picker-input-dialog'
        onClose={onCancel}>
        <DialogContent>
          <DatePicker
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            inline
            showDisabledMonthNavigation
            shouldCloseOnSelect={false}
            calendarClassName='datepicker-custom'
            dayClassName={() => 'datepicker-day-custom'}
            weekDayClassName={() => 'datepicker-weekday-custom'}
          />
          <Divider sx={{ borderColor: 'var(--border-dialog)' }} />
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 24px 24px 24px',
          }}>
          <StyledActionButton
            sx={{
              width: 98,
              color: 'var(--content-tertiary, #A6AEBF)',
              background: 'var(--background-inactive-accent, #EBEEF5)',
            }}
            color='primary'
            variant='text'
            onClick={onCancel}>
            {t('modal.CANCEL')}
          </StyledActionButton>
          <StyledActionButton
            style={{ width: 98, color: '#fff' }}
            type='submit'
            variant='contained'
            onClick={onConfirm}>
            {t('modal.CONFIRM')}
          </StyledActionButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default DatePickerInput
