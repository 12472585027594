// IMPORT DEPENDENCIES
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material'
import { t } from 'i18next'
import type { FC } from 'react'
import { Fragment, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// IMPORT COMPONENTS
import { ResourceType } from '../../slices/authorizationsApiSlice'
import type { ConcatElementV2, WorkspaceV2 } from '../../slices/types'
import PortsSummaryV2 from '../../views/Ports/Accordions/PortsSummaryV2'
import AccessDetails from '../../views/Workspace/Accordions/AccessDetails'
import AccessSummary from '../../views/Workspace/Accordions/AccessSummary'
import CloudDetails from '../../views/Workspace/Accordions/CloudDetails'
import CloudSummary from '../../views/Workspace/Accordions/CloudSummary'
import TransportDetails from '../../views/Workspace/Accordions/TransportDetails'
import TransportSummary from '../../views/Workspace/Accordions/TransportSummary'
import { StyledIdCardIconButton } from '../forms/SyledIconButtons'
import Visible from '../permissions/Visible'
import './styles.scss'

// IMPORT ICONS
import TrashIcon from '../../assets/icons-io-v1/delete.svg?react'
import EditIcon from '../../assets/icons-io-v1/edit.svg?react'
import ShowIcon from '../../assets/icons-io-v1/show.svg?react'
import useCurrentAccount from '../../hooks/useCurrentAccount'
import {
  disableActionsForErrorStates,
  disableAdministrativeState,
} from '../../utils/states'
import PortsDetailsV2 from '../../views/Ports/Accordions/PortsDetailsV2'

// STYLING -> ACCORDION
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  slotProps: { heading: { component: 'h4' } },
  borderStyle: 'none',
  '&.MuiPaper-root': {
    border: '1px solid var(--border-primary)',
    boxShadow: 'none',
    marginBottom: '16px',
    backgroundColor: 'var(--background-primary)',
    lineHeight: '1.5',
  },
  '&.MuiPaper-root:before': {
    height: 0,
  },
  '&.MuiButtonBase-root': {
    padding: '0',
  },
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: '0',
    paddingLeft: '15px',
  },
}))

const adaptElementType = (
  type: string,
  kind: 'transport' | 'port' | 'node' | 'attachment'
): 'transport' | 'port' | 'attachments' | typeof type => {
  switch (kind) {
    case 'transport':
      return kind
    case 'port':
      return kind
    case 'node':
      return type
    case 'attachment':
      return 'attachments'
    default:
      return 'port'
  }
}

type AccordionElementsV2Props = {
  element?: ConcatElementV2
  workspaceData?: WorkspaceV2
}

const SwitchAccordionSummary = ({
  element,
}: {
  element: AccordionElementsV2Props['element']
}) => {
  switch (element?.kind) {
    case 'transport':
      return <TransportSummary data={element} />
    case 'port':
      return <PortsSummaryV2 data={element} />
    case 'node':
      switch (element?.type) {
        case 'access':
          return <AccessSummary data={element} />
        case 'cloud':
          return <CloudSummary data={element} />
      }
      break
    default:
      return null
  }
}

const SwitchAccordionDetails = ({
  element,
  workspaceData,
}: {
  element: AccordionElementsV2Props['element']
  workspaceData: AccordionElementsV2Props['workspaceData']
}) => {
  switch (element?.kind) {
    case 'transport':
      return <TransportDetails data={element} workspaceData={workspaceData} />
    case 'port':
      return <PortsDetailsV2 data={element} />
    case 'node':
      switch (element?.type) {
        case 'access':
          return <AccessDetails data={element} workspaceData={workspaceData} />
        case 'cloud':
          return <CloudDetails data={element} workspaceData={workspaceData} />
      }
      break
    default:
      return null
  }
}

export const AccordionElementsV2: FC<AccordionElementsV2Props> = ({
  element,
  workspaceData,
}) => {
  const navigate = useNavigate()
  const { currentAccount } = useCurrentAccount()

  const disableActionsForAttachedElement = useMemo(() => {
    return workspaceData?.data?.attachments?.some((el) => {
      switch (element?.kind) {
        case 'node':
          return el?.nodeId === element?.id
        case 'transport':
          return el?.transportId === element?.id
        default:
          return false
      }
    })
  }, [element?.id, element?.kind, workspaceData?.data?.attachments])

  const elementDataType = 'type' in element ? element.type : ''
  const elementDataKind = element?.kind

  const openDetailsElement = useCallback(() => {
    const type = adaptElementType(elementDataType, elementDataKind)
    navigate(`details/${type}/${element?.id}/properties`)
  }, [element?.id, elementDataKind, elementDataType, navigate])

  const openDeleteConfirm = useCallback(() => {
    const type = adaptElementType(elementDataType, elementDataKind)
    navigate(`delete/${type}/${element?.id}`)
  }, [element?.id, elementDataKind, elementDataType, navigate])

  const openEdit = useCallback(() => {
    const type = adaptElementType(elementDataType, elementDataKind)
    navigate(`edit/${type}/${element?.id}`)
  }, [element?.id, elementDataKind, elementDataType, navigate])

  if (!element || element?.kind === 'attachment') {
    return null
  }

  return (
    <Fragment>
      {element && (
        <StyledAccordion
          key={element?.id}
          disableGutters={true}
          defaultExpanded={false}>
          <div className='accordion__container-summary-wrapper'>
            <StyledAccordionSummary className='accordion__container-summary'>
              <SwitchAccordionSummary element={element} />
            </StyledAccordionSummary>

            {/* ----- // MRC PRICE ----- */}
            <div className='accordion__element-details-price'>
              <div className='accordion__element-details--name'>
                {t('element.MRC')}
              </div>
              <div className='accordion__element-details--type'>
                {element?.product?.priceMrc}€
              </div>
            </div>

            {/* ----- // @FIXME: Wrong permissions ----- */}
            <Visible
              resource={
                element?.kind === 'port'
                  ? ResourceType.Account
                  : ResourceType.Workspace
              }
              resourceId={
                element?.kind === 'port'
                  ? currentAccount.id
                  : workspaceData?.data.id
              }
              action={
                element?.kind === 'node'
                  ? 'get_node'
                  : element?.kind === 'transport'
                    ? 'get_transport'
                    : element?.kind === 'port'
                      ? 'get_port'
                      : '*'
              }>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={disableAdministrativeState(
                    element?.administrativeState
                  )}
                  onClick={openDetailsElement}>
                  <ShowIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>

            {/* ----- // @FIXME: Wrong permissions ----- */}
            {(element?.kind === 'node' || element?.kind === 'transport') && (
              <Visible
                resource={ResourceType.Workspace}
                resourceId={workspaceData?.data.id}
                action={
                  element?.kind === 'node'
                    ? 'edit_node'
                    : element?.kind === 'transport'
                      ? 'edit_transport'
                      : '*'
                }>
                <div className='accordion__container-trash-icon'>
                  <StyledIdCardIconButton
                    disabled={
                      disableAdministrativeState(
                        element?.administrativeState
                      ) ||
                      disableActionsForErrorStates(element?.administrativeState)
                    }
                    onClick={openEdit}>
                    <EditIcon />
                  </StyledIdCardIconButton>
                </div>
              </Visible>
            )}

            {/* ///// DELETE ///// */}
            <Visible
              resource={
                element?.kind === 'port'
                  ? ResourceType.Account
                  : ResourceType.Workspace
              }
              resourceId={
                element?.kind === 'port'
                  ? currentAccount.id
                  : workspaceData?.data.id
              }
              action={
                element?.kind === 'node'
                  ? 'delete_node'
                  : element?.kind === 'transport'
                    ? 'delete_transport'
                    : element?.kind === 'port'
                      ? 'remove_port'
                      : '*'
              }>
              <div className='accordion__container-trash-icon'>
                <StyledIdCardIconButton
                  disabled={
                    disableAdministrativeState(element?.administrativeState) ||
                    disableActionsForAttachedElement
                  }
                  onClick={openDeleteConfirm}>
                  <TrashIcon />
                </StyledIdCardIconButton>
              </div>
            </Visible>
          </div>

          <AccordionDetails>
            <SwitchAccordionDetails
              element={element}
              workspaceData={workspaceData}
            />
          </AccordionDetails>
        </StyledAccordion>
      )}
    </Fragment>
  )
}

export default AccordionElementsV2
