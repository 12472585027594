// IMPORT DEPENDENCIES
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// ASSETS

// ICONS
import SortIcon from '../../../assets/icons-io-v1/arrow_swap_vertical.svg?react'
import ChevronLeftIcon from '../../../assets/icons-io-v1/chevron_left_2.svg?react'

// TYPES
import { useNavigate } from 'react-router-dom'
import { DisplayElementIcon } from '../../../hooks/displayIcons'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useGetPortsbyFiltersQuery } from '../../../slices/portsApiSlice'
import type { PortElement, PortsV2 } from '../../../slices/types'
import { PortStates } from '../../../slices/types'
import type { CreationState } from '../DrawerCreateElementV2'

type ConfigurAccessPortProps = {
  newElementData: CreationState
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  setCreationStep: Dispatch<SetStateAction<number>>
  toggleDrawer: () => void
}

function filterByLocation(products, locationName) {
  return products?.filter((product) => {
    return (
      product?.product &&
      product?.product?.location.toLowerCase() === locationName?.toLowerCase()
    )
  })
}

export default function ConfigureAccessPort({
  newElementData,
  setNewElementData,
  setCreationStep,
  toggleDrawer,
}: ConfigurAccessPortProps) {
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const navigate = useNavigate()

  // --------------------------------------------------------------
  // ----- HANDLE THE ADDITION OF THE PRODUCT AND REDIRECTION -----
  const handleAddPort = useCallback(
    (product) => {
      setNewElementData((previous) => ({
        ...previous,
        accessPort: product,
        vlan: null,
      }))
      setCreationStep(1)
      navigate(-1)
    },
    [navigate, setCreationStep, setNewElementData]
  )

  const handClickBack = useCallback(
    (creationStep: number) => {
      setNewElementData((previous) => ({
        ...previous,
        vlan: null,
      }))
      setCreationStep(creationStep)
      navigate(-1)
    },
    [navigate, setCreationStep, setNewElementData]
  )

  // -----------------------------
  // ----- IMPORT PORTS LIST -----
  // first get the ports and filter by location,
  // store the ports in a temporary state
  // pass the value to sorted data to sort git the sort function the data
  const [filteredPortsData, setFilteredPortsData] = useState<PortsV2['data']>(
    []
  )
  const { data: unFilteredPortsData } = useGetPortsbyFiltersQuery(
    { accountId: currentAccount?.id, query: { state: '' } },
    {
      skip: !currentAccount?.id,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    setFilteredPortsData(
      filterByLocation(
        unFilteredPortsData?.data,
        newElementData?.elementProduct?.location
      )
    )
  }, [newElementData?.elementProduct?.location, unFilteredPortsData?.data])

  // ----- END IMPORT PORTS LIST -----
  // ---------------------------------

  // ----------------------------------------------
  // ----- LOGIQUE TO SORT THE DATA BY COLUMN -----
  const [mySortedData, setMySortedData] = useState<PortsV2['data']>([])
  const [mySortedFilter, setMySortedFilter] = useState('')
  const [sortAscDesc, setSortAscDesc] = useState(true)
  useEffect(() => {
    setMySortedData(filteredPortsData)
  }, [filteredPortsData])

  const sortMyData = useCallback((sortBy: string) => {
    setMySortedFilter(sortBy)
    setSortAscDesc((previous) => !previous)
  }, [])

  useEffect(() => {
    if (sortAscDesc) {
      setMySortedData(
        (previous) =>
          previous &&
          [...previous].sort((a, b) =>
            a[mySortedFilter].localeCompare(b[mySortedFilter])
          )
      )
    } else {
      setMySortedData(
        (previous) =>
          previous &&
          [...previous].sort((a, b) =>
            b[mySortedFilter].localeCompare(a[mySortedFilter])
          )
      )
    }
  }, [sortAscDesc, mySortedFilter])

  // ----- END LOGIQUE TO SORT THE DATA BY COLUMN -----
  // --------------------------------------------------

  if (mySortedData === undefined) return
  return (
    <>
      <div className='drawer-create__wrapper'>
        <div>
          <div className='drawer-create__header'>
            <div
              className='drawer-create__header--link'
              onClick={() => handClickBack(1)}>
              <ChevronLeftIcon />
            </div>
            {/* // TOTO - ADD LOCALS*/}
            {t('element.SELECT_PORT')}
          </div>
        </div>
        {/* ----- FILTERED RESULTS ----- */}
        <div className='drawer-products__port-location'>
          Location: {newElementData?.elementProduct?.location}
        </div>
        <div className='drawer-products__containers'>
          <>
            <div className='drawer-products__card header'>
              <div className='drawer-products__card--header'>
                {t('element.PROVIDER')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'provider'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('provider')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.PORT_NAME')}
                <SortIcon
                  style={
                    mySortedFilter === 'name'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('name')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.BANDWIDTH')}
                <SortIcon
                  style={
                    mySortedFilter === 'bandwidth'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('bandwidth')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.AVAILABLE_CAPACITY')}
              </div>
            </div>
            {Object.keys(mySortedData).length > 0 ? (
              mySortedData.map((result: PortElement) => {
                return (
                  result?.administrativeState !== PortStates.deleted && (
                    <div
                      key={result.id}
                      className='drawer-products__card'
                      style={{ justifyContent: 'start' }}
                      onClick={() => handleAddPort(result)}>
                      <div className='drawer-products__card--col'>
                        <div className='drawer-products__card--provider'>
                          <DisplayElementIcon kind='port' />
                        </div>
                      </div>
                      <div className='drawer-products__card--col'>
                        {result?.name}
                      </div>
                      <div className='drawer-products__card--col'>
                        {humanizeBandwidth(result?.product?.bandwidth)}
                      </div>
                      <div className='drawer-products__card--col'>
                        {humanizeBandwidth(result?.availableBandwidth)}
                      </div>
                    </div>
                  )
                )
              })
            ) : (
              <div className='drawer-products__filters-message'>
                {t('element.NO_AVAILABLE_PORTS')}
              </div>
            )}
          </>
        </div>
      </div>
    </>
  )
}
