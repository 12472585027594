import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    background: 'var(--background-primary )',
    minWidth: 'fit-content',
  },
}))
