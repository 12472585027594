import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import type { CustomContentProps } from 'notistack'
import { SnackbarContent, useSnackbar } from 'notistack'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { ErrorData } from '../../slices/types'
import { StyleSnackbarProgressBar } from '../progressbars/SnackbarProgressBar'

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
    backgroundColor: '#D32F2F',
  },
  typography: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
    color: '#fff',
  },
  close: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#fff',
    transition: 'all .2s',
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: '10px',
    display: 'flex',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  progressBar: {
    width: '100%',
  },
}))

interface SnackbarErrorProps extends CustomContentProps {
  error: ErrorData
  autoHideDuration: number
}

const SnackbarError = forwardRef<HTMLDivElement, SnackbarErrorProps>(
  ({ id, message, error, autoHideDuration }, ref) => {
    const classes = useStyles()
    const { closeSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [progress, setProgress] = useState(100)

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    useEffect(() => {
      const interval = 100
      const decrement = 100 / (autoHideDuration / interval)

      const timer = setInterval(() => {
        setProgress((prev) => Math.max(0, prev - decrement))
      }, interval)

      return () => clearInterval(timer)
    }, [autoHideDuration])

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Card className={classes.card}>
          <CardActions classes={{ root: classes.actionRoot }}>
            <Typography component={'div'} className={classes.typography}>
              <div className={classes.checkIcon}>
                <CancelIcon />
              </div>
              <div>
                <div className={classes.message}>
                  {t(`messages.errors.${error.code}`)}
                  {error.details && ':'}
                </div>
                {error.details?.map(({ msg }) => <div key={msg}>- {msg}</div>)}
              </div>
            </Typography>
            <div className={classes.icons}>
              <IconButton
                size='small'
                className={classes.close}
                onClick={handleDismiss}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </div>
          </CardActions>
          <StyleSnackbarProgressBar
            className={classes.progressBar}
            variant='determinate'
            value={progress}
          />
        </Card>
      </SnackbarContent>
    )
  }
)

SnackbarError.displayName = 'SnackbarError'

export default SnackbarError
