import type { TFunction } from 'i18next'

export const displayElementType = (
  kind: string,
  type: string,
  t: TFunction
) => {
  switch (kind) {
    case 'node':
      switch (type) {
        case 'access':
          return t('element.ACCESS_NODE')
        case 'cloud':
          return t('element.CLOUD_NODE')
        default:
          return ''
      }

    case 'transport':
      return t('element.TRANSPORT')

    case 'attachments':
      return t('element.ATTACHMENT')

    case 'port':
      switch (type) {
        case 'PHYSICAL':
          return t('element.PHYSICAL_PORT')
        default:
          return ''
      }

    default:
      return ''
  }
}
