// IMPORT DEPENDENCIES
import { InputLabel } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

// IMPORT STYLES
import {
  StyledActionButton,
  StyledButton,
  StyledTextField,
} from '../../forms/StyledFormComponents'

// IMPORT ICONS
import AddIcon from '../../../assets/icons-io-v1/add.svg?react'
import EditIcon from '../../../assets/icons-io-v1/edit.svg?react'

// TYPES
import useResizeObserver from 'use-resize-observer'
import { displayElementType } from '../../../hooks/displayElementType'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { ResourceType } from '../../../slices/authorizationsApiSlice'
import type { TransportCreateFormData } from '../../../slices/types'
import { useCreateTransportInWorkspaceV2Mutation } from '../../../slices/workspacesApiSliceV2'
import { DOCS_URL } from '../../../views/Login/SetPassword'
import FormatMonthlyPrice from '../../format/monthlyPrice'
import Visible from '../../permissions/Visible'
import type { CreationState } from '../DrawerCreateElementV2'
import ReadOnlyRow from '../ReadOnlyRows'

type ConfigureTransportProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  toggleDrawer: () => void
}

export default function ConfigureTransport({
  newElementData,
  setCreationStep,
  setNewElementData,
  toggleDrawer,
}: ConfigureTransportProps) {
  const { showError, showSuccess } = useSnackbar()
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { currentAccount } = useCurrentAccount()
  const [createElementTransport] = useCreateTransportInWorkspaceV2Mutation()
  const { t } = useTranslation()

  const handleAddProduct = () => {
    setCreationStep(2)
    navigate(`product`)
  }

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  const createElement = useCallback(async () => {
    const data = newElementData

    if (!data) {
      return
    }

    const transport: TransportCreateFormData = {
      type: 'transport',
      name: data.elementName,
      product: { sku: data.elementProduct.sku },
    }

    try {
      await createElementTransport({
        accountId: currentAccount?.id,
        workspaceId: workspaceId,
        transport: transport,
      }).unwrap()
      toggleDrawer()
      showSuccess(
        t('element.messages.ELEMENT_HAS_BEEN_CREATED_WITH_SUCCESS', {
          element: displayElementType('transport', '', t),
        })
      )
    } catch (e) {
      showError(e)
    }
  }, [
    newElementData,
    createElementTransport,
    currentAccount?.id,
    workspaceId,
    toggleDrawer,
    showSuccess,
    t,
    showError,
  ])

  return (
    <>
      <div className='drawer-create__containers' ref={drawerWidth}>
        <div className='drawer-create__columns-input'>
          <InputLabel
            htmlFor='element-form-name'
            className='drawer__forms--labels'>
            {t('element.ELEMENT_NAME')}
          </InputLabel>
          <StyledTextField
            required
            id='element-form-name'
            variant='outlined'
            size='small'
            value={newElementData?.elementName}
            slotProps={{
              htmlInput: {
                form: {
                  autocomplete: 'off',
                },
              },
            }}
            onChange={(e) =>
              setNewElementData({
                ...newElementData,
                elementName: e.target.value,
              })
            }
          />
        </div>
      </div>

      {/* ----- PRODUCT ----- */}
      {Object.keys(newElementData?.elementProduct ?? {}).length === 0 ? (
        <div className='drawer-create__containers'>
          <div className='drawer-create__product-rows'>
            <div className='drawer-create__columns-title'>PRODUCT</div>
            <div className='drawer-create__columns-button'>
              <StyledButton
                variant='outlined'
                startIcon={<AddIcon height='22' width='22' />}
                onClick={() => handleAddProduct()}>
                Add
              </StyledButton>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='drawer-create__containers-product-info'>
            <div className='drawer-create__product-rows'>
              <div className='drawer-create__columns-title header'>PRODUCT</div>
              <div className='drawer-create__columns-button'>
                <StyledButton
                  variant='outlined'
                  startIcon={<EditIcon height='22' width='22' />}
                  onClick={() => handleAddProduct()}>
                  Replace
                </StyledButton>
              </div>
            </div>

            <ReadOnlyRow
              title={t('element.PROVIDER')}
              value={newElementData.elementProduct.provider}
            />

            <ReadOnlyRow
              title={t('element.LOCATION_1')}
              value={newElementData.elementProduct.location}
            />

            <ReadOnlyRow
              title={t('element.LOCATION_2')}
              value={newElementData.elementProduct.locationTo}
            />

            <ReadOnlyRow
              title={t('element.BANDWIDTH')}
              value={humanizeBandwidth(newElementData.elementProduct.bandwidth)}
            />

            <ReadOnlyRow
              title={t('element.SKU')}
              value={newElementData.elementProduct.sku}
            />

            <ReadOnlyRow
              title={t('element.MRC')}
              value={
                <FormatMonthlyPrice
                  price={newElementData.elementProduct.priceMrc}
                />
              }
            />
          </div>
        </>
      )}

      {/* --------------------------------- */}
      {/* ------------ ACTIONS ------------ */}
      {/* --------------------------------- */}
      <div className='element__form--btn' style={{ width }}>
        {/* --------------------------------- */}
        {/* ---------- DISCLAIMERS ---------- */}
        {/* --------------------------------- */}
        <div className='element__form--btn-disclaimer'>
          {t('disclaimers.CREATING_ELEMENT')}
          <a href={DOCS_URL} target='_blank' rel='noreferrer'>
            {DOCS_URL}
          </a>
        </div>

        <Visible
          resource={ResourceType.Workspace}
          resourceId={workspaceId}
          action='edit_node'>
          <StyledActionButton
            sx={{
              width: '49.5%',
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={toggleDrawer}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            sx={{ width: '49.5%', marginLeft: '1%' }}
            type='submit'
            color='primary'
            variant='contained'
            disabled={
              newElementData?.elementProduct?.sku === '' ||
              newElementData?.elementProduct?.sku === undefined
                ? true
                : false
            }
            onClick={createElement}>
            {t('element.CREATE')}
          </StyledActionButton>
        </Visible>
      </div>
    </>
  )
}
