import { useTranslation } from 'react-i18next'
import ReadOnlyRow from '../../../../components/drawer/ReadOnlyRows'
import { humanizeBandwidth } from '../../../../hooks/humanizeBandwidth'
import type { ProductElement } from '../../../../slices/types'

type ElementData = {
  data: {
    product: ProductElement
  }
}

type Props<T extends ElementData> = {
  elementData: T
}

export default function CloudProduct<T extends ElementData>({
  elementData,
}: Props<T>) {
  const { t } = useTranslation()

  return (
    <>
      <div className='show__element--content'>
        <div className='show__element--title'>{t('element.PRODUCT')}</div>
      </div>
      <ReadOnlyRow
        title={t('element.CSP_NAME')}
        value={elementData.data.product.cspName}
      />
      <ReadOnlyRow
        title={t('element.PROVIDER')}
        value={elementData.data.product.provider}
      />
      <ReadOnlyRow
        title={t('element.CSP_REGION')}
        value={elementData.data.product.cspRegion}
      />
      <ReadOnlyRow
        title={t('element.CSP_CITY')}
        value={elementData.data.product.cspCity}
      />
      <ReadOnlyRow
        title={t('element.LOCATION')}
        value={elementData.data.product.location}
      />
      <ReadOnlyRow
        title={t('element.BANDWIDTH')}
        value={humanizeBandwidth(elementData.data.product.bandwidth)}
      />
      <ReadOnlyRow
        title={t('element.SKU')}
        value={elementData.data.product.sku}
      />
      <ReadOnlyRow
        title={t('element.MRC')}
        value={elementData.data.product.priceMrc}
      />
    </>
  )
}
