import { API_PATH_V1 } from '../environments/env'
import { apiSlice } from './apiSlice'
import type { Profile } from './types'

export enum ResourceType {
  All = '*',
  Account = 'account',
  Workspace = 'workspace',
}

export type Permissions = Partial<
  Record<
    ResourceType,
    Partial<Record<string, Partial<Record<string, boolean>>>>
  >
>

export const authorizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfiles: builder.query<Profile[], { accountId: string }>({
      query: ({ accountId }) => {
        return {
          url: `${API_PATH_V1}/profiles/accounts/${accountId}`,
        }
      },
    }),
    getProfilesInWorkspace: builder.query<
      Profile[],
      { accountId: string; workspaceId: string }
    >({
      query: ({ accountId, workspaceId }) => {
        return {
          url: `${API_PATH_V1}/profiles/accounts/${accountId}/workspaces/${workspaceId}`,
        }
      },
    }),
    getPermissions: builder.query<Permissions, void>({
      query: () => {
        return {
          url: `${API_PATH_V1}/permissions`,
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
})

export const {
  useGetProfilesQuery,
  useGetProfilesInWorkspaceQuery,
  useGetPermissionsQuery,
} = authorizationsApiSlice
