// IMPORT DEPENDENCIES
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// IMPORT STYLES
import { StyledDrawer } from '../StyledDrawer'
import '../styles.scss'

// TYPES
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useResizeObserver from 'use-resize-observer'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'
import { displayElementType } from '../../../hooks/displayElementType'
import {
  attachmentElementIcons,
  DisplayElementIcon,
} from '../../../hooks/displayIcons'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useSnackbar } from '../../../hooks/useSnackbar'
import type {
  AccessElement,
  AttachmentElementData,
  CloudElement,
  ProductElement,
  TransportElementV2,
  WorkspaceV2,
} from '../../../slices/types'
import {
  useAvailableAttachmentsQuery,
  useCreateAttachmentMutation,
  useDeleteAttachmentMutation,
  useGetElementByIdV2Query,
} from '../../../slices/workspacesApiSliceV2'
import AdministrativeStatus from '../../chips/administrativeStatus'
import {
  StyledActionButton,
  StyledButton,
} from '../../forms/StyledFormComponents'

type DrawerAttachmentProps = {
  workspaceData: WorkspaceV2
}

const DisplayDetachDisclaimer = ({
  workspaceData,
  nodeId,
  transportId,
}: {
  workspaceData: DrawerAttachmentProps['workspaceData']
  nodeId?: string
  transportId?: string
}) => {
  const { t } = useTranslation()

  let element:
    | DrawerAttachmentProps['workspaceData']['data']['nodes'][0]
    | DrawerAttachmentProps['workspaceData']['data']['transports'][0]
  if (!!nodeId) {
    element = workspaceData.data.nodes.find((node) => node.id === nodeId)
  } else {
    element = workspaceData.data.transports.find(
      (transport) => transport.id === transportId
    )
  }

  if (!element) {
    return null
  }

  return (
    <div className='drawer-create__id-card'>
      <Typography variant='h6'>
        <p>
          {t('element.DETACH_DISCLAIMER', {
            elementKind: displayElementType(
              element.kind,
              'type' in element ? element.type : '',
              t
            ),
            elementName: element.name,
          })}
        </p>
      </Typography>
    </div>
  )
}

const DisplayAttachConfiguration = ({
  isTransportAttached,
  kind,
  name,
  product,
  setTransportFilters,
  availableAttachments,
  transportFilters,
  type,
  selectedNodeId,
  handleAddAttachment,
}: {
  availableAttachments:
    | (CloudElement | AccessElement)[]
    | Omit<TransportElementV2, 'attachments'>[]
  name: string
  kind: string
  type: string
  product: ProductElement
  setTransportFilters: Dispatch<SetStateAction<string>>
  transportFilters: string
  isTransportAttached: boolean
  selectedNodeId: string
  handleAddAttachment: (elementId: string) => void
}) => {
  const { t } = useTranslation()
  if (kind === 'attachment') {
    return null
  }

  if (!product) {
    return null
  }

  return (
    <div className='drawer-create__id-card'>
      <div className='accordion__container-show accordion__container-show-border'>
        <div className='accordion__element-type'>
          <DisplayElementIcon kind={kind} type={type} csp={product.cspName} />
        </div>

        <div className='accordion__element-details'>
          <div className='accordion__element-details-container'>
            <div className='accordion__element-details--type'>
              {displayElementType(kind, type, t)}
            </div>
            <div className='accordion__element-details--name'>{name}</div>
          </div>

          <div className='accordion__element-details-container'>
            {
              <div className='accordion__element-details--sm'>
                {product.location || ' - '}
              </div>
            }
            <div className='accordion__element-details--sm'> | </div>
            {
              <div className='accordion__element-details--sm'>
                {product.bandwidth
                  ? humanizeBandwidth(product.bandwidth)
                  : ' - '}
              </div>
            }

            {kind === 'transport' && (
              <>
                <div className='accordion__element-details--sm'> | </div>
                <div className='accordion__element-details--sm'>
                  {product.locationTo ? product.locationTo : ' - '}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {kind === 'transport' && !isTransportAttached && (
        <Box
          style={{
            padding: '30px 0 0',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <StyledButton
            color='primary'
            variant='text'
            selected={transportFilters === product.location}
            onClick={() => {
              setTransportFilters((tf) => {
                return tf === product.location ? null : product.location
              })
            }}
            style={{ marginRight: 10 }}>
            {product.location}
          </StyledButton>
          <StyledButton
            color='primary'
            variant='text'
            selected={transportFilters === product.locationTo}
            onClick={() => {
              setTransportFilters((tf) => {
                return tf === product.locationTo ? null : product.locationTo
              })
            }}
            style={{ marginRight: 10 }}>
            {product.locationTo}
          </StyledButton>
        </Box>
      )}

      {availableAttachments?.length === 0 ? (
        <Typography variant='h6' sx={{ marginTop: '24px' }}>
          {t('element.messages.NO_AVAILABLE_ATTACHMENTS')}
        </Typography>
      ) : (
        <>
          <Typography variant='h6' sx={{ marginTop: '24px' }}>
            {t('element.messages.AVAILABLE_ELEMENTS')}
          </Typography>
          <Divider
            sx={{
              width: '100%',
              margin: '8px 0 0 0',
              borderColor: 'var(--border-primary)',
            }}
          />
          <div>
            <ListOfAvailableAttachments
              handleAddAttachment={handleAddAttachment}
              selectedNodeId={selectedNodeId}
              availableAttachments={availableAttachments}
            />
          </div>
        </>
      )}
    </div>
  )
}

const AvailableAttachment = ({
  element,
  isSelected,
  handleAddAttachment,
}: {
  element:
    | AccessElement
    | CloudElement
    | Omit<TransportElementV2, 'attachments'>
  isSelected: boolean
  handleAddAttachment: (elementId: string) => void
}) => {
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    handleAddAttachment(element.id)
  }, [element.id, handleAddAttachment])

  return (
    <div
      className={`attachments__selection ${
        isSelected ? 'attachment__selected' : ''
      }`}
      key={element.id}
      onClick={onClick}>
      <div className='attachments__selection-card'>
        <div className='attachments__selection-card-info'>
          {attachmentElementIcons(
            element.kind,
            element.kind === 'transport' ? '' : element.type,
            element.product.cspName
          )}
        </div>
        <div className='attachments__selection-card-info element__type__card--small-type'>
          {displayElementType(
            element.kind,
            element.kind === 'transport' ? '' : element.type,
            t
          )}
        </div>
        <div className='attachments__selection-card-info'>{element.name}</div>
        <div className='attachments__selection-card-info '>
          {humanizeBandwidth(element.product.bandwidth)}
        </div>
      </div>
      <div className='attachments__selection-status'>
        <AdministrativeStatus status={element.administrativeState} />
      </div>
    </div>
  )
}

const ListOfAvailableAttachments = ({
  handleAddAttachment,
  selectedNodeId,
  availableAttachments,
}: {
  handleAddAttachment: (elementId: string) => void
  availableAttachments:
    | (CloudElement | AccessElement)[]
    | Omit<TransportElementV2, 'attachments'>[]
  selectedNodeId: string
}) => {
  return (
    <div>
      {availableAttachments.map(
        (
          element:
            | AccessElement
            | CloudElement
            | Omit<TransportElementV2, 'attachments'>
        ) => {
          const isSelected = selectedNodeId === element.id
          return (
            <AvailableAttachment
              key={element.id}
              element={element}
              isSelected={isSelected}
              handleAddAttachment={handleAddAttachment}
            />
          )
        }
      )}
    </div>
  )
}

const adaptElementKind = (type: string) => {
  switch (type) {
    case 'cloud':
      return 'nodes'
    case 'access':
      return 'nodes'
    case 'transport':
      return 'transports'
    default:
      break
  }
}

export default function DrawerAttachment({
  workspaceData,
}: DrawerAttachmentProps) {
  const { showError, showSuccess } = useSnackbar()
  const { currentAccount } = useCurrentAccount()
  const {
    workspaceId,
    elementId,
    type: elementType,
    location: side,
  } = useParams()

  const [selectedNodeId, setSelectedNodeId] = useState<string>('')
  const [transportFilters, setTransportFilters] = useState<string>(null)
  const [createAttachment] = useCreateAttachmentMutation()
  const [deleteAttachment] = useDeleteAttachmentMutation()
  const [attachment, setAttachment] = useState<AttachmentElementData | null>(
    null
  )
  const { t } = useTranslation()
  const navigate = useNavigate()

  const toggleDrawer = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const adaptedType = adaptElementKind(elementType)

  const { data: elementData, error } = useGetElementByIdV2Query(
    {
      accountId: currentAccount.id,
      workspaceId: workspaceId,
      elementType: adaptedType,
      elementId: elementId,
    },
    {
      skip: !currentAccount.id || !adaptedType,
      refetchOnMountOrArgChange: true,
    }
  )

  const { data: attachmentsData } = useAvailableAttachmentsQuery(
    {
      accountId: currentAccount.id,
      workspaceId: workspaceId,
      elementType: adaptedType,
      elementId: elementId,
    },
    {
      skip: !currentAccount.id || !adaptedType,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (!error) return
    showError(error)
    toggleDrawer()
  }, [showError, error, toggleDrawer])

  const handleDeleteAttachment = useCallback(async () => {
    try {
      await deleteAttachment({
        accountId: currentAccount.id,
        workspaceId: workspaceId,
        attachmentId: attachment?.id,
      }).unwrap()

      toggleDrawer()
      showSuccess(t('element.messages.ATTACHMENT_DELETED_WITH_SUCCESS'))
    } catch (e) {
      showError(e)
    }
  }, [
    deleteAttachment,
    currentAccount.id,
    workspaceId,
    attachment?.id,
    toggleDrawer,
    showSuccess,
    t,
    showError,
  ])

  const handleConfirmAttachment = useCallback(async () => {
    if (!elementData) {
      return
    }
    try {
      await createAttachment({
        accountId: currentAccount?.id,
        workspaceId: workspaceId,
        attachment:
          elementData.data.kind === 'transport'
            ? { nodeId: selectedNodeId, transportId: elementId }
            : { nodeId: elementId, transportId: selectedNodeId },
      }).unwrap()
      toggleDrawer()
      showSuccess(
        t('element.messages.ATTACHMENT_CREATED_WITH_SUCCESS', {
          element: displayElementType(
            elementData.data.kind,
            'type' in elementData.data ? elementData.data.type : '',
            t
          ),
        })
      )
    } catch (e) {
      showError(e)
    }
  }, [
    createAttachment,
    currentAccount?.id,
    elementData,
    elementId,
    selectedNodeId,
    t,
    toggleDrawer,
    workspaceId,
    showError,
    showSuccess,
  ])

  const [availableAttachments, setAvailableAttachments] = useState<
    (CloudElement | AccessElement)[] | Omit<TransportElementV2, 'attachments'>[]
  >([])

  useEffect(() => {
    if (workspaceData && attachmentsData) {
      if ('nodes' in attachmentsData.data) {
        const availableNodesIds = attachmentsData.data.nodes
        const availableNodes = workspaceData.data.nodes.reduce<
          Array<CloudElement | AccessElement>
        >((acc, current) => {
          if (availableNodesIds.find(({ id }) => id === current.id)) {
            acc = [...acc, current]
          }
          return acc
        }, [])

        setAvailableAttachments(
          availableNodes.filter(
            ({ product: { location } }) =>
              !transportFilters || transportFilters === location
          )
        )
      } else if ('transports' in attachmentsData.data) {
        const availableTransportsIds = attachmentsData.data.transports
        const availableTransports = workspaceData.data.transports.reduce<
          Omit<TransportElementV2, 'attachments'>[]
        >((acc, current) => {
          if (availableTransportsIds.find(({ id }) => id === current.id)) {
            acc = [...acc, current]
          }
          return acc
        }, [])

        setAvailableAttachments(
          availableTransports.filter(
            ({ product: { location, locationTo } }) =>
              !transportFilters ||
              transportFilters === location ||
              transportFilters === locationTo
          )
        )
      }
    }
  }, [
    attachmentsData,
    transportFilters,
    workspaceData,
    workspaceData.data.nodes,
    workspaceData.data.transports,
  ])

  const dataAttachement =
    elementData && 'attachment' in elementData?.data
      ? elementData?.data.attachment
      : null

  const dataAttachements =
    elementData && 'attachments' in elementData.data
      ? elementData.data.attachments
      : null

  useEffect(() => {
    if (dataAttachement) {
      setAttachment(dataAttachement)
    }
  }, [dataAttachement])

  useEffect(() => {
    if (dataAttachements) {
      const attachedElement = dataAttachements.find((att) => {
        return att.transportId === elementData?.data.id && att.side === side
      })
      setAttachment(attachedElement)
    }
  }, [dataAttachements, elementData?.data.id, side])

  const handleAddAttachment = useCallback(
    (elementId: string) => {
      setSelectedNodeId(elementId === selectedNodeId ? null : elementId)
    },
    [selectedNodeId]
  )

  // Function to determine if we show the filter buttons or not
  const isTransportAttached = useMemo(() => {
    return (
      !!workspaceData &&
      !!elementData &&
      workspaceData.data.attachments.some(
        (t) => t.transportId === elementData.data.id
      )
    )
  }, [elementData, workspaceData])

  const name =
    elementData && 'name' in elementData.data ? elementData.data.name : ''

  const kind = elementData?.data.kind
  const type = elementData?.data.kind === 'node' ? elementData?.data.type : ''

  const product =
    elementData?.data.kind === 'attachment' ? null : elementData?.data.product

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  return (
    <StyledDrawer anchor='right' open onClose={toggleDrawer}>
      <section className='drawer-create__wrapper' ref={drawerWidth}>
        <div className='show__element__container-close'>
          <IconButton
            onClick={toggleDrawer}
            sx={{ padding: '0', height: '24px' }}>
            <CloseIcon fontSize='small' style={{ width: '22px' }} />
          </IconButton>
        </div>

        <div>
          <div className='drawer-create__header'>
            {!!attachment ? 'Detach element' : 'Select your element to attach'}
          </div>
        </div>

        {!!attachment ? (
          <DisplayDetachDisclaimer
            workspaceData={workspaceData}
            nodeId={kind === 'node' ? elementData.data.id : null}
            transportId={kind === 'transport' ? elementData.data.id : null}
          />
        ) : (
          <DisplayAttachConfiguration
            handleAddAttachment={handleAddAttachment}
            selectedNodeId={selectedNodeId}
            isTransportAttached={isTransportAttached}
            availableAttachments={availableAttachments}
            kind={kind}
            name={name}
            product={product}
            setTransportFilters={setTransportFilters}
            transportFilters={transportFilters}
            type={type}
          />
        )}

        <div className='element__form--btn' style={{ width }}>
          <StyledActionButton
            sx={{
              width: '49%',
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={toggleDrawer}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            sx={{ width: '49.5%', marginLeft: '1%' }}
            type='submit'
            color='primary'
            variant='contained'
            disabled={!selectedNodeId && !attachment}
            onClick={
              attachment ? handleDeleteAttachment : handleConfirmAttachment
            }>
            {t('modal.CONFIRM')}
          </StyledActionButton>
        </div>
      </section>
    </StyledDrawer>
  )
}
