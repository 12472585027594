import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { logoutAction, setTokenAction } from './authActions'
import { authApiSlice } from './authApiSlice'

type State = {
  token: string
}

const initialState: State = {
  token: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(logoutAction, (state) => {
        state.token = ''
      })
      .addCase(setTokenAction, (state, { payload: token }) => {
        state.token = token
      })
      .addMatcher(authApiSlice.endpoints.revoke.matchPending, (state) => {
        state.token = ''
      })
      .addMatcher(
        authApiSlice.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          if (payload?.accessToken?.token) {
            state.token = payload.accessToken.token
          } else {
            state.token = ''
          }
        }
      )
      .addMatcher(authApiSlice.endpoints.login.matchPending, (state) => {
        state.token = ''
      })
  },
})

const selectAuth = (state: RootState) => state.auth
export const selectCurrentToken = createSelector(selectAuth, (s) => s.token)
