// -- Import NPM
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ElementStates } from '../../slices/types'

type AdministrativeStatusProps = {
  status: string
  attachment?: boolean
}

export default function AdministrativeStatus(props: AdministrativeStatusProps) {
  const { status, attachment } = props

  // The code below sets the Chip for the Administrative Status
  // creation_pending | creation_proceed | creation_error | deployed | delete_pending | delete_proceed | delete_error | deleted
  const capitalizeStr = (str: string) => {
    return str.replace('_', ' ').toUpperCase()
  }

  const StylesChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
      border: 'none',
      minWidth: '100%',
      fontSize: '12px',
      fontWeight: '500',
      minHeight: '24px',
    },
  }))

  const displayWsState = (state: string) => {
    switch (state) {
      case ElementStates.creation_pending:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case ElementStates.creation_proceed:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case ElementStates.creation_error:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-failed-full)',
              backgroundColor: 'var(--chips-failed)',
            }}
          />
        )
      case ElementStates.deployed:
        return (
          <StylesChip
            label={capitalizeStr(attachment ? 'Attached' : state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deployed-full)',
              backgroundColor: 'var(--chips-deployed)',
            }}
          />
        )
      case ElementStates.delete_pending:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case ElementStates.delete_proceed:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-deploying-full)',
              backgroundColor: 'var(--chips-deploying)',
            }}
          />
        )
      case ElementStates.delete_error:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-failed-full)',
              backgroundColor: 'var(--chips-failed)',
            }}
          />
        )
      case ElementStates.deleted:
        return (
          <StylesChip
            label={capitalizeStr(state)}
            size='small'
            variant='outlined'
            sx={{
              color: 'var(--chips-draft-full)',
              backgroundColor: 'var(--chips-draft)',
            }}
          />
        )
      default:
        break
    }
  }

  return displayWsState(status)
}
