import type { FC, PropsWithChildren } from 'react'
import { usePermissions } from '../../hooks/usePermissions'
import type { ResourceType } from '../../slices/authorizationsApiSlice'

interface VisibleProps {
  resource: ResourceType
  resourceId: string
  action: string
}

const Visible: FC<PropsWithChildren<VisibleProps>> = ({
  children,
  resource,
  resourceId,
  action,
}) => {
  const { allowed } = usePermissions(resource, resourceId, action)

  if (!allowed) {
    return null
  }

  return <>{children}</>
}

export default Visible
