// -- NPM IMPORTS
import { MenuItem } from '@mui/material'

// -- COMPONENT IMPORTS

// -- ASSETS IMPORTS
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import {
  StyledInputLabel,
  StyledTextField,
  selectProps,
} from '../../components/forms/StyledFormComponents'
import type { PortsFilters } from '../../slices/types'
import './style.scss'

export type SelectFilterMenuProps = {
  filters: PortsFilters[] // the filters object
  setFilter: React.Dispatch<React.SetStateAction<string>>
}
export default function SelectFilterMenu({
  filters,
  setFilter,
}: SelectFilterMenuProps) {
  const [activeBtn, setActiveBtn] = useState<string>(null)

  const handleCreatedFilterButton = useCallback(
    (value: string) => {
      if (value === 'All' || value === 'Clear Filters') {
        setFilter('')
        setActiveBtn(null)
      } else {
        setFilter(value)
        setActiveBtn(value)
      }
    },
    [setFilter]
  )

  return (
    <div className='ports__filters-container'>
      <div className='ports__filters--drop-down'>
        <StyledInputLabel shrink htmlFor='element-form-csp-name'>
          {t('ports.columns.FILTERS')}
        </StyledInputLabel>
        <StyledTextField
          select
          size='small'
          value={activeBtn !== null ? activeBtn : 'All'}
          sx={{
            textTransform: 'capitalize',
            padding: '0',
            margin: '0',
            border: '1px solid var(--border-primary)',
            '& fieldset': { border: 'none' },
          }}
          slotProps={{
            select: selectProps,
          }}
          variant='outlined'>
          {filters.map((filter: { id: number; filter: string }) => {
            return (
              <MenuItem
                key={filter.id}
                value={filter.filter}
                onClick={() => handleCreatedFilterButton(filter.filter)}
                sx={{ textTransform: 'capitalize' }}>
                {filter.filter}
              </MenuItem>
            )
          })}
        </StyledTextField>
      </div>
    </div>
  )
}
