// -- NPM IMPORTS
import { Button } from '@mui/material'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

// -- COMPONENT IMPORTS
import type { ResourceType } from '../../slices/authorizationsApiSlice'
import type { WorkspaceData } from '../../slices/types'
import CreateItemModal from '../modals/CreateItemModal'
import Visible from '../permissions/Visible'

// -- ASSETS IMPORTS
import { useNavigate } from 'react-router-dom'
import AddIcon from '../../assets/icons-16/add.svg?react'
import './style.scss'

type PageHeaderProps = {
  page?: 'WORKSPACES' | 'USERS'
  staticTitle?: boolean
  addItemFor?: string
  datas?: WorkspaceData // @FIXME: Or Ports or Users or ...
  showDesignButton?: boolean
  permCreate?: [ResourceType, string, string]
}

const PageHeader: FC<PageHeaderProps> = ({
  page,
  addItemFor,
  datas,
  staticTitle,
  permCreate: [permCreateResource, permCreateResourceId, permCreateAction] = [],
}) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()

  const handleClickOpenModal = useCallback(() => {
    setOpenModal(true)
  }, [])

  //* Virifcation for button control according to scope
  // Use case :
  // Create User > Open Drawer
  // Create Workspaces > Open Create Item Modal
  const handleButtonCreate = useCallback(
    (page: string) => () => {
      if (page === 'WORKSPACES') {
        handleClickOpenModal()
      } else if (page === 'USERS') {
        navigate('create')
      }
    },
    [handleClickOpenModal, navigate]
  )

  const staticTitleData = useMemo(() => {
    if (datas)
      return (
        <div className='page__header__primary-content'>
          <div className='page__header__primary-content-infos'>
            <div className='page__header__primary-content-infos-title'>
              {datas?.name}
            </div>
            <div className='page__header__primary-content-infos-description'>
              {datas?.description}
            </div>
          </div>
        </div>
      )
  }, [datas])

  return (
    <div className='page__header'>
      <div className='page__header__primary'>
        {staticTitle ? (
          staticTitleData
        ) : (
          <div className='page__header__primary-content-infos-title'>
            {t(`page.${page}`)}
          </div>
        )}

        <Visible
          resource={permCreateResource}
          resourceId={permCreateResourceId}
          action={permCreateAction}>
          <div className='page__header__primary-button'>
            <Button
              variant='contained'
              startIcon={<AddIcon />}
              onClick={handleButtonCreate(page)}>
              {addItemFor}
            </Button>
          </div>
        </Visible>
      </div>

      <CreateItemModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        model='workspace'
        dialogMessage={addItemFor}
      />
    </div>
  )
}

export default PageHeader
