import { ElementStates } from '../../../slices/types'

const detachableStates = [
  ElementStates.deployed,
  ElementStates.creation_error,
  ElementStates.delete_error,
]
export function AttachmentIsDetachable(state: ElementStates) {
  return detachableStates.includes(state)
}
