// IMPORT DEPENDENCIES
import { InputLabel } from '@mui/material'
import type { ChangeEventHandler, Dispatch, SetStateAction } from 'react'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// IMPORT STYLES
import {
  StyledActionButton,
  StyledButton,
  StyledTextField,
} from '../../forms/StyledFormComponents'

// IMPORT ICONS
import AddIcon from '../../../assets/icons-io-v1/add.svg?react'
import EditIcon from '../../../assets/icons-io-v1/edit.svg?react'

// TYPES
import useResizeObserver from 'use-resize-observer'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { ResourceType } from '../../../slices/authorizationsApiSlice'
import { useAddNewPortMutation } from '../../../slices/portsApiSlice'
import { DOCS_URL } from '../../../views/Login/SetPassword'
import FormatMonthlyPrice from '../../format/monthlyPrice'
import Visible from '../../permissions/Visible'
import type { CreationState } from '../DrawerCreateElementV2'
import ReadOnlyRow from '../ReadOnlyRows'

type ConfigureTransportProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  setLoading: Dispatch<SetStateAction<boolean>>
}

export default function ConfigurePort({
  newElementData,
  setCreationStep,
  setNewElementData,
  setLoading,
}: ConfigureTransportProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { workspaceId } = useParams()
  const { currentAccount } = useCurrentAccount()

  const [createPort] = useAddNewPortMutation()
  const { t } = useTranslation()

  const handleAddProduct = useCallback(() => {
    setCreationStep(1)
    navigate(`${location?.pathname}/product`)
  }, [location?.pathname, navigate, setCreationStep])

  // ----- Action Buttons Fixed Position Fix ----- //
  const drawerWidth = useRef(null)
  const { width } = useResizeObserver({ ref: drawerWidth })
  //     - please add the following reference to the parent container
  //     - that will define the defined width
  //  TODO ref={drawerWidth}
  // ----- END Action Buttons Fixed Position Fix ----- //

  const discardDrawer = useCallback(() => {
    navigate(`/ports`)
  }, [navigate])

  const createElement = useCallback(
    async ({ elementName: name, elementProduct: { sku } }: CreationState) => {
      const body = {
        name,
        product: { sku },
      }
      setLoading(true)
      try {
        await createPort({
          accountId: currentAccount?.id,
          body,
        }).unwrap()
        navigate(`/ports`)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    },
    [createPort, currentAccount?.id, navigate, setLoading]
  )

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) =>
      setNewElementData((previous) => ({
        ...previous,
        elementName: e.target.value,
      })),
    [setNewElementData]
  )

  const onClickCreate = useCallback(
    () => createElement(newElementData),
    [createElement, newElementData]
  )
  return (
    <>
      <div className='drawer-create__containers' ref={drawerWidth}>
        <div className='drawer-create__columns-input'>
          <InputLabel
            htmlFor='element-form-name'
            className='drawer__forms--labels'>
            {t('element.ELEMENT_NAME')}
          </InputLabel>
          <StyledTextField
            required
            id='element-form-name'
            variant='outlined'
            size='small'
            value={newElementData?.elementName}
            slotProps={{
              htmlInput: {
                form: {
                  autocomplete: 'off',
                },
              },
            }}
            onChange={onChange}
          />
        </div>
      </div>

      {/* ----- PRODUCT ----- */}
      {Object.keys(newElementData?.elementProduct || {}).length === 0 ? (
        <div className='drawer-create__containers'>
          <div className='drawer-create__product-rows'>
            <div className='drawer-create__columns-title'>PRODUCT</div>
            <div className='drawer-create__columns-button'>
              <StyledButton
                variant='outlined'
                startIcon={<AddIcon height='22' width='22' />}
                onClick={handleAddProduct}>
                Add
              </StyledButton>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='drawer-create__containers-product-info'>
            <div className='drawer-create__product-rows'>
              <div className='drawer-create__columns-title'>PRODUCT</div>
              <div className='drawer-create__columns-button'>
                <StyledButton
                  variant='outlined'
                  startIcon={<EditIcon height='22' width='22' />}
                  onClick={handleAddProduct}>
                  Replace
                </StyledButton>
              </div>
            </div>
            <ReadOnlyRow
              title={t('element.PROVIDER')}
              value={newElementData.elementProduct.provider}
            />
            <ReadOnlyRow
              title={t('element.LOCATION')}
              value={newElementData.elementProduct.location}
            />
            <ReadOnlyRow
              title={t('element.BANDWIDTH')}
              value={humanizeBandwidth(newElementData.elementProduct.bandwidth)}
            />
            <ReadOnlyRow
              title={t('element.SKU')}
              value={newElementData.elementProduct.sku}
            />
            <ReadOnlyRow
              title={t('element.MRC')}
              value={
                <FormatMonthlyPrice
                  price={newElementData.elementProduct.priceMrc}
                />
              }
            />
            <ReadOnlyRow
              title={t('element.COMMITMENT')}
              value={t('ports.messages.MONTH', {
                count: newElementData.elementProduct.duration,
              })}
            />
          </div>
        </>
      )}

      {/* --------------------------------- */}
      {/* ------------ ACTIONS ------------ */}
      {/* --------------------------------- */}

      <div className='element__form--btn' style={{ width }}>
        {/* --------------------------------- */}
        {/* ---------- DISCLAIMERS ---------- */}
        {/* --------------------------------- */}
        <div className='element__form--btn-disclaimer'>
          {t('disclaimers.ORDERING_PORT')}
          <a href={DOCS_URL} target='_blank' rel='noreferrer'>
            {DOCS_URL}
          </a>
        </div>

        <Visible
          resource={ResourceType.Workspace}
          resourceId={workspaceId}
          action='edit_node'>
          <StyledActionButton
            sx={{
              width: '49.5%',
              backgroundColor: 'var(--background-tertiary)',
              '&:hover': {
                backgroundColor: 'var(--background-secondary)',
              },
            }}
            color='primary'
            variant='text'
            onClick={discardDrawer}>
            {t('element.DISCARD')}
          </StyledActionButton>
          <StyledActionButton
            sx={{ width: '49.5%', marginLeft: '1%' }}
            type='submit'
            color='primary'
            variant='contained'
            disabled={
              !newElementData?.elementProduct?.sku ||
              newElementData?.elementName === ''
            }
            onClick={onClickCreate}>
            {t('element.CREATE')}
          </StyledActionButton>
        </Visible>
      </div>
    </>
  )
}
