import { useTranslation } from 'react-i18next'
import ReadOnlyRow from '../../../../components/drawer/ReadOnlyRows'
import { humanizeBandwidth } from '../../../../hooks/humanizeBandwidth'
import type { TransportV2 } from '../../../../slices/types'

type Props = {
  elementData: TransportV2
}

export default function TransportProduct({ elementData }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <div className='show__element--content'>
        <div className='show__element--title'>{t('element.PRODUCT')}</div>
      </div>
      <ReadOnlyRow
        title={t('element.PROVIDER')}
        value={elementData.data.product.provider}
      />
      <ReadOnlyRow
        title={t('element.LOCATION_1')}
        value={elementData.data.product.location}
      />
      <ReadOnlyRow
        title={t('element.LOCATION_2')}
        value={elementData.data.product.locationTo}
      />
      <ReadOnlyRow
        title={t('element.BANDWIDTH')}
        value={humanizeBandwidth(elementData.data.product.bandwidth)}
      />
      <ReadOnlyRow
        title={t('element.SKU')}
        value={elementData.data.product.sku}
      />
      <ReadOnlyRow
        title={t('element.MRC')}
        value={elementData.data.product.priceMrc}
      />
    </>
  )
}
