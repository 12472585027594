// -- NPM IMPORTS
import Snackbar from '@mui/material/Snackbar'
import type { AlertColor, AlertProps } from '@mui/material/Alert'
import MuiAlert from '@mui/material/Alert'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'

// -- COMPONENT IMPORTS

// -- ASSETS IMPORTS
import './style.scss'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  }
)

export type SnackbarProps = {
  openSnackbar: boolean
  setOpenSnackbar: Dispatch<SetStateAction<boolean>>
  severity: string // can be "error" => red, "warning" => orange, "info" => blue, "success" => green
  snackbarMessage: string
  anchors: {
    vertical: 'top' | 'bottom'
    horizontal: 'center' | 'left' | 'right'
  }
}

export default function SnackbarAlert({
  openSnackbar,
  setOpenSnackbar,
  severity,
  snackbarMessage,
  anchors,
}: SnackbarProps) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  return (
    <Snackbar
      anchorOrigin={anchors}
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={severity as AlertColor}
        sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  )
}
