import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../slices/authSlice'
import type { Permissions } from '../slices/authorizationsApiSlice'
import {
  ResourceType,
  useGetPermissionsQuery,
} from '../slices/authorizationsApiSlice'
import { useSnackbar } from './useSnackbar'

const isAllowed = (
  resource: ResourceType,
  resourceId: string,
  action: string,
  permissions: Permissions
): boolean => {
  switch (true) {
    case permissions[resource]?.[resourceId]?.[action]:
    case permissions[resource]?.[resourceId]?.['*']:
    case permissions[resource]?.['*']?.[action]:
    case permissions[resource]?.['*']?.['*']:
    case permissions[ResourceType.All]?.[resourceId]?.[action]:
    case permissions[ResourceType.All]?.[resourceId]?.['*']:
    case permissions[ResourceType.All]?.['*']?.[action]:
    case permissions[ResourceType.All]?.['*']?.['*']:
      return true
    default:
      return false
  }
}

export const usePermissions = (
  resource: ResourceType,
  resourceId: string,
  action: string
) => {
  const { showError } = useSnackbar()
  const token = useSelector(selectCurrentToken)
  const [tokenState, setTokenState] = useState(token)

  const {
    data: permissions = {},
    isError,
    isFetching,
    isLoading,
    refetch,
    error,
  } = useGetPermissionsQuery(null, {
    skip: !token,
  })

  useEffect(() => {
    if (tokenState !== token) {
      refetch()
      setTokenState(token)
    }
  }, [tokenState, token, refetch])

  useEffect(() => {
    if (!error) return
    showError(error)
  }, [showError, error])

  const allowed = useMemo(
    () => isAllowed(resource, resourceId, action, permissions),
    [permissions, resource, resourceId, action]
  )

  return {
    allowed,
    isError,
    isFetching,
    isLoading,
  }
}
