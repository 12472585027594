// -- Import NPM
import { useTranslation } from 'react-i18next'

// Import Components
import { Fragment, useCallback, useMemo } from 'react'
import AttachmentIcon from '../../../assets/icons-io-v1/attachment.svg?react'
import DetachmentIcon from '../../../assets/icons-io-v1/detachment.svg?react'
import AdministrativeStatus from '../../../components/chips/administrativeStatus'
import { displayElementType } from '../../../hooks/displayElementType'
import { attachmentElementIcons } from '../../../hooks/displayIcons'

import Visible from '../../../components/permissions/Visible'
import { ResourceType } from '../../../slices/authorizationsApiSlice'
import type {
  AccessElementV2,
  AttachmentElementData,
  WorkspaceV2,
} from '../../../slices/types'
import { AttachmentIsDetachable } from './Attachments'

//Import Icons
import { useNavigate } from 'react-router-dom'
import ShowIcon from '../../../assets/icons-io-v1/show.svg?react'
import { StyledButton } from '../../../components/forms/StyledFormComponents'
import { usePermissions } from '../../../hooks/usePermissions'

type AccordionAccessProps = {
  data: AccessElementV2['data']
  workspaceData?: WorkspaceV2
}

const DisplayDetachButton = ({
  attachment,
  workspaceData,
  data,
}: {
  attachment: AttachmentElementData
  workspaceData: AccordionAccessProps['workspaceData']
  data: AccordionAccessProps['data']
}) => {
  const nodeAttached = workspaceData?.data?.transports?.find(
    (element) => element?.id === attachment?.transportId
  )
  const { t } = useTranslation()
  const navigate = useNavigate()

  const openDetach = useCallback(() => {
    navigate(
      `attachment/transport/${attachment?.transportId}/${attachment?.side}`
    )
  }, [navigate, attachment?.transportId, attachment?.side])

  const showDetailsAttachment = useCallback(() => {
    navigate(`details/attachments/${attachment?.id}/properties`)
  }, [attachment?.id, navigate])

  return (
    <div className='product__container--current-prod'>
      {attachmentElementIcons('transport', '', '')}
      <span className='product__container--attachments-type'>
        {displayElementType('transport', '', t)}
      </span>
      <span className='product__container--attachments-name'>
        {nodeAttached?.name}
      </span>
      <span className='product__container--attachments-status'>
        <AdministrativeStatus
          status={attachment?.administrativeState}
          attachment={true}
        />
      </span>

      <Visible
        resource={ResourceType.Workspace}
        resourceId={data?.id}
        action='get_attachment'>
        <StyledButton
          sx={{
            margin: 0,
            marginLeft: '10px',
            padding: '0 0 0 12px',
            minWidth: 'unset',
          }}
          variant='outlined'
          disableRipple
          startIcon={<ShowIcon />}
          onClick={showDetailsAttachment}
        />
      </Visible>

      <Visible
        resource={ResourceType.Workspace}
        resourceId={data?.id}
        action='delete_attachment'>
        <StyledButton
          sx={{ marginLeft: '12px' }}
          variant='outlined'
          startIcon={<DetachmentIcon />}
          disabled={!AttachmentIsDetachable(attachment?.administrativeState)}
          onClick={openDetach}>
          {t('element.DETACH')}
        </StyledButton>
      </Visible>
    </div>
  )
}

export default function AccessDetails({
  data,
  workspaceData,
}: AccordionAccessProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { allowed } = usePermissions(
    ResourceType.Workspace,
    workspaceData.data?.id,
    'add_attachment'
  )

  const attachment = useMemo((): AttachmentElementData => {
    return (
      workspaceData?.data?.attachments?.find(
        (attachment) => attachment?.nodeId === data?.id
      ) ?? null
    )
  }, [data?.id, workspaceData?.data?.attachments])

  const openAttachmentConfiguration = useCallback(() => {
    navigate(`attachment/access/${data?.id}`)
  }, [navigate, data?.id])

  return (
    <>
      <div className='accordion__container-open'>
        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>
            {t('element.PROVIDER')}
          </div>
          <div className='accordion__open--details-info'>
            {data?.product?.provider ?? ' - '}
          </div>
        </div>

        {data?.product?.type === 'PHYSICAL' && (
          <div className='accordion__container-open--details'>
            <div className='accordion__open--details-title'>
              {t('element.PORT_NAME')}
            </div>
            <div className='accordion__open--details-info'>
              {data?.physicalPort?.name ? data?.physicalPort?.name : ' - '}
            </div>
          </div>
        )}

        {data?.product?.type === 'VIRTUAL' && (
          <div className='accordion__container-open--details'>
            <div className='accordion__open--details-title'>
              {t('element.SERVICE_KEY_ID')}
            </div>
            <div className='accordion__open--details-info'>
              {data?.serviceKey?.id ? data?.serviceKey?.id : ' - '}
            </div>
          </div>
        )}

        {data?.product?.type === 'VIRTUAL' && (
          <div className='accordion__container-open--details'>
            <div className='accordion__open--details-title'>
              {t('element.SERVICE_KEY_NAME')}
            </div>
            <div className='accordion__open--details-info'>
              {data?.serviceKey?.name ? data?.serviceKey?.name : ' - '}
            </div>
          </div>
        )}

        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>
            {t('element.VLAN')}
          </div>
          <div className='accordion__open--details-info'>
            {data?.vlan ? data?.vlan : ' - '}
          </div>
        </div>
      </div>

      <div className='accordion__container-open'>
        <Fragment>
          <div className='accordion__container-open--details'>
            <div className='accordion__open--details-info'>
              <div className='accordion__open--details-info-attachment'>
                {t('element.ATTACHMENT')}: {data?.product?.location}
              </div>
              {!!attachment ? (
                <DisplayDetachButton
                  attachment={attachment}
                  data={data}
                  workspaceData={workspaceData}
                />
              ) : (
                <StyledButton
                  sx={{ height: '48px' }}
                  variant='outlined'
                  startIcon={<AttachmentIcon />}
                  disabled={
                    data?.administrativeState !== 'deployed' || !allowed
                  }
                  onClick={openAttachmentConfiguration}>
                  Attach element
                </StyledButton>
              )}
            </div>
          </div>
        </Fragment>
      </div>
    </>
  )
}
