import { t } from 'i18next'
import ReadOnlyRow from '../../../components/drawer/ReadOnlyRows'
import FormatMonthlyPrice from '../../../components/format/monthlyPrice'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import type { PortElementV2 } from '../../../slices/types'

type Props = {
  elementData: PortElementV2
}

export default function PortProducts({ elementData }: Props) {
  return (
    <>
      <div className='show__element--content'>
        <div className='show__element--title'>{t('element.PRODUCT')}</div>
      </div>
      <ReadOnlyRow
        title={t('element.PROVIDER')}
        value={elementData.data.product.provider}
      />
      <ReadOnlyRow
        title={t('element.LOCATION')}
        value={elementData.data.product.location}
      />
      <ReadOnlyRow
        title={t('element.BANDWIDTH')}
        value={humanizeBandwidth(elementData.data.product.bandwidth)}
      />
      <ReadOnlyRow
        title={t('element.SKU')}
        value={elementData.data.product.sku}
      />
      <ReadOnlyRow
        title={t('element.MRC')}
        value={<FormatMonthlyPrice price={elementData.data.product.priceMrc} />}
      />
      <ReadOnlyRow
        title={t('element.COMMITMENT')}
        value={t('ports.messages.MONTH', {
          count: elementData.data.product.duration,
        })}
      />
    </>
  )
}
