import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import type { CustomContentProps } from 'notistack'
import { SnackbarContent, useSnackbar } from 'notistack'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { StyleSnackbarProgressBar } from '../progressbars/SnackbarProgressBar'

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
  },
  typography: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
    color: '#fff',
  },
  close: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#fff',
    transition: 'all .2s',
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: '10px',
    display: 'flex',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  progressBar: {
    width: '100%',
  },
}))

interface SnackbarProgressProps extends CustomContentProps {
  autoHideDuration: number
  color: string
}

const SnackbarProgress = forwardRef<HTMLDivElement, SnackbarProgressProps>(
  ({ id, message, color, autoHideDuration }, ref) => {
    const classes = useStyles()
    const { closeSnackbar } = useSnackbar()
    const [progress, setProgress] = useState(100)

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    useEffect(() => {
      const interval = 100
      const decrement = 100 / (autoHideDuration / interval)

      const timer = setInterval(() => {
        setProgress((prev) => {
          const newProgress = Math.max(0, prev - decrement)

          if (newProgress === 0) {
            closeSnackbar(id)
          }

          return newProgress
        })
      }, interval)

      return () => clearInterval(timer)
    }, [autoHideDuration, closeSnackbar, id])

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Card
          className={classes.card}
          style={{
            backgroundColor: color === 'success' ? '#5DAB44' : '#D32F2F',
          }}>
          <CardActions classes={{ root: classes.actionRoot }}>
            <Typography className={classes.typography}>
              <div className={classes.checkIcon}>
                {color === 'success' ? <CheckCircleIcon /> : <ErrorIcon />}
              </div>
              <div>
                <div className={classes.message}>{message}</div>
              </div>
            </Typography>
            <div className={classes.icons}>
              <IconButton
                size='small'
                className={classes.close}
                onClick={handleDismiss}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </div>
          </CardActions>
          <StyleSnackbarProgressBar
            className={classes.progressBar}
            variant='determinate'
            value={progress}
          />
        </Card>
      </SnackbarContent>
    )
  }
)

SnackbarProgress.displayName = 'SnackbarProgress'

export default SnackbarProgress
