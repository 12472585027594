// -- Import NPM
import { Divider } from '@mui/material'

// -- Import COMPONENTS
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useGetElementBandwidthMetricsQuery } from '../../../slices/MetricsApiSlice'
import Recharts from '../../charts/Recharts'

type ElementDetailsProps = {
  elementTypeMetric: 'nodes' | 'ports'
  elementId: string
  bandwidth?: number | false
  deployedAt: Date
}

type MetricsQuery = {
  start_d?: string
  end_d?: string
  range?: string
}
export default function DetailsTabMetrics({
  elementTypeMetric,
  elementId,
  bandwidth = false,
  deployedAt,
}: ElementDetailsProps) {
  const { t } = useTranslation()
  const { currentAccount } = useCurrentAccount()
  const { workspaceId } = useParams()

  const [query, setQuery] = useState<MetricsQuery>({})
  const [defaultRangeState, setDefaultRangeState] = useState('default')

  const {
    data: bandwidthData,
    isLoading: isLoadingData,
    error: errorData,
    refetch: refetchData,
  } = useGetElementBandwidthMetricsQuery({
    id: elementId,
    accountId: currentAccount?.id,
    workspaceId,
    params: query,
    elementType: elementTypeMetric,
  })

  useEffect(() => {
    if (query) refetchData()
  }, [query, refetchData])

  const refreshMetricsData = useCallback(
    (customDate?: string, startDate?: Date, endDate?: Date) => {
      var today = new Date()
      var lastDay = new Date(today.getTime() - 1000 * 60 * 60 * 24)
      var lastHour = new Date(today.getTime() - 1000 * 60 * 60)

      switch (customDate) {
        case 'last_hour':
          setDefaultRangeState('lastHour')
          setQuery({
            start_d: lastHour.toISOString().split('.')[0] + 'Z',
            end_d: new Date().toISOString().split('.')[0] + 'Z',
          })
          break
        case 'last_day':
          setDefaultRangeState('lastDay')
          setQuery({
            start_d: lastDay.toISOString().split('.')[0] + 'Z',
            end_d: new Date().toISOString().split('.')[0] + 'Z',
          })
          break
        case 'custom':
          setDefaultRangeState('custom')
          setQuery({
            start_d: new Date(startDate).toISOString().split('.')[0] + 'Z',
            end_d: new Date(endDate).toISOString().split('.')[0] + 'Z',
          })
          break
        default:
          setDefaultRangeState('default')
          setQuery({})
          break
      }
    },
    []
  )

  // const bandwidthProduct = elementData?.data?.product?.bandwidth ?? false

  return (
    <>
      <Divider sx={{ width: '100%', margin: '9px 0 32px 0', opacity: '0.6' }} />
      {!bandwidthData ? (
        <div className='show__element-monitoring' />
      ) : (
        <div className='show__element-monitoring'>
          {isLoadingData && <div>{t('metrics.LOADING')}</div>}
          <Recharts
            errorData={errorData}
            data={bandwidthData}
            refreshMetricsData={refreshMetricsData}
            minDate={deployedAt}
            bandwidth={bandwidth}
            defaultRangeState={defaultRangeState}
            setDefaultRangeState={setDefaultRangeState}
          />
        </div>
      )}
    </>
  )
}
