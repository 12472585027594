// -- NPM IMPORTS
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

// -- COMPONENT IMPORTS
import { ResourceType } from '../../slices/authorizationsApiSlice'
import Visible from '../permissions/Visible'

// -- ASSETS IMPORTS
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../../assets/icons-16/add.svg?react'
import './style.scss'

type PageHeaderProps = {
  addItemFor?: string
  permCreate?: [ResourceType, string, string]
  page: string
}

export default function PageHeaderV2({
  page,
  addItemFor,
  permCreate: [permCreateResource, permCreateResourceId, permCreateAction] = [
    ResourceType.All,
    '',
    '',
  ],
}: PageHeaderProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCreateElement = useCallback(() => {
    navigate('create')
  }, [navigate])

  return (
    <div className='page__header'>
      <div className='page__header__primary'>
        <div className='page__header__primary-content-infos-title'>
          {t(`page.${page}`)}
        </div>

        <Visible
          resource={permCreateResource}
          resourceId={permCreateResourceId}
          action={permCreateAction}>
          <div className='page__header__primary-button'>
            <Button
              variant='contained'
              startIcon={<AddIcon />}
              onClick={handleCreateElement}>
              {addItemFor}
            </Button>
          </div>
        </Visible>
      </div>
    </div>
  )
}
