import type { CardProps } from '@mui/material'
import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'

interface StyledLVCardProps extends CardProps {}

export const StyledLVCard = styled(Card)<StyledLVCardProps>(({ style }) => ({
  height: 200,
  borderWidth: '1px',
  borderStyle: 'solid',
  minHeight: '100%',
  borderRadius: 'var(--border-radius)',
  '& .MuiCardHeader-root': {
    padding: '6px 12px',
    borderBottom: '1px solid var(--border-primary)',
    '& .MuiCardHeader-title': {
      color: 'var(--content-primary)',
      fontSize: '1rem',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    '& .MuiCardHeader-subheader': {
      color: 'var(--content-secondary)',
      fontSize: '.8rem',
    },
  },
  '& .MuiAvatar-root': {
    width: 30,
    height: 30,
    padding: '3px',
    background: 'transparent',
  },
  '& .MuiCardContent-root': {
    paddingTop: '8px',
    borderTop: '1px solid var(--border-primary)',
  },
  ...style,
}))
