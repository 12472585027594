import IconAccess from '../assets/icons-io-v1/access.svg?react'
import IconCloud from '../assets/icons-io-v1/cloud.svg?react'
import IconPort from '../assets/icons-io-v1/port.svg?react'
import IconTransport from '../assets/icons-io-v1/transport.svg?react'

import Icon40AWS from '../assets/csp/AWS.png'
import Icon40Azure from '../assets/csp/Azure.png'
import Icon40GCP from '../assets/csp/GCP.png'
import Equinix from '../assets/csp/equinix.png'
import Megaport from '../assets/csp/megaport.png'

import InterCloud from '../assets/csp/intercloud-white.svg?react'
import IconAWS from '../assets/icons-40/icon-logo-aws.svg?react'
import IconAzure from '../assets/icons-40/icon-logo-azure.svg?react'
import IconGCP from '../assets/icons-40/icon-logo-gcp.svg?react'
import AttachmentIcon from '../assets/icons-io-v1/attachment.svg?react'
import type { Kind } from '../slices/types'

const DisplayElementIcon = ({
  kind,
  type,
  csp,
}: {
  kind: 'transport' | 'attachments' | 'node' | 'port' | string
  type?: 'cloud' | 'access' | string
  csp?: string
}) => {
  switch (kind) {
    case 'transport':
      return <IconTransport className='accordion__element-type--icon' />
    case 'attachments':
      return <AttachmentIcon className='accordion__element-type--icon' />
    case 'node':
      switch (type) {
        case 'cloud':
          switch (csp) {
            case 'AZURE':
              return (
                <img
                  src={Icon40Azure}
                  className='accordion__element-type--icon'
                  alt='Azure'
                />
              )
            case 'AWS':
              return (
                <img
                  src={Icon40AWS}
                  className='accordion__element-type--icon'
                  alt='AWS'
                />
              )
            case 'GCP':
              return (
                <img
                  src={Icon40GCP}
                  className='accordion__element-type--icon'
                  alt='GCP'
                />
              )
            default:
              return <IconCloud className='accordion__element-type--icon' />
          }

        case 'access':
          return <IconAccess className='accordion__element-type--icon' />
        default:
          break
      }
      break
    case 'port':
      return <IconPort className='accordion__element-type--icon' />
    default:
      break
  }
}

const displayProviderLogo = (provider: string = '', className = '') => {
  switch (provider.toLowerCase()) {
    case 'megaport':
      return <img src={Megaport} className={className} alt='Megaport' />
    case 'equinix':
      return <img src={Equinix} className={className} alt='Equinix' />
    case 'intercloud':
      return <InterCloud className={className} />
    default:
      break
  }
}

// THIS GENERATES THE ICONS IN THE LITTLE ATTACHMENT CARDS
const attachmentElementIcons = (
  kind: Kind,
  type: string = '',
  csp: string = ''
) => {
  switch (kind) {
    case 'transport':
      return <IconTransport className='accordion__open--attachments-icon' />

    case 'node':
      switch (type) {
        case 'cloud':
          switch (csp) {
            case 'AZURE':
              return <IconAzure className='accordion__open--attachments-icon' />
            case 'AWS':
              return <IconAWS className='accordion__open--attachments-icon' />
            case 'GCP':
              return <IconGCP className='accordion__open--attachments-icon' />
            default:
              return <IconCloud className='accordion__open--attachments-icon' />
          }
        case 'access':
          return <IconAccess className='accordion__open--attachments-icon' />
      }
      break
    case 'port':
      return <IconPort className='accordion__open--attachments-icon' />
  }
}

const displayCspWithLogo = (
  cspName: string,
  width = '24px',
  height = '24px',
  withName = true
) => {
  switch (cspName.toUpperCase()) {
    case 'AWS':
      return (
        <div className='menu-csp-logo'>
          <IconAWS {...{ width, height }} className='card-id-logo' />{' '}
          {withName && (
            <span className='resume-csp-direction-name'>{cspName}</span>
          )}
        </div>
      )
    case 'GCP':
      return (
        <div className='menu-csp-logo'>
          <IconGCP {...{ width, height }} className='card-id-logo' />{' '}
          {withName && (
            <span className='resume-csp-direction-name'>{cspName}</span>
          )}
        </div>
      )
    case 'AZURE':
      return (
        <div className='menu-csp-logo'>
          <IconAzure {...{ width, height }} className='card-id-logo' />{' '}
          {withName && (
            <span className='resume-csp-direction-name'>{cspName}</span>
          )}
        </div>
      )
    default:
      return (
        <div className='menu-csp-logo'>
          <span className='resume-csp-direction-name'>{cspName}</span>
        </div>
      )
  }
}

const smallElementTypeCard = (type: string, t) => {
  let icon: React.ReactNode
  let name: string
  switch (type) {
    case 'cloud':
      icon = <IconCloud />
      name = t('element.CLOUD_NODE')
      break
    case 'access':
      icon = <IconAccess />
      name = t('element.ACCESS_NODE')
      break
    case 'transport':
      icon = <IconTransport />
      name = t('element.TRANSPORT')
      break
    case 'ports':
      icon = <IconPort />
      name = t('element.PORT')
      break
  }

  return (
    <div className='element__type__card--small'>
      <span className='element__type__card--small-logo'>{icon}</span>
      <span className='element__type__card--small-type'>{name}</span>
    </div>
  )
}

const smallElementKindCard = (kind: string, type: string, t) => {
  let icon: React.ReactNode
  let name: string
  switch (kind) {
    case 'node':
      switch (type) {
        case 'cloud':
          icon = <IconCloud />
          name = t('element.CLOUD_NODE')
          break
        case 'access':
          icon = <IconAccess />
          name = t('element.ACCESS_NODE')
          break
      }
      break
    case 'transport':
      icon = <IconTransport />
      name = t('element.TRANSPORT')
      break
    case 'ports':
      icon = <IconPort />
      name = t('element.PORT')
      break
  }

  return (
    <div className='attachment__type__card--small'>
      <span className='attachment__type__card--small-logo'>{icon}</span>
      <span className='attachment__type__card--small-type'>{name}</span>
    </div>
  )
}

export {
  attachmentElementIcons,
  displayCspWithLogo,
  DisplayElementIcon,
  displayProviderLogo,
  smallElementKindCard,
  smallElementTypeCard,
}
