// -- Import NPM
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// -- Import Components
import useCurrentAccount from '../../../hooks/useCurrentAccount'
import { useLazyGetLOAQuery } from '../../../slices/portsApiSlice'

// Import Assets
import { StyleButton } from '../../../components/buttons/StyledButton'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import type { PortElementV2 } from '../../../slices/types'

type PortsSummaryProps = {
  data: PortElementV2['data']
}

export default function PortsDetailsV2({ data }: PortsSummaryProps) {
  const { t } = useTranslation()

  const { currentAccount } = useCurrentAccount()

  const [getLOA] = useLazyGetLOAQuery()
  const handleGetLoa = useCallback(
    (portId: string) => {
      getLOA({ accountId: currentAccount?.id, portId })
    },
    [currentAccount?.id, getLOA]
  )

  return (
    <>
      <div className='accordion__container-open'>
        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>
            {t('element.RESERVED_CAPACITY')}
          </div>
          <div className='accordion__open--details-info accordion__open--details-info-PORTS'>
            {humanizeBandwidth(
              data?.product?.bandwidth - data?.availableBandwidth
            )}
          </div>
        </div>

        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>
            {t('element.AVAILABLE_CAPACITY')}
          </div>
          <div className='accordion__open--details-info accordion__open--details-info-PORTS'>
            {humanizeBandwidth(data?.availableBandwidth)}
          </div>
        </div>

        <div className='accordion__container-open--details'>
          <div className='accordion__open--details-title'>LOA</div>
          <div className='accordion__open--details-info accordion__open--details-info-PORTS'>
            <StyleButton
              variant='contained'
              onClick={(e) => {
                e.stopPropagation()
                handleGetLoa(data?.id)
              }}>
              {t('element.DOWNLOAD')}
            </StyleButton>
          </div>
        </div>
      </div>
    </>
  )
}
