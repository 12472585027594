import type { SearchParams, SearchResponse } from 'meilisearch'
import MeiliSearch, { MeiliSearchApiError } from 'meilisearch'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { MEILISEARCH_URL } from '../environments/env.js'
import { useSelfQuery } from '../slices/authApiSlice.js'
import { selectCurrentToken } from '../slices/authSlice.js'
import type { AvailableProduct } from '../slices/types'

export enum ProductCatalog {
  Access = 'accessproduct',
  Cloud = 'cloudproduct',
  Transport = 'transportproduct',
  Port = 'portproduct',
}

const useMeiliSearch = (
  searchQuery: string,
  facetSearch: SearchParams,
  productCatalog: ProductCatalog
) => {
  const [isLoading, setIsLoading] = useState(false)
  const token = useSelector(selectCurrentToken)
  const { refetch, isFetching } = useSelfQuery()
  const [error, setError] = useState(null)
  const [data, setData] = useState<
    SearchResponse<AvailableProduct, SearchParams>
  >({
    hits: [],
    facetDistribution: {},
    processingTimeMs: null,
    query: '',
  })

  const client = useRef<MeiliSearch>(null)
  useEffect(() => {
    client.current = new MeiliSearch({
      host: `${MEILISEARCH_URL}`,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    })
  }, [token])

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!client.current) {
        return
      }
      setIsLoading(true)
      setError(null)
      try {
        const index = client.current.index<AvailableProduct>(productCatalog)
        const response = await index.search(searchQuery, facetSearch)
        setData(response)
      } catch (error) {
        // if typeof error
        if (error instanceof MeiliSearchApiError && !isFetching) {
          refetch()
        }
        setError(error)
        console.error('Error searching:', typeof error, error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchSearchResults()
  }, [searchQuery, facetSearch, productCatalog, token, isFetching, refetch])

  return { data, isLoading, isError: !!error }
}

export default useMeiliSearch
