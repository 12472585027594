// IMPORT DEPENDENCIES
import { MenuItem } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useMeiliSearch, { ProductCatalog } from '../../../hooks/useMeiliSearch'

// ASSETS
import { displayProviderLogo } from '../../../hooks/displayIcons'

// ICONS
import SortIcon from '../../../assets/icons-io-v1/arrow_swap_vertical.svg?react'

// TYPES
import type { SearchParams } from 'meilisearch/dist/types/types'
import { useNavigate } from 'react-router-dom'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import type {
  AvailableProduct,
  TransportQueryParams,
} from '../../../slices/types'
import SelectedFilter from '../../fields/SelectedFilter'
import {
  StyledActionButton,
  StyledTextField,
  selectProps,
} from '../../forms/StyledFormComponents'
import type { CreationState } from '../DrawerCreateElementV2'

type ConfigureTransportProductProps = {
  newElementData: CreationState
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  setCreationStep: Dispatch<SetStateAction<number>>
}

interface formInput {
  name: string
  provider: string
  location: string
  locationTo: string
  bandwidth: string
}
interface ProductCardProps {
  result: AvailableProduct
  activeRadio: number
  setActiveRadio: Dispatch<SetStateAction<number>>
}

const ProductCard: React.FC<ProductCardProps> = ({
  result,
  activeRadio,
  setActiveRadio,
}) => {
  const handleSetActiveRadio = useCallback(() => {
    setActiveRadio(result.id)
  }, [setActiveRadio, result.id])

  return (
    <div
      key={result.id}
      className='drawer-products__card'
      onClick={handleSetActiveRadio}>
      <div className='drawer-products__card--radio'>
        <input
          type='radio'
          value={result.id}
          checked={result.id === activeRadio ? true : false}
        />
      </div>
      <div className='drawer-products__card--col'>
        <div className='drawer-products__card--provider'>
          {displayProviderLogo(result?.provider)}
        </div>
      </div>
      <div className='drawer-products__card--col'>{result?.location}</div>
      <div className='drawer-products__card--col'>{result?.locationTo}</div>
      <div className='drawer-products__card--col'>
        {humanizeBandwidth(result?.bandwidth)}
      </div>

      <div className='drawer-products__card--col'>
        {result?.priceMrc}
        {' €'}
      </div>
    </div>
  )
}

export default function ConfigureTransportProduct({
  newElementData,
  setNewElementData,
  setCreationStep,
}: ConfigureTransportProductProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeRadio, setActiveRadio] = useState<number>(null)
  const { register, reset, control } = useForm<formInput>({
    shouldUseNativeValidation: true,
  })

  const [transportQueryParams, setTransportQueryParams] =
    useState<TransportQueryParams>({})
  // MEILI SEARCH CONFIG
  const searchQuery: string = ''
  const [facetSearch, setFacetSearch] = useState<SearchParams>({
    filter: [],
    facets: ['location', 'locationTo', 'bandwidth', 'provider'],
  })

  const meiliSearchResponse = useMeiliSearch(
    searchQuery,
    facetSearch,
    ProductCatalog.Transport
  )
  const {
    data: { hits: searchResults, facetDistribution: facetDistri },
  } = meiliSearchResponse

  useEffect(() => {
    const entries = Object.entries(transportQueryParams ?? {})

    let filterOr: string = ''
    if (transportQueryParams.location) {
      filterOr += transportQueryParams.locationTo
        ? `(location = "${transportQueryParams.location}" AND locationTo = "${transportQueryParams.locationTo}")`
        : `(location = "${transportQueryParams.location}" OR locationTo = "${transportQueryParams.location}")`
    }
    if (transportQueryParams.locationTo) {
      filterOr += transportQueryParams.location
        ? ` OR (location = "${transportQueryParams.locationTo}" AND locationTo = "${transportQueryParams.location}")`
        : `(location = "${transportQueryParams.locationTo}" OR locationTo = "${transportQueryParams.locationTo}")`
    }
    filterOr = filterOr ? `(${filterOr})` : ''

    const filter = entries
      .filter(([key]) => {
        return key !== 'location' && key !== 'locationTo'
      })
      .map(([key, val]) => {
        return `${key} = "${val}"`
      })
      .concat([filterOr])
      .filter((val) => !!val)
      .join(' AND ')

    setFacetSearch((prevFacetSearch) => ({
      ...prevFacetSearch,
      filter,
    }))
  }, [transportQueryParams])

  const handleChange = useCallback(
    (name: string, value: string) => {
      setNewElementData((previous) => ({ ...previous, [name]: value }))
      setTransportQueryParams((previous) => ({
        ...previous,
        [name]: value,
      }))
    },
    [setNewElementData]
  )

  const handleClearFacet = useCallback(
    (facet: string) => {
      setNewElementData((previous) => ({ ...previous, [facet]: '' }))
      setTransportQueryParams((previous) => {
        const { [facet]: _, ...transportQueryParamsBis } = previous
        return {
          ...transportQueryParamsBis,
        }
      })
      reset({ [facet]: '' })
    },
    [reset, setNewElementData]
  )

  // ----------------------------------------------
  // ----- LOGIQUE TO SORT THE DATA BY COLUMN -----
  const [mySortedData, setMySortedData] = useState(searchResults)
  const [mySortedFilter, setMySortedFilter] = useState('')
  const [sortAscDesc, setSortAscDesc] = useState(true)
  useEffect(() => {
    setMySortedData(searchResults)
  }, [searchResults])

  const sortMyData = (sortBy: string) => {
    setMySortedFilter(sortBy)
    setSortAscDesc(!sortAscDesc)
    if (sortBy === 'priceMrc' || sortBy === 'bandwidth') {
      if (sortAscDesc) {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => b[sortBy] - a[sortBy])
        )
      } else {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => a[sortBy] - b[sortBy])
        )
      }
    } else {
      if (sortAscDesc) {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
        )
      } else {
        setMySortedData(
          mySortedData &&
            [...mySortedData].sort((a, b) => b[sortBy].localeCompare(a[sortBy]))
        )
      }
    }
  }

  // --------------------------------------------------------------
  // ----- HANDLE THE ADDITION OF THE PRODUCT AND REDIRECTION -----
  const handleAddProduct = useCallback(() => {
    const product = mySortedData.find((data) => data.id === activeRadio)
    setNewElementData((previous) => ({
      ...previous,
      elementProduct: product,
      bandwidth: '',
      provider: '',
      location: '',
      locationTo: '',
    }))
    setCreationStep(1)
    navigate(-1)
  }, [activeRadio, mySortedData, navigate, setCreationStep, setNewElementData])

  const facetLocationsConcatenation = () => {
    // Concatenate and remove duplicates using Set
    const location = facetDistri?.location
    const locationTo = facetDistri?.locationTo
    const uniqueLocations = { ...location, ...locationTo }
    return uniqueLocations
  }

  return (
    <>
      {/* ----- FILTERS ----- */}
      <>
        <div className='drawer-create__containers'>
          <>
            <div className='drawer-create__titles'>
              <span>{t('element.FILTERS')} :</span>
            </div>

            <div className='drawer-create__columns-filters'>
              {newElementData?.provider === '' ? (
                <div className='drawer-create__columns-filter'>
                  <Controller
                    name='provider'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        select
                        label={t('element.PROVIDER')}
                        size='small'
                        slotProps={{
                          select: selectProps,
                        }}
                        {...register('provider')}>
                        {facetDistri?.provider !== undefined ? (
                          Object.keys(facetDistri?.provider).map((provider) => {
                            return (
                              <MenuItem
                                key={provider}
                                value={provider}
                                onClick={() =>
                                  handleChange('provider', provider)
                                }>
                                {provider}
                              </MenuItem>
                            )
                          })
                        ) : (
                          <div />
                        )}
                      </StyledTextField>
                    )}
                  />
                </div>
              ) : (
                <SelectedFilter
                  newElementData={newElementData}
                  handleClearFacet={handleClearFacet}
                  legend={t('element.PROVIDER')}
                />
              )}

              {newElementData?.location === '' ? (
                <div className='drawer-create__columns-filter'>
                  <Controller
                    name='location'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        select
                        label={t('element.LOCATION_1')}
                        size='small'
                        slotProps={{
                          select: selectProps,
                        }}
                        {...register('location')}>
                        {facetLocationsConcatenation() !== undefined ? (
                          Object.keys(facetLocationsConcatenation()).map(
                            (location) => {
                              return (
                                <MenuItem
                                  key={location}
                                  value={location}
                                  onClick={() =>
                                    handleChange('location', location)
                                  }>
                                  {location}
                                </MenuItem>
                              )
                            }
                          )
                        ) : (
                          <div />
                        )}
                      </StyledTextField>
                    )}
                  />
                </div>
              ) : (
                <SelectedFilter
                  newElementData={newElementData}
                  handleClearFacet={handleClearFacet}
                  legend={t('element.LOCATION_1')}
                />
              )}

              {newElementData?.locationTo === '' ? (
                <div className='drawer-create__columns-filter'>
                  <Controller
                    name='locationTo'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        select
                        label={t('element.LOCATION_2')}
                        size='small'
                        slotProps={{
                          select: selectProps,
                        }}
                        {...register('locationTo')}>
                        {facetLocationsConcatenation() !== undefined ? (
                          Object.keys(facetLocationsConcatenation()).map(
                            (locationTo) => {
                              return (
                                <MenuItem
                                  key={locationTo}
                                  value={locationTo}
                                  onClick={() =>
                                    handleChange('locationTo', locationTo)
                                  }>
                                  {locationTo}
                                </MenuItem>
                              )
                            }
                          )
                        ) : (
                          <div />
                        )}
                      </StyledTextField>
                    )}
                  />
                </div>
              ) : (
                <SelectedFilter
                  newElementData={newElementData}
                  handleClearFacet={handleClearFacet}
                  legend={t('element.LOCATION_2')}
                />
              )}

              {newElementData?.bandwidth === '' ? (
                <div className='drawer-create__columns-filter'>
                  <Controller
                    name='bandwidth'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        select
                        label={t('element.BANDWIDTH')}
                        size='small'
                        slotProps={{
                          select: selectProps,
                        }}
                        {...register('bandwidth')}>
                        {facetDistri?.bandwidth !== undefined ? (
                          Object.keys(facetDistri?.bandwidth).map(
                            (bandwidth) => {
                              return (
                                <MenuItem
                                  key={bandwidth}
                                  value={bandwidth}
                                  onClick={() =>
                                    handleChange('bandwidth', bandwidth)
                                  }>
                                  {bandwidth}
                                </MenuItem>
                              )
                            }
                          )
                        ) : (
                          <div />
                        )}
                      </StyledTextField>
                    )}
                  />
                </div>
              ) : (
                <SelectedFilter
                  newElementData={newElementData}
                  handleClearFacet={handleClearFacet}
                  legend={t('element.BANDWIDTH')}
                />
              )}
            </div>
          </>
        </div>
      </>

      {/* ----- FILTERED RESULTS ----- */}
      <div
        className={`drawer-products__containers ${
          Object.keys(transportQueryParams).length >= 1
            ? 'drawer-products__containers--border-bottom'
            : ''
        }`}>
        {Object?.keys(transportQueryParams).length >= 1 ? (
          <>
            <div className='drawer-products__card header'>
              <div className='drawer-products__card--radio' />
              <div className='drawer-products__card--header'>
                {t('element.PROVIDER')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'provider'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('provider')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.LOCATION_1')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'location'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('location')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.LOCATION_2')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'locationTo'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('locationTo')}
                />
              </div>
              <div className='drawer-products__card--header'>
                {t('element.BANDWIDTH')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'bandwidth'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('bandwidth')}
                />
              </div>

              <div className='drawer-products__card--header'>
                {t('element.MRC')}
                <SortIcon
                  style={
                    mySortedFilter && mySortedFilter === 'priceMrc'
                      ? { color: 'var(--content-active)' }
                      : { color: 'var(--content-primary)' }
                  }
                  onClick={() => sortMyData('priceMrc')}
                />
              </div>
            </div>
            {mySortedData.map((result) => {
              return (
                <ProductCard
                  result={result}
                  activeRadio={activeRadio}
                  setActiveRadio={setActiveRadio}
                />
              )
            })}
          </>
        ) : (
          <div className='drawer-products__filters-message'>
            Please select a filter.
          </div>
        )}

        {Object?.keys(transportQueryParams).length >= 1 && (
          <div className='drawer-products__footer'>
            <div> {/* Placeholder for infinite scrool*/}</div>
            <div>
              <StyledActionButton
                variant='contained'
                disabled={!activeRadio}
                onClick={handleAddProduct}>
                Add
              </StyledActionButton>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
