import { t } from 'i18next'

interface Props {
  price: number
}
export default function FormatMonthlyPrice({ price }: Props) {
  return (
    <span>
      {price}€ / {t('element.MONTH')}
    </span>
  )
}
