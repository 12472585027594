// -- Import NPM
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

// -- ASSETS IMPORTS
import { useMemo } from 'react'
import type { OperationalStatus } from '../../slices/types'
import './style.scss'

type OperationalStatusProps = {
  status?: OperationalStatus
  showStatusText?: boolean
}

const StyledStateChip = styled(Chip)(({ theme }) => ({
  height: '12px',
  width: '12px',
}))

function OperationalStatusCmp({
  status,
  showStatusText,
}: OperationalStatusProps) {
  const { t } = useTranslation()

  const infosStatus = useMemo<
    Record<
      OperationalStatus,
      { className: string; backgroundColor: string; text: string }
    >
  >(
    () => ({
      up: {
        backgroundColor: 'var(--chips-deployed-full)',
        className: 'operational-state-text-up',
        text: t('metrics.UP'),
      },
      down: {
        backgroundColor: 'var(--chips-failed-full)',
        className: 'operational-state-text-down',
        text: t('metrics.DOWN'),
      },
      unknown: {
        backgroundColor: 'var(--chips-draft-full)',
        className: 'operational-state-text-no-data',
        text: t('metrics.NO_DATA'),
      },
    }),
    [t]
  )

  const { backgroundColor, text, className } =
    infosStatus[status] ?? infosStatus['unknown']

  return (
    <div className='operational-state'>
      <StyledStateChip
        label=''
        size='small'
        sx={{
          backgroundColor,
        }}
      />
      {showStatusText && <span className={className}>{text}</span>}
    </div>
  )
}

export default OperationalStatusCmp
