const EUROPE_MAP = {
  type: 'Topology',
  arcs: [
    [
      [6214, 2041],
      [61, -89],
      [9, -89],
    ],
    [
      [6284, 1863],
      [-17, -85],
      [5, -61],
      [31, -101],
      [37, -23],
    ],
    [
      [6340, 1593],
      [10, -56],
      [-29, -46],
      [-26, -99],
      [-30, -7],
      [-21, -95],
      [-39, -3],
    ],
    [
      [6205, 1287],
      [-21, 94],
      [-53, 44],
      [1, 63],
      [-24, 50],
      [28, 157],
      [-14, 39],
      [24, 115],
      [-30, 9],
    ],
    [
      [6116, 1858],
      [-13, 86],
      [46, 107],
      [65, -10],
    ],
    [
      [3637, 2029],
      [-39, 25],
    ],
    [
      [3598, 2054],
      [39, -25],
    ],
    [
      [5776, 3642],
      [-14, -65],
      [42, -94],
    ],
    [
      [5804, 3483],
      [-13, -80],
      [-36, -7],
      [-52, -85],
      [2, -85],
      [-49, -45],
    ],
    [
      [5656, 3181],
      [-17, -31],
      [-71, -34],
      [-57, 9],
      [-78, -61],
      [-113, 27],
    ],
    [
      [5320, 3091],
      [-66, 8],
      [-116, 37],
      [-41, 80],
      [-134, -10],
      [-22, -49],
      [-76, 25],
    ],
    [
      [4865, 3182],
      [-49, -5],
      [-74, 56],
    ],
    [
      [4742, 3233],
      [-8, 54],
    ],
    [
      [4734, 3287],
      [3, 72],
    ],
    [
      [4737, 3359],
      [57, -3],
      [50, -58],
      [42, 68],
      [67, -45],
      [75, 53],
      [77, 3],
      [36, 24],
      [91, -19],
      [-46, 132],
      [136, 106],
      [14, 64],
    ],
    [
      [5336, 3684],
      [37, -46],
      [87, 0],
      [31, 45],
      [9, 65],
      [50, -8],
      [105, -62],
      [49, 14],
      [72, -50],
    ],
    [
      [4224, 4209],
      [-22, 2],
    ],
    [
      [4202, 4211],
      [22, -2],
    ],
    [
      [4191, 4224],
      [-32, -23],
      [-95, 23],
      [-72, -31],
      [-79, 21],
      [-72, -5],
    ],
    [
      [3841, 4209],
      [-20, 4],
    ],
    [
      [3821, 4213],
      [-22, -18],
    ],
    [
      [3799, 4195],
      [-38, 38],
      [-10, 65],
    ],
    [
      [3751, 4298],
      [116, 76],
    ],
    [
      [3867, 4374],
      [81, -45],
      [42, 43],
    ],
    [
      [3990, 4372],
      [5, 0],
    ],
    [
      [3995, 4372],
      [16, 20],
      [97, -18],
      [109, -63],
      [-26, -87],
    ],
    [
      [7409, 2357],
      [-17, -96],
      [-51, -4],
      [-29, -94],
      [1, -79],
      [-54, -41],
      [72, -148],
      [-69, -23],
      [-36, 49],
      [-91, -33],
      [-41, -67],
    ],
    [
      [7094, 1821],
      [-30, -94],
      [-117, -31],
      [-106, 81],
      [-68, -6],
      [-61, -41],
      [-98, -9],
    ],
    [
      [6614, 1721],
      [13, 107],
      [-23, 72],
      [-70, 79],
    ],
    [
      [6534, 1979],
      [23, 148],
      [60, 56],
      [-19, 65],
      [-40, 38],
      [-23, 88],
      [7, 49],
      [41, 62],
    ],
    [
      [6583, 2485],
      [32, -104],
      [57, 14],
      [117, -35],
      [47, 12],
      [121, -38],
      [59, 20],
      [52, 74],
      [123, 44],
      [50, -43],
      [78, -13],
      [40, -54],
      [50, -5],
    ],
    [
      [6042, 2657],
      [-50, 50],
    ],
    [
      [5992, 2707],
      [50, -50],
    ],
    [
      [5987, 2712],
      [-43, 3],
    ],
    [
      [5944, 2715],
      [43, -3],
    ],
    [
      [5692, 2695],
      [50, -44],
      [25, -90],
      [-12, -60],
      [44, -35],
      [6, 80],
      [81, -22],
      [15, 70],
      [92, 71],
      [36, -17],
      [-2, -191],
      [-32, -34],
      [-16, -146],
      [-51, 2],
      [2, -148],
      [46, -83],
    ],
    [
      [5976, 2048],
      [-101, 84],
    ],
    [
      [5875, 2132],
      [-10, 14],
    ],
    [
      [5865, 2146],
      [-70, 150],
      [-122, 184],
      [-14, 83],
      [-56, 70],
      [9, 101],
      [32, 13],
      [48, -52],
    ],
    [
      [5987, 2712],
      [5, -5],
    ],
    [
      [6042, 2657],
      [25, -3],
    ],
    [
      [6067, 2654],
      [47, 8],
    ],
    [
      [6114, 2662],
      [-31, -100],
      [6, -61],
      [62, -66],
      [-59, -133],
    ],
    [
      [6092, 2302],
      [-40, -7],
      [-64, -136],
      [-3, -115],
    ],
    [
      [5985, 2044],
      [-9, 4],
    ],
    [
      [5692, 2695],
      [19, 47],
      [63, 21],
      [36, -34],
      [91, -23],
      [43, 9],
    ],
    [
      [7349, 5633],
      [172, -49],
      [12, -71],
      [106, 45],
      [98, -75],
      [-17, -76],
      [30, -64],
      [-33, -68],
      [56, -43],
      [22, -104],
      [59, -38],
      [1, -70],
      [102, -42],
      [33, -59],
      [-72, -89],
      [-116, 21],
      [-18, -44],
      [36, -44],
      [14, -126],
      [22, -72],
    ],
    [
      [7856, 4565],
      [-115, -21],
      [-39, -53],
      [-44, -136],
      [-29, 48],
      [-74, 0],
      [-45, -30],
      [-19, 61],
      [-115, -21],
      [-153, 19],
      [-56, 41],
      [-153, 47],
      [-82, 6],
      [-111, -19],
      [-57, -77],
      [-53, -19],
    ],
    [
      [6711, 4411],
      [4, 150],
      [-66, 54],
      [32, 59],
      [67, 42],
      [3, 127],
      [-42, 122],
      [-15, 86],
    ],
    [
      [6694, 5051],
      [188, 22],
      [99, 86],
      [36, 138],
      [50, 26],
      [64, 185],
    ],
    [
      [7131, 5508],
      [54, 42],
      [86, -9],
      [44, 81],
      [34, 11],
    ],
    [
      [4191, 4224],
      [11, -13],
    ],
    [
      [4224, 4209],
      [15, 0],
    ],
    [
      [4239, 4209],
      [48, -101],
      [-31, -66],
    ],
    [
      [4256, 4042],
      [-57, -63],
      [11, -91],
    ],
    [
      [4210, 3888],
      [-55, 17],
      [-86, 99],
      [-49, -11],
      [-58, 107],
      [-38, 2],
      [-68, 48],
      [-15, 59],
    ],
    [
      [3821, 4213],
      [-22, -18],
    ],
    [
      [4734, 3287],
      [8, -54],
    ],
    [
      [4865, 3182],
      [-23, -83],
      [-55, -47],
      [-54, -17],
      [-20, 42],
      [-55, -131],
      [-78, 74],
      [-84, -89],
      [-40, 18],
      [-73, -15],
    ],
    [
      [4383, 2934],
      [-37, 133],
      [-88, 44],
      [42, 44],
      [86, 153],
      [76, 64],
    ],
    [
      [4462, 3372],
      [70, -4],
      [66, 61],
      [49, -39],
      [90, -31],
    ],
    [
      [5476, 4237],
      [59, 32],
      [17, -51],
      [138, -37],
      [-17, -51],
      [57, -91],
      [80, 71],
      [91, -106],
      [36, 11],
      [65, -29],
      [39, -105],
    ],
    [
      [6041, 3881],
      [-42, -7],
      [-48, -55],
      [-13, -48],
      [-79, -74],
      [-52, 12],
      [-31, -67],
    ],
    [
      [5336, 3684],
      [-60, 60],
      [-54, 80],
      [-28, 9],
      [-59, 109],
      [-19, 80],
      [13, 57],
      [177, 120],
      [110, 55],
      [60, -17],
    ],
    [
      [5392, 5051],
      [-1, -16],
    ],
    [
      [5391, 5035],
      [1, 16],
    ],
    [
      [4722, 5281],
      [75, -12],
      [7, -57],
      [106, -61],
      [42, 7],
      [14, -86],
      [40, -27],
      [28, 60],
      [63, 11],
      [55, 77],
      [137, -93],
      [29, 12],
      [42, -104],
      [39, -20],
    ],
    [
      [5399, 4988],
      [21, -113],
      [-40, -111],
      [73, -73],
      [-15, -41],
      [31, -91],
      [-24, -72],
      [20, -76],
      [41, -65],
      [-30, -109],
    ],
    [
      [4462, 3372],
      [-2, 135],
      [33, 137],
      [54, 93],
      [-189, 65],
      [-70, 65],
    ],
    [
      [4288, 3867],
      [21, 89],
      [-38, 25],
      [-15, 61],
    ],
    [
      [4239, 4209],
      [-2, 85],
      [29, 115],
      [-37, 79],
      [111, 24],
      [46, 123],
      [-1, 75],
      [22, 158],
    ],
    [
      [4407, 4868],
      [-24, 54],
      [28, 57],
      [102, 15],
      [31, -45],
      [97, 74],
      [22, 70],
      [-55, 77],
      [54, 31],
      [-49, 103],
    ],
    [
      [4613, 5304],
      [109, -23],
    ],
    [
      [4977, 5320],
      [66, -22],
      [-38, -65],
      [-59, 35],
      [31, 52],
    ],
    [
      [4848, 5493],
      [69, -80],
      [-13, -62],
      [-59, -3],
      [-58, 45],
      [-2, 73],
      [63, 27],
    ],
    [
      [5128, 5629],
      [38, -110],
      [-50, -43],
      [21, -77],
      [-49, -30],
      [-112, 19],
      [-25, 122],
      [80, 71],
      [24, -70],
      [50, 112],
      [23, 6],
    ],
    [
      [4613, 5304],
      [-6, 143],
      [-46, 39],
      [-26, 141],
      [2, 115],
      [67, -14],
      [72, 142],
      [42, 34],
      [78, 112],
      [65, -5],
      [15, -88],
      [-38, -87],
      [13, -92],
      [85, -30],
      [-30, -61],
      [-52, -4],
      [-19, -76],
      [-92, -128],
      [-13, -71],
      [34, -47],
      [-42, -46],
    ],
    [
      [3023, 4438],
      [1, 50],
      [-66, 71],
      [17, 48],
      [-29, 43],
      [50, 159],
      [-32, 62],
    ],
    [
      [2964, 4871],
      [9, 178],
      [-86, 154],
      [34, 98],
      [48, 26],
    ],
    [
      [2969, 5327],
      [82, 100],
      [62, 117],
    ],
    [
      [3113, 5544],
      [55, -58],
      [15, -110],
      [35, -111],
      [100, -72],
      [98, -220],
      [27, -147],
      [-43, -46],
      [50, -32],
      [27, 47],
      [99, -11],
      [52, -40],
      [18, -77],
      [-27, -107],
      [-88, -122],
      [3, -71],
      [63, 11],
      [-10, -69],
      [-152, -101],
      [-62, 21],
      [-90, -24],
      [-71, 17],
      [-89, -23],
      [-5, -31],
      [-123, 36],
      [-85, -39],
      [-25, -95],
      [-80, 50],
      [-75, -22],
      [-65, -96],
      [-31, 60],
      [90, 106],
      [90, 163],
      [154, -1],
      [55, 108],
    ],
    [
      [3843, 1360],
      [41, -61],
      [-35, -100],
      [-22, -24],
      [-80, 53],
      [-19, 38],
      [81, 80],
      [34, 14],
    ],
    [
      [6575, 6280],
      [73, -30],
      [-61, -66],
      [-73, -21],
      [-47, 31],
      [64, 85],
      [44, 1],
    ],
    [
      [7330, 6516],
      [-45, -134],
      [-40, -58],
      [12, -141],
      [25, -82],
      [-45, -102],
    ],
    [
      [7237, 5999],
      [-57, 24],
      [-63, -27],
      [-68, 72],
    ],
    [
      [7049, 6068],
      [0, 8],
    ],
    [
      [7049, 6076],
      [-119, 64],
      [-121, -51],
    ],
    [
      [6809, 6089],
      [32, 128],
      [-59, -29],
      [-52, 27],
      [-31, 69],
      [-7, 161],
      [87, 26],
      [43, 44],
      [112, 12],
      [69, 28],
      [143, -23],
      [33, -25],
      [131, -11],
      [20, 20],
    ],
    [
      [3145, 2263],
      [55, -38],
      [18, -52],
      [72, -26],
      [28, -46],
      [36, 15],
      [37, -38],
      [91, 0],
      [7, 40],
      [93, -33],
      [16, -31],
    ],
    [
      [3637, 2029],
      [48, -39],
      [58, -3],
      [66, 34],
      [35, -10],
    ],
    [
      [3844, 2011],
      [-5, -155],
      [-120, -101],
      [-33, -50],
      [-150, -62],
      [-166, -350],
      [-18, -53],
      [15, -115],
      [63, -85],
      [-81, -78],
      [-38, -75],
      [-44, -165],
      [-45, 7],
      [-60, -58],
      [-32, -109],
      [-32, -56],
      [-33, 26],
      [-55, -41],
      [-25, 19],
      [-210, -11],
      [-41, -57],
      [-65, -22],
      [-22, -71],
    ],
    [
      [2647, 349],
      [-2, 0],
    ],
    [
      [2645, 349],
      [-36, -36],
      [-60, 48],
      [-65, 204],
      [-74, 65],
      [-54, -4],
    ],
    [
      [2356, 626],
      [-16, 99],
      [37, 101],
      [34, 20],
      [-47, 112],
      [12, 77],
      [42, 73],
      [-27, 26],
      [-21, 91],
      [-34, 59],
      [73, 2],
      [22, 85],
      [-23, 45],
      [26, 69],
      [6, 162],
      [86, 136],
      [-49, 28],
      [-15, 68],
      [-68, 14],
      [-15, -32],
      [-66, 9],
      [-46, -22],
      [-24, 89],
      [-75, -49],
    ],
    [
      [2168, 1888],
      [-6, 77],
      [-64, 200],
      [5, 37],
      [100, 46],
      [28, 57],
      [60, 58],
      [72, -22],
      [19, -30],
      [141, 7],
      [112, -9],
      [146, -47],
      [117, 35],
      [62, -43],
      [50, 26],
      [55, -36],
      [80, 19],
    ],
    [
      [7462, 9038],
      [-71, -135],
      [30, -83],
      [89, -32],
      [100, -105],
      [-13, -46],
      [-115, -137],
      [-1, -41],
      [114, -192],
      [29, -105],
      [-57, -22],
      [16, -141],
      [-32, -27],
      [71, -167],
      [65, -37],
      [-7, -61],
      [-74, -66],
      [71, -74],
      [104, -70],
      [48, -76],
      [-43, -101],
      [-88, -90],
      [-201, -248],
      [-57, -39],
      [-139, -144],
    ],
    [
      [7301, 6799],
      [-49, -24],
      [-57, 22],
      [-51, -34],
      [-84, 2],
      [-50, -47],
      [-361, -95],
      [-75, 129],
      [-79, 16],
      [-84, 45],
      [-19, 105],
      [41, 143],
      [-55, 116],
      [15, 98],
      [-32, 113],
      [38, 24],
      [32, 87],
      [107, 31],
      [17, 80],
      [123, 85],
      [197, 243],
      [94, 27],
      [-13, 137],
      [-167, 89],
    ],
    [
      [6789, 8191],
      [-61, 102],
      [-12, 60],
      [50, 100],
      [-59, 90],
      [26, 70],
      [-37, 122],
      [-63, 110],
      [-137, 48],
      [-204, 147],
    ],
    [
      [6292, 9040],
      [97, 68],
      [49, -8],
      [131, -142],
      [85, -28],
      [102, 52],
      [167, -47],
      [75, 65],
      [54, 215],
      [65, 58],
      [114, 3],
      [82, 38],
      [72, -71],
      [102, -34],
      [30, -56],
      [-68, -65],
      [13, -50],
    ],
    [
      [4724, 2077],
      [14, -151],
      [-46, -195],
      [-63, 58],
      [4, 83],
      [-34, 71],
      [15, 88],
      [61, 57],
      [49, -11],
    ],
    [
      [4210, 3888],
      [78, -21],
    ],
    [
      [4383, 2934],
      [-31, -26],
      [36, -161],
      [-64, -30],
      [58, -97],
      [-25, -62],
      [27, -69],
      [93, -42],
      [-27, -77],
    ],
    [
      [4450, 2370],
      [-9, -13],
    ],
    [
      [4441, 2357],
      [-10, -5],
    ],
    [
      [4431, 2352],
      [-89, -83],
      [-7, -55],
      [-104, -34],
      [-85, 69],
      [-108, 10],
      [-88, 43],
      [-78, -66],
      [-48, -90],
      [1, -103],
      [19, -32],
    ],
    [
      [3145, 2263],
      [45, 51],
      [32, 244],
      [15, 261],
      [-8, 58],
      [21, 87],
      [-19, 50],
      [-87, 73],
      [-45, 83],
      [17, 66],
      [-78, 83],
      [-28, 68],
      [-45, -17],
      [-38, 38],
      [-153, 68],
      [-3, 72],
      [-46, 33],
      [9, 51],
      [53, 29],
      [55, -2],
      [123, 42],
      [62, -77],
      [43, 38],
      [105, -10],
      [-4, 151],
      [-39, 84],
      [50, 33],
      [64, -69],
      [120, -31],
      [67, 49],
      [-3, 70],
      [132, 61],
      [49, 67],
      [8, 173],
      [132, 58],
    ],
    [
      [2647, 349],
      [-2, 0],
    ],
    [
      [6752, 186],
      [62, -47],
      [53, 21],
      [47, -19],
      [142, -32],
      [25, -52],
      [-211, -28],
      [-4, 43],
      [-43, 26],
      [-115, 12],
      [-1, 69],
      [45, 7],
    ],
    [
      [7361, 426],
      [-15, -66],
      [-49, -78],
      [-11, 72],
      [28, 47],
      [47, 25],
    ],
    [
      [6688, 1077],
      [58, -57],
      [31, -1],
      [-4, -75],
      [-58, 35],
      [-61, 80],
      [34, 18],
    ],
    [
      [7105, 1189],
      [28, -72],
      [-26, -22],
      [-76, 47],
      [-4, 30],
      [78, 17],
    ],
    [
      [7094, 1821],
      [37, -31],
      [5, -58],
      [-42, -32],
      [3, -77],
      [-31, -52],
      [-117, 49],
      [-70, -26],
      [-33, 26],
      [-64, -59],
      [-43, 13],
      [9, -97],
      [-80, -47],
      [-83, 73],
      [-19, -120],
      [40, -75],
      [23, -120],
      [-5, -96],
      [-31, -45],
      [62, -27],
      [71, -91],
      [33, -12],
      [9, -106],
      [-15, -29],
      [-64, 63],
      [-38, -56],
      [-7, -95],
      [-52, -13],
      [50, -163],
      [-49, 6],
      [-55, -35],
      [-31, 92],
      [-63, -54],
      [-19, 90],
      [16, 34],
      [-19, 73],
      [-60, 73],
      [29, 48],
      [88, 84],
      [128, -105],
      [34, 71],
      [-157, 53],
      [-117, -28],
      [-18, 81],
      [-48, 122],
      [-55, 69],
      [-41, 95],
    ],
    [
      [6340, 1593],
      [87, 4],
      [46, 61],
      [115, 18],
      [26, 45],
    ],
    [
      [5985, 2044],
      [9, -37],
    ],
    [
      [5994, 2007],
      [-40, 54],
      [-79, 71],
    ],
    [
      [5715, 3086],
      [46, -32],
      [71, -112],
      [76, -43],
      [74, -13],
      [68, 49],
    ],
    [
      [6050, 2935],
      [2, -82],
      [35, -88],
      [-20, -111],
    ],
    [
      [5865, 2146],
      [-98, 122],
      [-63, 38],
      [-59, -12],
      [-12, 46],
      [-65, 64],
      [-45, 73],
      [23, 42],
      [-57, 88],
      [-10, 112],
      [-70, 64],
      [-50, -134],
      [-47, 59],
      [-7, 111],
    ],
    [
      [5305, 2819],
      [42, -18],
      [109, 29],
      [91, -22],
      [-2, 78],
      [51, 23],
      [0, 101],
      [119, 76],
    ],
    [
      [6504, 3589],
      [105, -121],
    ],
    [
      [6609, 3468],
      [-45, -50],
      [-40, -9],
      [-90, -185],
      [-21, -89],
      [-45, -97],
      [-70, -48],
      [-59, -8],
    ],
    [
      [6239, 2982],
      [-97, 15],
      [-41, -48],
      [-51, -14],
    ],
    [
      [5715, 3086],
      [-34, 96],
      [-25, -1],
    ],
    [
      [5804, 3483],
      [95, -67],
      [133, 19],
      [10, 58],
      [146, 23],
      [55, 33],
      [29, 72],
      [88, -10],
      [45, 19],
      [42, -58],
      [57, 17],
    ],
    [
      [2380, 5350],
      [-43, -83],
      [-50, -25],
      [-35, -59],
      [45, -62],
      [74, -23],
      [36, 78],
      [58, -64],
      [52, -19],
    ],
    [
      [2517, 5093],
      [7, -195],
      [31, -104],
      [-30, -111],
      [-33, -50],
      [11, -47],
      [-150, -14],
      [-22, -35],
      [-133, -91],
      [-112, -45],
      [-119, 82],
      [-13, 127],
      [62, -4],
      [36, 84],
      [43, 152],
      [-106, 63],
      [38, 136],
      [-27, 64],
      [34, 46],
      [117, -14],
      [82, 57],
      [-54, 37],
      [58, 143],
      [63, 11],
      [68, 45],
      [55, -35],
      [-43, -45],
    ],
    [
      [1148, 8380],
      [34, -65],
      [115, 1],
      [39, -63],
      [-2, -81],
      [152, -62],
      [12, -122],
      [-73, -88],
      [-63, -16],
      [-2, -58],
      [-68, -47],
      [-140, -31],
      [-127, -89],
      [-135, -19],
      [-15, -57],
      [-108, -33],
      [-204, 39],
      [-141, 92],
      [-161, -8],
      [81, 90],
      [-92, 161],
      [-117, 5],
      [-82, -28],
      [-32, 44],
      [195, 31],
      [66, 112],
      [-102, 37],
      [-136, -44],
      [-42, 25],
      [57, 151],
      [132, 104],
      [215, -126],
      [-17, -91],
      [73, -11],
      [92, 38],
      [-26, 75],
      [153, 15],
      [82, 28],
      [121, -11],
      [69, -42],
      [131, 52],
      [-8, 84],
      [74, 8],
    ],
    [
      [4694, 1698],
      [43, -34],
      [4, -56],
      [36, -102],
      [-29, -68],
      [15, -48],
      [-24, -249],
      [-67, 19],
      [-9, -57],
      [-53, -28],
      [-37, 92],
      [12, 89],
      [-9, 125],
      [9, 70],
      [-46, 110],
      [25, 32],
      [63, 17],
      [67, 88],
    ],
    [
      [5320, 3091],
      [-47, -61],
      [36, -85],
      [13, -99],
    ],
    [
      [5322, 2846],
      [-76, 46],
      [-55, -56],
      [-71, -24],
      [14, -112],
      [-17, -76],
      [16, -133],
      [48, -70],
      [128, -120],
      [64, -246],
      [93, -136],
      [54, -40],
      [127, 3],
      [23, -41],
      [-37, -39],
      [13, -57],
      [146, -91],
      [58, -66],
      [75, -48],
      [72, -142],
      [-19, -79],
      [-53, 46],
      [-22, 80],
      [-48, 3],
      [-64, 57],
      [-63, -114],
      [-16, -74],
      [94, -106],
      [-5, -126],
      [-40, -3],
      [-42, -51],
      [4, -78],
      [-56, -75],
      [-11, -52],
      [-60, 5],
      [-2, 72],
      [36, 62],
      [-9, 49],
      [50, 31],
      [-28, 175],
      [-55, 168],
      [-37, -19],
      [-61, 63],
      [13, 41],
      [-35, 74],
      [-29, -17],
      [-35, 60],
      [-34, -1],
      [-47, 110],
      [-55, 11],
      [-40, -17],
      [-113, 134],
      [-13, 44],
      [-45, 35],
      [-23, 64],
      [-67, 64],
      [-27, 50],
      [-69, 61],
      [7, 55],
      [-31, 87],
      [-9, 88],
      [-22, 55],
      [-47, 16],
      [-76, 73],
      [-66, 20],
      [-42, -34],
      [-71, -119],
      [-64, -16],
    ],
    [
      [5593, 909],
      [-53, -119],
      [-25, -93],
      [34, -114],
      [-36, -98],
      [-82, 36],
      [-30, 66],
      [-53, 15],
      [-134, 124],
      [-34, -2],
      [-38, 62],
      [10, 57],
      [56, 4],
      [58, 51],
      [67, -65],
      [76, 12],
      [81, 44],
      [25, -15],
      [78, 35],
    ],
    [
      [6424, 1962],
      [-65, -14],
      [-75, -85],
    ],
    [
      [6214, 2041],
      [1, 60],
      [38, 14],
    ],
    [
      [6253, 2115],
      [69, 91],
      [36, -21],
      [40, -92],
      [51, -16],
      [-25, -115],
    ],
    [
      [6344, 5404],
      [-9, 2],
    ],
    [
      [6335, 5406],
      [9, -2],
    ],
    [
      [6694, 5051],
      [-7, 61],
      [-94, 49],
    ],
    [
      [6593, 5161],
      [6, 142],
      [-32, 44],
      [-78, -4],
      [-106, 54],
    ],
    [
      [6383, 5397],
      [-27, 131],
      [-3, 87],
    ],
    [
      [6353, 5615],
      [76, 62],
      [70, 29],
      [129, -25],
      [38, 13],
      [163, -29],
      [63, 47],
      [30, -68],
      [78, -11],
      [131, -125],
    ],
    [
      [7049, 6076],
      [0, -8],
    ],
    [
      [7237, 5999],
      [68, -62],
      [-27, -109],
      [67, -87],
      [4, -108],
    ],
    [
      [6353, 5615],
      [-12, 48],
      [13, 156],
      [47, 51],
      [3, 66],
      [39, 71],
      [110, 49],
      [91, -98],
      [17, -68],
      [62, -39],
      [96, 70],
      [-10, 168],
    ],
    [
      [4441, 2357],
      [-10, -5],
    ],
    [
      [7356, 2811],
      [-17, 146],
      [24, 112],
      [-30, 153],
      [-32, 34],
      [-75, 151],
      [-32, 107],
      [-60, 36],
    ],
    [
      [7134, 3550],
      [31, 36],
      [47, -8],
      [81, 23],
      [63, -63],
      [84, -26],
      [46, -36],
      [16, -58],
      [-17, -59],
      [61, -56],
      [1, -100],
      [55, -53],
      [-32, -76],
      [-109, 0],
      [-2, -122],
      [-103, -141],
    ],
    [
      [6424, 1962],
      [53, 23],
      [57, -6],
    ],
    [
      [6092, 2302],
      [59, -97],
      [44, -14],
      [58, -76],
    ],
    [
      [6116, 1858],
      [-122, 149],
    ],
    [
      [2380, 5350],
      [108, 47],
      [58, -46],
      [68, -104],
      [20, -58],
      [-25, -54],
      [-92, -42],
    ],
    [
      [3867, 4374],
      [123, -2],
    ],
    [
      [3995, 4372],
      [-102, 24],
      [4, 36],
      [77, 12],
      [-14, 90],
      [56, 64],
      [71, 180],
      [65, 51],
      [30, 54],
      [55, 27],
      [105, 15],
      [65, -57],
    ],
    [
      [5630, 9019],
      [4, -72],
      [77, -36],
      [-81, -47],
      [-97, -9],
      [34, 122],
      [63, 42],
    ],
    [
      [5530, 9013],
      [28, -53],
      [-46, -41],
      [-91, 11],
      [109, 83],
    ],
    [
      [5887, 9177],
      [48, -34],
      [-22, -24],
      [5, -41],
      [-159, -33],
      [13, 70],
      [115, 62],
    ],
    [
      [7727, 9244],
      [-47, -72],
      [-165, -68],
      [-53, -66],
    ],
    [
      [6292, 9040],
      [-39, -33],
      [-14, -114],
      [-44, -34],
      [-125, 41],
      [-122, 8],
      [-1, -100],
      [-38, -50],
      [-80, 36],
      [-72, -51],
      [-46, -93],
      [-50, -36],
      [41, -98],
      [-139, -148],
      [7, -57],
      [-132, -40],
      [15, -74],
      [-18, -139],
      [-123, -194],
      [67, -38],
      [-25, -114],
      [-99, 22],
      [-78, -36],
      [-72, -95],
      [-24, -81],
      [32, -76],
      [-19, -110],
      [32, -93],
      [-22, -135],
      [101, -93],
      [-28, -87],
      [-59, -16],
      [47, -123],
      [-12, -105],
      [-117, -148],
      [19, -82],
      [-52, -68],
    ],
    [
      [5003, 6386],
      [-140, 95],
      [-60, -100],
      [-82, -21],
      [-13, -37],
      [-160, -166],
      [-113, -28],
      [-237, 133],
      [-32, 62],
      [89, 137],
      [-89, 1],
      [-42, 62],
      [130, 157],
      [-112, 22],
      [-1, 98],
      [-37, 48],
      [-10, 138],
      [37, 96],
      [10, 116],
      [88, 35],
      [55, 108],
      [92, 31],
      [42, 76],
      [67, -19],
      [146, 129],
      [53, -7],
      [75, 64],
      [44, 125],
      [95, 92],
      [116, 14],
      [-16, 38],
      [52, 83],
      [102, 44],
      [14, 76],
      [-35, 55],
      [123, 144],
      [-20, 83],
      [85, 89],
      [75, 38],
      [26, 79],
      [88, 105],
      [4, 43],
      [102, 30],
      [118, 182],
      [84, 30],
      [63, 89],
      [56, 20],
      [-11, 31],
      [42, 53],
      [112, 66],
      [199, 9],
      [92, 28],
      [5, 88],
      [110, 30],
      [192, -55],
      [22, 67],
      [105, 63],
      [1, 61],
      [151, 31],
      [67, -33],
      [-81, -80],
      [28, -49],
      [164, 161],
      [51, -112],
      [83, 151],
      [137, -31],
      [118, -56],
      [116, -23],
      [120, -83],
      [-194, -105],
      [163, -43],
    ],
    [
      [6672, 11462],
      [-29, -57],
      [183, -42],
      [-167, -149],
      [-113, 0],
      [-34, 64],
      [-178, -17],
      [104, 126],
      [5, 77],
      [229, -2],
    ],
    [
      [6412, 11564],
      [112, -85],
      [-170, -17],
      [-124, 78],
      [182, 24],
    ],
    [
      [5758, 11904],
      [104, 6],
      [186, -120],
      [9, -76],
      [361, -80],
      [-355, -102],
      [-10, -76],
      [-83, -72],
      [-19, -110],
      [-100, -49],
      [-22, -86],
      [-139, -96],
      [-182, 137],
      [-93, 17],
      [-65, 89],
      [-45, 138],
      [203, 16],
      [106, 59],
      [-82, 83],
      [-116, -41],
      [-74, -76],
      [-140, 39],
      [-170, 102],
      [-132, 215],
      [157, 75],
      [277, 10],
      [206, -70],
      [54, 62],
      [164, 6],
    ],
    [
      [6646, 12038],
      [575, -75],
      [-45, -67],
      [-153, -58],
      [-26, -60],
      [-382, -46],
      [-71, 49],
      [-225, -8],
      [-166, 61],
      [311, 26],
      [6, 31],
      [-436, -27],
      [-89, 51],
      [175, 59],
      [209, 19],
      [209, -26],
      [108, 71],
    ],
    [
      [6150, 5188],
      [185, -16],
      [258, -11],
    ],
    [
      [6711, 4411],
      [38, -100],
      [32, -158],
      [-57, -43],
      [-84, -111],
      [-65, -113],
      [15, -128],
      [-29, 7],
    ],
    [
      [6561, 3765],
      [-74, 37],
      [-27, 44],
      [-76, 18],
      [-47, -40],
      [-89, 24],
      [-33, -53],
      [-45, 9],
      [-43, 101],
      [-38, -52],
      [-48, 28],
    ],
    [
      [5399, 4988],
      [-8, 47],
    ],
    [
      [5392, 5051],
      [231, 82],
      [51, 7],
      [49, 75],
      [185, 70],
      [76, -11],
      [22, -92],
      [59, -24],
      [85, 30],
    ],
    [
      [2356, 626],
      [-52, -44],
      [-118, 26],
      [-36, -20],
      [12, 117],
      [3, 184],
      [-18, 86],
      [-42, -26],
      [-7, 67],
      [-34, 11],
      [20, 174],
      [37, 56],
      [31, 144],
      [30, 230],
      [-32, 196],
      [18, 61],
    ],
    [
      [7356, 2811],
      [71, -62],
      [86, 58],
      [48, -61],
    ],
    [
      [7561, 2746],
      [-7, -96],
      [-115, -38],
      [7, -56],
      [-28, -44],
      [-9, -155],
    ],
    [
      [6583, 2485],
      [-53, 121],
      [-93, 1],
      [-41, 37],
    ],
    [
      [6396, 2644],
      [18, 84],
      [-71, 49],
      [-31, 43],
      [-27, 104],
      [-46, 58],
    ],
    [
      [6609, 3468],
      [67, 12],
      [169, -14],
      [47, -61],
      [51, 50],
      [128, 25],
      [19, 58],
      [44, 12],
    ],
    [
      [10455, 9081],
      [-78, -79],
      [-158, -42],
      [-53, 43],
      [12, 101],
      [71, 57],
      [65, 4],
      [141, -84],
    ],
    [
      [8762, 3245],
      [19, 133],
      [54, 19],
      [20, 48],
      [124, -7],
      [33, 116],
      [-26, 82],
      [15, 123],
      [30, 50],
      [-13, 58],
      [-66, 68],
      [-172, 73],
      [-49, -18],
      [-38, 41],
      [-41, 92],
      [-105, -44],
      [-58, 6],
      [-23, 37],
      [-58, -20],
      [-38, 41],
      [-12, 109],
      [-53, 80],
      [-30, -14],
      [-65, 36],
      [-22, 101],
      [36, 36],
      [-44, 56],
      [-37, 86],
      [-86, 3],
      [-37, -32],
      [-56, 18],
      [-61, -72],
      [-47, 15],
    ],
    [
      [7330, 6516],
      [-3, 58],
      [120, 23],
      [44, 56],
      [90, -16],
      [-14, 69],
      [-86, -6],
      [-123, 97],
      [-57, 2],
    ],
    [
      [7727, 9244],
      [79, -24],
      [91, 65],
      [149, -80],
      [-4, -48],
      [107, -37],
      [337, -60],
      [201, -105],
      [106, -94],
      [152, -74],
      [48, -5],
      [162, -95],
      [13, -118],
      [35, -63],
      [-25, -54],
      [-103, -107],
      [-155, -71],
      [-110, -21],
      [-89, 9],
      [-145, 51],
      [-212, 35],
      [-69, 48],
      [-281, 49],
      [83, -114],
      [143, -62],
      [52, -63],
      [-18, -89],
      [-52, -40],
      [63, -92],
      [19, -156],
      [64, -44],
      [56, 15],
      [68, -88],
      [123, -30],
      [117, 16],
      [0, 95],
      [-44, 30],
      [-80, -9],
      [-77, 134],
      [61, 72],
      [146, -85],
      [177, -55],
      [172, -23],
      [-21, 96],
      [-94, 127],
      [16, 54],
      [136, 102],
      [77, 19],
      [120, 124],
      [77, -37],
      [71, 11],
      [111, -94],
      [63, 158],
      [1, 65],
      [-103, 79],
      [49, 141],
      [18, 132],
      [-36, 78],
      [185, -1],
      [90, -27],
      [83, -82],
      [26, -76],
      [-192, -31],
      [-56, -108],
      [90, -43],
      [36, -72],
      [151, -11],
      [112, 41],
      [-4, 54],
      [78, 127],
      [94, 1],
      [30, 46],
      [182, 95],
      [229, 85],
      [193, 99],
      [91, -178],
      [183, 85],
      [174, 20],
      [98, -20],
      [91, 76],
      [127, 40],
      [75, -63],
      [-43, -96],
      [80, -18],
      [13, 88],
      [98, 14],
      [62, 55],
      [-9, 60],
      [-99, 111],
      [88, 72],
      [371, -47],
      [160, -58],
      [43, -41],
      [213, -90],
      [-3, -114],
      [-45, -234],
      [-54, -112],
      [-294, -262],
      [-74, -94],
      [-110, -75],
      [-124, -28],
      [-159, -150],
      [-75, -103],
      [-50, -206],
      [-29, -187],
      [-5, -224],
      [-35, -225],
      [-5, -225],
      [5, -290],
      [15, -468],
      [49, -599],
      [-5, -262],
      [-19, -159],
      [-30, -328],
      [-50, -234],
      [-139, -75],
      [-110, 19],
      [-89, 47],
      [-154, 47],
      [-239, -29],
      [-70, 0],
      [-49, -48],
      [-24, 75],
      [-65, -23],
      [-12, -48],
      [-68, -6],
      [-9, 57],
      [-92, -13],
      [-36, -88],
      [-77, -53],
      [-50, 0],
      [0, -58],
      [-83, -76],
      [-18, -69],
      [26, -83],
      [-66, -55],
      [-111, 166],
      [-46, -44],
      [2, -61],
      [-58, -59],
      [-17, -129],
      [34, -62],
      [-37, -48],
      [-39, -134],
      [84, -35],
      [-4, -80],
      [54, -37],
      [86, -17],
      [67, -95],
      [68, -169],
      [-78, -29],
      [109, -88],
      [-68, -107],
      [-39, 5],
      [-28, -43],
      [-90, -18],
      [-78, -244],
      [-49, -35],
      [-1, -59],
      [75, -58],
      [36, -114],
      [-6, -201],
      [33, -40],
      [24, -75],
      [100, -195],
      [-29, -70],
      [-45, -32],
      [-46, -70],
      [-64, 28],
      [-51, 107],
      [-53, 47],
      [-82, 37],
      [-45, 53],
      [10, 75],
      [-48, 3],
      [-69, 63],
      [-83, -15],
      [-46, -39],
      [-20, 51],
      [-85, 48],
      [-62, 66],
      [-169, 13],
      [-126, 82],
      [-59, 10],
      [-34, -49],
      [-142, 200],
      [-54, 49],
      [-58, 19],
      [-36, 55],
      [-63, 17],
      [-40, 83],
      [-49, 31],
      [-8, 74],
      [53, -17],
      [81, 13],
      [-20, 69],
      [33, 31],
      [15, 71],
      [51, 52],
      [-67, 68],
      [81, 91],
      [57, 20],
      [64, 97],
      [-147, -42],
    ],
    [
      [11729, 9388],
      [26, -38],
      [132, -76],
      [-39, -62],
      [-89, 8],
      [-19, 46],
      [-103, 30],
      [-24, 86],
      [116, 6],
    ],
    [
      [11161, 10177],
      [159, -29],
      [-30, -106],
      [-68, -11],
      [-51, -128],
      [42, -182],
      [61, -93],
      [134, -105],
      [15, -66],
      [-282, -16],
      [-162, 54],
      [-72, 83],
      [-34, 102],
      [-95, 31],
      [-100, -31],
      [-65, 109],
      [29, 59],
      [102, -6],
      [81, 139],
      [-72, 32],
      [104, 65],
      [2, 49],
      [250, 73],
      [52, -23],
    ],
    [
      [12966, 11136],
      [108, -66],
      [-90, -115],
      [-296, -85],
      [-404, -98],
      [-176, -37],
      [-171, -79],
      [-38, -75],
      [-96, -63],
      [-92, -4],
      [-91, -59],
      [-98, -120],
      [10, -34],
      [-97, -93],
      [-95, -51],
      [-219, 48],
      [-77, -14],
      [-85, 102],
      [121, 53],
      [112, 97],
      [143, 153],
      [-103, 61],
      [150, 18],
      [24, 46],
      [452, 188],
      [192, 54],
      [185, -28],
      [311, 71],
      [249, 125],
      [171, 5],
    ],
    [
      [10117, 12152],
      [-250, -99],
      [-139, 56],
      [389, 43],
    ],
    [
      [12137, 12147],
      [-170, -103],
      [-198, 7],
      [8, 107],
      [360, -11],
    ],
    [
      [10416, 12166],
      [239, -50],
      [-277, -48],
      [-87, -81],
      [-207, -19],
      [-29, 54],
      [310, 84],
      [51, 60],
    ],
    [
      [12481, 12256],
      [104, -76],
      [-325, -62],
      [-85, 42],
      [212, 38],
      [94, 58],
    ],
    [
      [6150, 5188],
      [49, 84],
      [136, 134],
    ],
    [
      [6344, 5404],
      [-61, -77],
      [94, -12],
      [6, 82],
    ],
    [
      [2550, 5759],
      [44, -70],
      [-85, -20],
      [41, 90],
    ],
    [
      [2511, 6046],
      [40, -134],
      [-41, -9],
      [-56, 57],
      [57, 86],
    ],
    [
      [2527, 6218],
      [-24, -84],
      [-86, -80],
      [-21, 103],
      [131, 61],
    ],
    [
      [2969, 5327],
      [-75, -4],
      [-54, -52],
      [-125, 25],
      [-44, 38],
      [78, 129],
      [-52, 99],
      [-84, -30],
      [-16, 42],
      [25, 66],
      [-21, 88],
      [-47, 31],
      [53, 158],
      [-34, 75],
      [10, 94],
      [71, 33],
      [-14, 70],
      [57, 101],
      [85, -27],
      [165, 36],
      [-2, -94],
      [-114, -93],
      [27, -95],
      [49, 31],
      [148, -11],
      [46, 9],
      [49, -61],
      [-31, -41],
      [-34, -118],
      [-104, -176],
      [115, -76],
      [17, -30],
    ],
    [
      [6114, 2662],
      [89, -58],
      [58, 92],
      [51, -94],
      [84, 42],
    ],
    [
      [6561, 3765],
      [-57, -176],
    ],
    [
      [5305, 2819],
      [17, 27],
    ],
    [
      [6034, 6082],
      [18, -107],
      [-68, -74],
      [-46, 27],
      [2, 67],
      [49, 77],
      [45, 10],
    ],
    [
      [6789, 8191],
      [-111, 0],
      [-40, -25],
      [-107, 29],
      [1, -62],
      [-118, -55],
      [18, -65],
      [-61, -83],
      [43, -92],
      [-74, -89],
      [-39, -93],
      [-131, -72],
      [-66, -17],
      [-35, -74],
      [-59, 0],
      [-14, -73],
      [-82, -22],
      [18, -58],
      [-98, -65],
      [29, -40],
      [-66, -150],
      [9, -182],
      [36, -82],
      [77, -31],
      [61, -82],
      [60, -37],
      [36, -79],
      [-126, -96],
      [29, -61],
      [-182, -136],
      [-50, -12],
      [12, -115],
      [-30, -132],
      [11, -55],
      [-19, -104],
      [-99, -262],
      [-50, 23],
      [-113, -7],
      [-69, -112],
      [23, -53],
      [-137, -47],
      [-54, 14],
      [-11, 93],
      [-44, 97],
      [46, 113],
      [-83, 105],
      [-40, 136],
      [-51, 112],
      [25, 84],
      [-94, 53],
      [4, 106],
      [29, 50],
    ],
    [
      [8762, 3245],
      [-89, -3],
      [-98, -76],
      [-138, -40],
      [-86, -95],
      [-67, -34],
      [33, -131],
      [61, -98],
      [115, 46],
      [20, -107],
      [-83, -20],
      [-43, 34],
      [-76, -75],
      [-75, -28],
      [-18, -45],
      [-54, -47],
      [-33, 5],
      [-34, 54],
      [20, 80],
      [-8, 49],
      [-43, 17],
      [-103, 79],
      [58, 54],
      [108, 66],
      [-13, 62],
      [-190, -5],
      [-41, 59],
      [8, 74],
      [-145, -8],
      [-70, -137],
      [-41, -60],
      [-56, -31],
      [-20, -138],
    ],
    [
      [3023, 4438],
      [-123, -58],
      [-61, 43],
      [-42, -2],
      [-52, 47],
      [-42, -36],
      [-26, 69],
      [131, 112],
      [21, 117],
      [-41, 103],
      [80, 54],
      [96, -16],
    ],
  ],
  transform: {
    scale: [0.007123098833858655, 0.0037846559231489284],
    translate: [-24.19673317797321, 34.8218199522132],
  },
  objects: {
    continent_Europe_subunits: {
      type: 'GeometryCollection',
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Albania' },
        },
        { type: null, properties: { type: 'Country', geounit: 'Aland' } },
        {
          arcs: [[5, 6]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Andorra' },
        },
        {
          arcs: [[7, 8, 9, 10, 11, 12, 13, 14, 15]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Austria' },
        },
        {
          type: null,
          properties: { type: 'Geounit', geounit: 'BrusselsCapitalRegion' },
        },
        {
          arcs: [[18, 19, 20, 21, 22, 23, 24, 25]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'FlemishRegion' },
        },
        {
          arcs: [[26, 27, 28, 29, 30]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Bulgaria' },
        },
        {
          arcs: [[35, 36, 37, 38]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'BosniaandHerzegovina' },
        },
        {
          arcs: [[-34, 39, -32, 40, 41, 42, 43, 44, -36, 45]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'RepublicSrpska' },
        },
        {
          arcs: [[46, 47, 48, 49, 50]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Belarus' },
        },
        {
          arcs: [[51, -17, 52, 53, 54, 55, -19]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'WalloonRegion' },
        },
        {
          arcs: [[-14, 57, -12, 58, 59, 60]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Switzerland' },
        },
        {
          arcs: [[61, 62, -16, 63]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'CzechRepublic' },
        },
        {
          arcs: [[66, 67, -64, -15, -61, 68, 69, -54, 70, 71, 72]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Germany' },
        },
        { type: null, properties: { type: 'Geosubunit', geounit: 'Denmark' } },
        {
          arcs: [[[73]], [[74]], [[75]], [[-73, 76]]],
          type: 'MultiPolygon',
          properties: { type: 'Country', geounit: 'Denmark' },
        },
        {
          arcs: [[77, 78, 79, 80]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'England' },
        },
        {
          arcs: [[[82]], [[83, 84, 85, 86, 87]]],
          type: 'MultiPolygon',
          properties: { type: 'Sovereigncountry', geounit: 'Estonia' },
        },
        {
          arcs: [[81], [88, -6, 89, 90, 91, 92, 93, 94]],
          type: 'Polygon',
          properties: { type: 'Geosubunit', geounit: 'Spain' },
        },
        {
          arcs: [[95, 96, 97, 98]],
          type: 'Polygon',
          properties: { type: 'Country', geounit: 'Finland' },
        },
        {
          type: null,
          properties: { type: 'Dependency', geounit: 'FaroeIslands' },
        },
        {
          arcs: [
            [99],
            [
              -57, -20, -56, 100, -69, -60, 101, 102, 103, 104, -90, -7, -89,
              105, -22,
            ],
          ],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'France' },
        },
        { type: null, properties: { type: 'Geosubunit', geounit: 'Guernsey' } },
        { type: null, properties: { type: 'Geosubunit', geounit: 'Guernsey' } },
        { type: null, properties: { type: 'Geosubunit', geounit: 'Guernsey' } },
        {
          arcs: [[-92, 106]],
          type: 'Polygon',
          properties: { type: 'Dependency', geounit: 'Gibraltar' },
        },
        {
          arcs: [[[107]], [[108]], [[109]], [[110]], [[111, -3, 112, -28]]],
          type: 'MultiPolygon',
          properties: { type: 'Sovereigncountry', geounit: 'Greece' },
        },
        {
          arcs: [
            [[-37, -45, 113, 114]],
            [[115, 116, -41, -33, -40, -35, -46, -39, 117, 118]],
          ],
          type: 'MultiPolygon',
          properties: { type: 'Sovereigncountry', geounit: 'Croatia' },
        },
        {
          arcs: [[119, 120, 121, -116, 122, -9, 123]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Hungary' },
        },
        { type: null, properties: { type: 'Country', geounit: 'IsleofMan' } },
        {
          arcs: [[124, 125]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Ireland' },
        },
        {
          arcs: [[126]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Iceland' },
        },
        { type: null, properties: { type: 'Geosubunit', geounit: 'Italy' } },
        { type: null, properties: { type: 'Geosubunit', geounit: 'Italy' } },
        {
          arcs: [[127], [130], [128, 129, -102, -59, -11]],
          type: 'Polygon',
          properties: { type: 'Geosubunit', geounit: 'Italy' },
        },
        { type: null, properties: { type: 'Country', geounit: 'Jersey' } },
        {
          arcs: [[131, -1, 132, 133]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Kosovo' },
        },
        {
          arcs: [[-58, -13]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Liechtenstein' },
        },
        {
          arcs: [[-50, 136, 137, 138, 139]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Lithuania' },
        },
        {
          arcs: [[-70, -101, -55]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Luxembourg' },
        },
        {
          arcs: [[140, -85, 141, -51, -140, 142, -87]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Latvia' },
        },
        {
          arcs: [[143, -104]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Monaco' },
        },
        {
          arcs: [[144, 145]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Moldova' },
        },
        {
          arcs: [[-29, -113, -2, -132, 146]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Macedonia' },
        },
        {
          type: null,
          properties: { type: 'Sovereigncountry', geounit: 'Malta' },
        },
        {
          arcs: [[147, -133, -5, 148, -114, -44]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Montenegro' },
        },
        {
          arcs: [[-125, 149]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'NorthernIreland' },
        },
        { type: null, properties: { type: 'Geounit', geounit: 'JanMayen' } },
        {
          arcs: [[[-24, 150]], [[-71, -53, -18, -52, -26, 151]]],
          type: 'MultiPolygon',
          properties: { type: 'Geocore', geounit: 'Netherlands' },
        },
        {
          arcs: [[[152]], [[153]], [[154]], [[155, -99, 156, 157]]],
          type: 'MultiPolygon',
          properties: { type: 'Geounit', geounit: 'Norway' },
        },
        {
          arcs: [[[158]], [[159]], [[160]], [[161]]],
          type: 'MultiPolygon',
          properties: { type: 'Geounit', geounit: 'Svalbard' },
        },
        {
          arcs: [[162, -137, -49, 163, 164, -62, -68, 165, -65, 166]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Poland' },
        },
        {
          arcs: [[167, -94]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'Portugal' },
        },
        {
          arcs: [[168, 169, -31, 170, 171, -121, 172, -145]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Romania' },
        },
        {
          arcs: [
            [[173]],
            [[174, -47, -142, -84, 175, -96, -156, 176]],
            [[177]],
            [[178]],
            [[179]],
            [[180]],
            [[181]],
            [[182]],
            [[183]],
          ],
          type: 'MultiPolygon',
          properties: { type: 'Geosubunit', geounit: 'Russia' },
        },
        {
          arcs: [[-163, 184, -135, 185, -138]],
          type: 'Polygon',
          properties: { type: 'Geosubunit', geounit: 'Russia' },
        },
        {
          arcs: [[[186]], [[187]], [[188]], [[-80, 189]]],
          type: 'MultiPolygon',
          properties: { type: 'Geounit', geounit: 'Scotland' },
        },
        {
          type: null,
          properties: { type: 'Sovereigncountry', geounit: 'SanMarino' },
        },
        {
          arcs: [[-171, -30, -147, -134, -148, -43, 190]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'Serbia' },
        },
        {
          arcs: [[-172, -191, -42, -117, -122]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'Vojvodina' },
        },
        {
          arcs: [[191, -124, -8, -63, -165]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Slovakia' },
        },
        {
          arcs: [[-119, 192, -129, -10, -123]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Slovenia' },
        },
        {
          arcs: [[[193]], [[194, -157, -98]]],
          type: 'MultiPolygon',
          properties: { type: 'Sovereigncountry', geounit: 'Sweden' },
        },
        {
          arcs: [[195, -169, -146, -173, -120, -192, -164, -48, -175]],
          type: 'Polygon',
          properties: { type: 'Sovereigncountry', geounit: 'Ukraine' },
        },
        {
          type: null,
          properties: { type: 'Sovereigncountry', geounit: 'Vatican' },
        },
        {
          arcs: [[-78, 196]],
          type: 'Polygon',
          properties: { type: 'Geounit', geounit: 'Wales' },
        },
      ],
    },
  },
}

export default EUROPE_MAP
