// IMPORT DEPENDENCIES
import { InputLabel, MenuItem } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useMeiliSearch, { ProductCatalog } from '../../../hooks/useMeiliSearch'

// ASSETS
import { displayProviderLogo } from '../../../hooks/displayIcons'

// ICONS
import SortIcon from '../../../assets/icons-io-v1/arrow_swap_vertical.svg?react'

// TYPES
import type { Hits, SearchParams } from 'meilisearch/dist/types/types'
import { useNavigate } from 'react-router-dom'
import { humanizeBandwidth } from '../../../hooks/humanizeBandwidth'
import type { AccessQueryParams, AvailableProduct } from '../../../slices/types'
import SelectedFilter from '../../fields/SelectedFilter'
import {
  StyledActionButton,
  StyledTextField,
  selectProps,
} from '../../forms/StyledFormComponents'
import type { CreationState } from '../DrawerCreateElementV2'

type ConfigurAccessProductProps = {
  newElementData: CreationState
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  setCreationStep: Dispatch<SetStateAction<number>>
}

interface formInput {
  provider: string
  location: string
  bandwidth: string
  mrc: number
  type: string
}
interface ProductCardProps {
  result: AvailableProduct
  activeRadio: number
  setActiveRadio: Dispatch<SetStateAction<number>>
}

const ProductCard: React.FC<ProductCardProps> = ({
  result,
  activeRadio,
  setActiveRadio,
}) => {
  const handleSetActiveRadio = useCallback(() => {
    setActiveRadio(result.id)
  }, [setActiveRadio, result.id])

  return (
    <div
      key={result.id}
      className='drawer-products__card'
      onClick={handleSetActiveRadio}>
      <div className='drawer-products__card--radio'>
        <input
          type='radio'
          value={result.id}
          checked={result.id === activeRadio ? true : false}
        />
      </div>
      <div className='drawer-products__card--col'>
        <div className='drawer-products__card--provider'>
          {displayProviderLogo(result?.provider)}
        </div>
      </div>
      <div className='drawer-products__card--col'>{result?.location}</div>
      <div className='drawer-products__card--col'>
        {humanizeBandwidth(result?.bandwidth)}
      </div>
      <div className='drawer-products__card--col'>
        {result?.priceMrc}
        {' €'}
      </div>
    </div>
  )
}

type SortFilter = 'provider' | 'location' | 'bandwidth' | 'priceMrc'
type Filter = 'provider' | 'location' | 'bandwidth' | 'type'

const sortMyData = (
  data: Hits<AvailableProduct>,
  sortBy: SortFilter,
  sortAsc: boolean
): typeof data => {
  if (!sortBy) {
    return data
  }
  if (sortBy === 'priceMrc' || sortBy === 'bandwidth') {
    if (sortAsc) {
      return [...(data || [])].sort((a, b) => b[sortBy] - a[sortBy])
    } else {
      return [...(data || [])].sort((a, b) => a[sortBy] - b[sortBy])
    }
  } else if (sortAsc) {
    return [...(data || [])].sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
  } else {
    return [...(data || [])].sort((a, b) => b[sortBy].localeCompare(a[sortBy]))
  }
}

export default function ConfigureAccessProduct({
  newElementData,
  setNewElementData,
  setCreationStep,
}: ConfigurAccessProductProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeRadio, setActiveRadio] = useState<number>(null)
  const { register, reset, control } = useForm<formInput>({
    shouldUseNativeValidation: true,
  })

  const [accessQueryParams, setAccessQueryParams] = useState<AccessQueryParams>(
    {}
  )
  // MEILI SEARCH CONFIG
  const searchQuery: string = ''
  const [facetSearch, setFacetSearch] = useState<SearchParams>({
    filter: [],
    facets: ['location', 'bandwidth', 'provider', 'type'],
  })

  const meiliSearchResponse = useMeiliSearch(
    searchQuery,
    facetSearch,
    ProductCatalog.Access
  )
  const {
    isLoading,
    data: { hits: searchResults, facetDistribution: facetDistri },
  } = meiliSearchResponse

  useEffect(() => {
    const filter = Object?.entries(accessQueryParams ?? {})
      .map(([key, val]) => {
        return `${key} = "${val}"`
      })
      .join(' AND ')

    setFacetSearch((prevFacetSearch) => ({
      ...prevFacetSearch,
      filter,
    }))
  }, [accessQueryParams])

  const handleChange = useCallback(
    (name: Filter, value: string) => {
      return () => {
        setNewElementData((previous) => ({ ...previous, [name]: value }))
        setAccessQueryParams((previous) => ({
          ...previous,
          [name]: value,
        }))
      }
    },
    [setNewElementData]
  )

  const handleClearAllFacet = useCallback(() => {
    setNewElementData((previous) => ({
      ...previous,
      elementProduct: null,
      accessPort: null,
      bandwidth: '',
      provider: '',
      location: '',
      type: '',
      vlan: null,
    }))
    setAccessQueryParams({})
    reset()
  }, [reset, setNewElementData])

  const handleClearFacet = useCallback(
    (facet: string) => {
      if (facet === 'type') handleClearAllFacet()
      else {
        setNewElementData((previous) => ({ ...previous, [facet]: '' }))
        setAccessQueryParams((previous) => {
          const { [facet]: _, ...accessaccessQueryParamsBis } = previous
          return { ...accessaccessQueryParamsBis }
        })
        reset({ [facet]: '' })
      }
    },
    [handleClearAllFacet, reset, setNewElementData]
  )

  // ----------------------------------------------
  // ----- LOGIQUE TO SORT THE DATA BY COLUMN -----
  const [mySortedData, setMySortedData] = useState(searchResults || [])
  const [mySortedFilter, setMySortedFilter] = useState<SortFilter>('provider')
  const [sortAscDesc, setSortAscDesc] = useState(true)
  const lastSort = useRef({ mySortedFilter, sortAscDesc })

  useEffect(() => {
    // Sort result with last sort
    if (isLoading) {
      return
    }
    setMySortedData(
      sortMyData(
        searchResults,
        lastSort.current.mySortedFilter,
        lastSort.current.sortAscDesc
      )
    )
  }, [searchResults, isLoading])

  useEffect(() => {
    lastSort.current = {
      mySortedFilter,
      sortAscDesc,
    }
    // Sort if sort changes
    setMySortedData((previous) =>
      sortMyData(previous, mySortedFilter, sortAscDesc)
    )
  }, [mySortedFilter, sortAscDesc])

  const onClickFilter = useCallback((filter: SortFilter) => {
    return () => {
      if (lastSort.current.mySortedFilter === filter) {
        setSortAscDesc((previous) => !previous)
      } else {
        // Asc by default
        setSortAscDesc(true)
      }
      setMySortedFilter(filter)
    }
  }, [])
  // ----- END LOGIQUE TO SORT THE DATA BY COLUMN -----
  // --------------------------------------------------

  // --------------------------------------------------------------
  // ----- HANDLE THE ADDITION OF THE PRODUCT AND REDIRECTION -----
  const handleAddProduct = useCallback(() => {
    const product = mySortedData.find((data) => data.id === activeRadio)

    setNewElementData((previous) => ({
      ...previous,
      elementProduct: product,
      accessPort: null,
      bandwidth: '',
      provider: '',
      location: '',
      type: '',
      vlan: null,
    }))
    setCreationStep(1)
    navigate(-1)
  }, [activeRadio, mySortedData, navigate, setCreationStep, setNewElementData])

  const displayPortType = (type: string) => {
    switch (type) {
      case 'VIRTUAL':
        return t('element.CONNECTED_TO_PROVIDER_THROUGH_SERVICE_KEY')
      case 'PHYSICAL':
        return t('element.CONNECTED_TO_AUTONOMI_CUSTOMER_PORT')
      default:
        break
    }
  }

  return (
    <>
      {/* ----- FILTER ----- */}
      <>
        <div className='drawer-create__containers'>
          {newElementData?.type === '' ? (
            <>
              <InputLabel className='drawer__forms--labels'>
                {t('element.CONNECTION_TYPE')}
              </InputLabel>
              <Controller
                name='type'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    select
                    size='small'
                    label={t('element.CONNECTION_TYPE_CHOOSE')}
                    slotProps={{
                      select: selectProps,
                    }}
                    {...register('type')}>
                    {facetDistri?.type !== undefined ? (
                      Object.keys(facetDistri?.type).map((type) => {
                        return (
                          <MenuItem
                            key={type}
                            value={type}
                            onClick={handleChange('type', type)}>
                            {displayPortType(type)}
                          </MenuItem>
                        )
                      })
                    ) : (
                      <div />
                    )}
                  </StyledTextField>
                )}
              />
            </>
          ) : (
            <SelectedFilter
              newElementData={newElementData}
              handleClearFacet={handleClearFacet}
              legend={t('element.CONNECTION_TYPE')}
              largeInput={true}
            />
          )}
        </div>

        {newElementData?.type !== '' ? (
          <div className='drawer-create__containers'>
            <>
              <div className='drawer-create__titles'>
                <span>{t('element.FILTERS')} :</span>
              </div>
              <div className='drawer-create__columns-filters'>
                {newElementData?.provider === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='provider'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.PROVIDER')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('provider')}>
                          {facetDistri?.provider !== undefined ? (
                            Object.keys(facetDistri?.provider).map(
                              (provider) => {
                                return (
                                  <MenuItem
                                    key={provider}
                                    value={provider}
                                    onClick={handleChange(
                                      'provider',
                                      provider
                                    )}>
                                    {provider}
                                  </MenuItem>
                                )
                              }
                            )
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.PROVIDER')}
                  />
                )}

                {newElementData?.location === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='location'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.CSP_LOCATION')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('location')}>
                          {facetDistri?.location !== undefined ? (
                            Object.keys(facetDistri?.location).map(
                              (location) => {
                                return (
                                  <MenuItem
                                    key={location}
                                    value={location}
                                    onClick={handleChange(
                                      'location',
                                      location
                                    )}>
                                    {location}
                                  </MenuItem>
                                )
                              }
                            )
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.LOCATION')}
                  />
                )}
                {newElementData?.bandwidth === '' ? (
                  <div className='drawer-create__columns-filter'>
                    <Controller
                      name='bandwidth'
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          select
                          label={t('element.BANDWIDTH')}
                          size='small'
                          slotProps={{
                            select: selectProps,
                          }}
                          {...register('bandwidth')}>
                          {facetDistri?.bandwidth !== undefined ? (
                            Object.keys(facetDistri?.bandwidth).map(
                              (bandwidth) => {
                                return (
                                  <MenuItem
                                    key={bandwidth}
                                    value={bandwidth}
                                    onClick={handleChange(
                                      'bandwidth',
                                      bandwidth
                                    )}>
                                    {bandwidth}
                                  </MenuItem>
                                )
                              }
                            )
                          ) : (
                            <div />
                          )}
                        </StyledTextField>
                      )}
                    />
                  </div>
                ) : (
                  <SelectedFilter
                    newElementData={newElementData}
                    handleClearFacet={handleClearFacet}
                    legend={t('element.BANDWIDTH')}
                  />
                )}
              </div>
            </>
          </div>
        ) : (
          <div />
        )}
      </>

      {/* ----- FILTERED RESULTS ----- */}
      {newElementData?.type !== '' ? (
        <div
          className={`drawer-products__containers ${
            Object.keys(accessQueryParams).length >= 2
              ? 'drawer-products__containers--border-bottom'
              : ''
          }`}>
          {Object?.keys(accessQueryParams).length >= 1 ? (
            <>
              <div className='drawer-products__card header'>
                <div className='drawer-products__card--radio' />
                <div className='drawer-products__card--header'>
                  {t('element.PROVIDER')}
                  <SortIcon
                    style={
                      mySortedFilter && mySortedFilter === 'provider'
                        ? { color: 'var(--content-active)' }
                        : { color: 'var(--content-primary)' }
                    }
                    onClick={onClickFilter('provider')}
                  />
                </div>
                <div className='drawer-products__card--header'>
                  {t('element.LOCATION')}
                  <SortIcon
                    style={
                      mySortedFilter && mySortedFilter === 'location'
                        ? { color: 'var(--content-active)' }
                        : { color: 'var(--content-primary)' }
                    }
                    onClick={onClickFilter('location')}
                  />
                </div>
                <div className='drawer-products__card--header'>
                  {t('element.BANDWIDTH')}
                  <SortIcon
                    style={
                      mySortedFilter && mySortedFilter === 'bandwidth'
                        ? { color: 'var(--content-active)' }
                        : { color: 'var(--content-primary)' }
                    }
                    onClick={onClickFilter('bandwidth')}
                  />
                </div>
                <div className='drawer-products__card--header'>
                  {t('element.MRC')}
                  <SortIcon
                    style={
                      mySortedFilter && mySortedFilter === 'priceMrc'
                        ? { color: 'var(--content-active)' }
                        : { color: 'var(--content-primary)' }
                    }
                    onClick={onClickFilter('priceMrc')}
                  />
                </div>
              </div>
              {mySortedData.map((result) => {
                return (
                  <ProductCard
                    result={result}
                    activeRadio={activeRadio}
                    setActiveRadio={setActiveRadio}
                  />
                )
              })}
            </>
          ) : (
            <div className='drawer-products__filters-message'>
              Please select a filter.
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      {Object?.keys(accessQueryParams).length >= 1 && (
        <div className='drawer-products__footer'>
          <div> {/* Placeholder for infinite scrool*/}</div>
          <div>
            <StyledActionButton
              variant='contained'
              disabled={!activeRadio}
              onClick={handleAddProduct}>
              Add
            </StyledActionButton>
          </div>
        </div>
      )}
    </>
  )
}
