// IMPORT DEPENDENCIES
import type { Dispatch, SetStateAction } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// IMPORT ASSETS
// import useCurrentAccount from '../../hooks/useCurrentAccount'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'
import { smallElementTypeCard } from '../../../hooks/displayIcons'

// TYPES
import { IconButton } from '@mui/material'
import type { CreationState } from '../DrawerCreateElementV2'
import ConfigurePort from './ConfigurePort'

type ConfigureElementProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
  setLoading: Dispatch<SetStateAction<boolean>>
}

export default function ConfigureItem({
  newElementData,
  setCreationStep,
  setNewElementData,
  setLoading,
}: ConfigureElementProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handClickBack = useCallback(
    (creationStep: number) => {
      setCreationStep(creationStep)
      navigate(-1)
    },
    [navigate, setCreationStep]
  )

  const toggleDrawer = useCallback(() => {
    navigate('/ports')
  }, [navigate])

  const showConfigureForm = useCallback(() => {
    switch (newElementData?.elementType) {
      case 'ports':
        return (
          <ConfigurePort
            newElementData={newElementData}
            setCreationStep={setCreationStep}
            setNewElementData={setNewElementData}
            setLoading={setLoading}
          />
        )
      default:
        return null
    }
  }, [newElementData, setCreationStep, setLoading, setNewElementData])

  return (
    <div className='drawer-create__wrapper'>
      <div className='show__element__container-close'>
        <IconButton
          onClick={toggleDrawer}
          sx={{ padding: '0', height: '24px' }}>
          <CloseIcon fontSize='small' style={{ width: '22px' }} />
        </IconButton>
      </div>
      <div>
        <div className='drawer-create__header'>
          <div
            className='drawer-create__header--link'
            onClick={() => handClickBack(0)}
          />
          {newElementData?.elementType === 'ports'
            ? t('ports.ORDER_A_PORT')
            : ' New user'}
        </div>
      </div>

      <div className='drawer-create__id-card'>
        {smallElementTypeCard(newElementData?.elementType, t)}
      </div>
      {showConfigureForm()}
    </div>
  )
}
