import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/logos/horizontal-small.svg?react'
import './style.scss'

export default function TopLogo() {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate('/login')
  }, [navigate])

  return (
    <div onClick={handleClick} className='login__top-container'>
      <Logo />
    </div>
  )
}
