// IMPORT DEPENDENCIES
import type { Dispatch, SetStateAction } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// IMPORT ICONS
import ChevronLeftIcon from '../../../assets/icons-io-v1/chevron_left_2.svg?react'
import CloseIcon from '../../../assets/icons-io-v1/close.svg?react'

// TYPES
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import type { CreationState } from '../DrawerCreateElementV2'
import ConfigurePortProduct from './ConfigurePortProduct'
type ConfigureProductProps = {
  newElementData: CreationState
  setCreationStep: Dispatch<SetStateAction<number>>
  setNewElementData: Dispatch<SetStateAction<CreationState>>
}

export default function ConfigureItemProduct({
  newElementData,
  setCreationStep,
  setNewElementData,
}: ConfigureProductProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handClickBack = useCallback(
    (creationStep: number) => {
      setNewElementData((previous) => ({
        ...previous,
        bandwidth: '',
        location: '',
        duration: 0,
        portType: '',
      }))
      setCreationStep(creationStep)
      navigate(-1)
    },
    [navigate, setCreationStep, setNewElementData]
  )

  const toggleDrawer = useCallback(() => {
    navigate('/ports')
  }, [navigate])

  const displayProductList = useCallback(() => {
    switch (newElementData?.elementType) {
      case 'ports':
        return (
          <ConfigurePortProduct
            newElementData={newElementData}
            setNewElementData={setNewElementData}
            setCreationStep={setCreationStep}
          />
        )
      default:
        return null
    }
  }, [newElementData, setCreationStep, setNewElementData])

  return (
    <>
      <div className='drawer-create__wrapper'>
        <div className='show__element__container-close'>
          <IconButton
            onClick={toggleDrawer}
            sx={{ padding: '0', height: '24px' }}>
            <CloseIcon fontSize='small' style={{ width: '22px' }} />
          </IconButton>
        </div>
        <div className='drawer-create__header'>
          <div
            className='drawer-create__header--link'
            onClick={() => handClickBack(0)}>
            <ChevronLeftIcon />
          </div>
          {t('ports.ADD_PORT_PRODUCT')}
        </div>

        {displayProductList()}
      </div>
    </>
  )
}
