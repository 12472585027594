import { API_PATH_CUSTOMERS_TOKEN, API_PATH_V1 } from '../environments/env'
import type { RootState } from '../store'
import { apiSlice } from './apiSlice'
import { selectCurrentToken } from './authSlice'
import type { User } from './types'

let currentToken: string

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    self: builder.query<User, void>({
      query: () => `${API_PATH_V1}/users/self`,
      forceRefetch: ({ state }) => {
        const token = selectCurrentToken(state as unknown as RootState)
        if (currentToken !== token) {
          currentToken = token
          return true
        }

        return false
      },
      keepUnusedDataFor: 0,
      providesTags: ['Self'],
    }),
    revoke: builder.mutation<void, void>({
      query: () => ({
        url: `${API_PATH_CUSTOMERS_TOKEN}/revoke`,
        method: 'GET',
        credentials: 'include',
      }),
    }),
    login: builder.mutation<
      { otpEnabled: Boolean; accessToken: { token: string } },
      { email: string; password: string }
    >({
      query: (body) => ({
        url: `${API_PATH_V1}/signin`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
})

export const { useLoginMutation, useSelfQuery, useRevokeMutation } =
  authApiSlice
