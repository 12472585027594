import { Divider } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ReadOnlyRow from '../../../../components/drawer/ReadOnlyRows'
import { humanizeBandwidth } from '../../../../hooks/humanizeBandwidth'
import type { AccessElementV2 } from '../../../../slices/types'

type Props = {
  elementData: AccessElementV2
}

export default function AccessProduct({ elementData }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <div className='show__element--content'>
        <div className='show__element--title'>{t('element.PRODUCT')}</div>
      </div>
      <ReadOnlyRow
        title={t('element.PROVIDER')}
        value={elementData.data.product.provider}
      />
      <ReadOnlyRow
        title={t('element.LOCATION')}
        value={elementData.data.product.location}
      />
      <ReadOnlyRow
        title={t('element.BANDWIDTH')}
        value={humanizeBandwidth(elementData.data.product.bandwidth)}
      />
      <ReadOnlyRow
        title={t('element.SKU')}
        value={elementData.data.product.sku}
      />
      <ReadOnlyRow
        title={t('element.MRC')}
        value={elementData.data.product.priceMrc}
      />

      <Divider sx={{ width: '100%', margin: '24px 0' }} />

      <div className='show__element--content'>
        <div className='show__element--title'>
          {t('element.CONNECTION_TYPE')}
        </div>
      </div>
      {elementData.data.product.type === 'PHYSICAL' && (
        <ReadOnlyRow
          title={t('element.CONNECTED_TO_AUTONOMI_CUSTOMER_PORT')}
          value={null}
        />
      )}
      {elementData.data.product.type === 'VIRTUAL' && (
        <ReadOnlyRow
          title={t('element.CONNECTED_TO_PROVIDER_THROUGH_SERVICE_KEY')}
          value={null}
        />
      )}

      {elementData.data.product.type === 'PHYSICAL' && (
        <>
          <ReadOnlyRow
            title={t('element.PORT_NAME')}
            value={elementData.data.physicalPort.name}
          />
          <ReadOnlyRow
            title={t('element.PORT_BANDWIDTH')}
            value={humanizeBandwidth(
              elementData.data.physicalPort.product.bandwidth
            )}
          />
          <ReadOnlyRow
            title={t('element.PORT_AVAILABLE_CAPACITY')}
            value={humanizeBandwidth(
              elementData.data.physicalPort.availableBandwidth
            )}
          />
        </>
      )}

      {elementData.data.product.type === 'VIRTUAL' && (
        <>
          <ReadOnlyRow
            title={t('element.SERVICE_KEY_NAME')}
            value={elementData.data.serviceKey.name}
          />
          <ReadOnlyRow
            title={t('element.SERVICE_KEY_ID')}
            value={elementData.data.serviceKey.id}
          />
          <ReadOnlyRow
            title={t('element.KEY_EXPIRATION')}
            value={moment(elementData.data.serviceKey.expirationDate).format(
              'YYYY/MM/DD HH:MM:ss'
            )}
          />
        </>
      )}

      <ReadOnlyRow
        title={t('element.VLAN')}
        value={elementData.data.vlan ? elementData.data.vlan : '-'}
      />
    </>
  )
}
