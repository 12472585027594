import type { BaseQueryExtraOptions } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { BaseQueryApi } from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  API_PATH_CUSTOMERS_TOKEN,
  REACT_APP_API_URL as baseUrl,
} from '../environments/env.js'
import type { RootState } from '../store'
import { logoutAction, setTokenAction } from './authActions'
import type { CredentialsResponse } from './types'

export const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: 'include', //Set Header  Access-Control-Allow-Credentials: true on mockoon
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    headers.set('Content-Type', 'application/json')
    return headers
  },
  mode: 'cors',
})

// Global to queue pending request to refresh
// Avoid to cancel repetitive refresh token
var currentQuery: ReturnType<typeof baseQuery> | null

const refreshToken = async (
  api: BaseQueryApi,
  extraOptions: BaseQueryExtraOptions<typeof fetchBaseQueryWithReauth> = {}
) => {
  if (!currentQuery) {
    currentQuery = baseQuery(
      {
        url: `${API_PATH_CUSTOMERS_TOKEN}/refresh`,
        method: 'GET',
        credentials: 'include',
      },
      api,
      extraOptions
    )
  }
  return currentQuery
}

const fetchBaseQueryWithReauth: typeof baseQuery = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result?.error?.status === 401) {
    // Check if a refresh token exists, if yes, resend to fetch the new token,
    // if no, redirect to login
    try {
      const response = await refreshToken(api, extraOptions).finally(
        () => (currentQuery = null)
      )
      if (!(response.data as CredentialsResponse)?.accessToken?.token) {
        throw response.error
      }
      api.dispatch(
        setTokenAction((response.data as CredentialsResponse).accessToken.token)
      )
      result = await baseQuery(args, api, extraOptions)
    } catch (e) {
      api.dispatch(logoutAction())
      throw e
    }
  }

  if (result.error && result?.error?.status === 429) {
    // Ratelimiting
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(baseQuery(args, api, extraOptions))
      }, 1000)
    })
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: fetchBaseQueryWithReauth,
  tagTypes: [
    'Self',
    'Users',
    'UsersDetailed',
    'Accounts',
    'Workspaces',
    'WorkspacesUsers',
    'Products',
    'Ports',
    'Settings',
    'Nodes',
    'Transports',
    'Attachments',
    'Metrics',
  ],
  endpoints: () => ({}),
})
