import { API_PATH_ACCOUNTS } from '../environments/env'
import { apiSlice } from './apiSlice'
import type {
  PortAddElement,
  PortElement,
  PortElementV2,
  PortsV2,
} from './types'

const portsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLOA: builder.query({
      query: ({ accountId, portId }) => {
        return {
          url: `${API_PATH_ACCOUNTS}/${accountId}/ports/${portId}/loa`,
          responseHandler: async (response) => {
            window.open(window.URL.createObjectURL(await response.blob()))
          },
        }
      },
    }),

    getPorts: builder.query<PortsV2, string>({
      query: (currentAccountId) => ({
        url: `${API_PATH_ACCOUNTS}/${currentAccountId}/ports`,
      }),
      providesTags: ['Ports'],
      transformResponse: (response: {
        terms: string
        data: Omit<PortElement, 'kind'>[]
      }): PortsV2 => {
        const data: PortElement[] = response.data.map((e) => ({
          ...e,
          kind: 'port',
        }))

        return {
          ...response,
          data,
        }
      },
    }),

    getPortsbyFilters: builder.query<
      PortsV2,
      { accountId: string; query: { state: string } }
    >({
      query: ({ query: params, accountId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/ports`,
        params,
      }),
      transformResponse: (response: PortsV2 /** @FIXME: Wrong API type */) => ({
        ...response,
        data: response.data.map((e) => ({
          ...e,
          kind: 'port',
        })),
      }),
      providesTags: ['Ports'],
    }),

    getAccessVlan: builder.query<
      number[],
      { accountId: string; portId: string }
    >({
      query: ({ accountId, portId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/ports/${portId}/available-vlans`,
      }),
    }),

    getPortByIdV2: builder.query<
      PortElementV2,
      { accountId: string; portId: string }
    >({
      query: ({ accountId, portId }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/ports/${portId}`,
      }),
      transformResponse: (response: PortElementV2) => {
        const nodes = response.data.nodes ?? []
        return {
          ...response,
          data: {
            ...response.data,
            nodes,
            kind: 'port',
          },
        }
      },
      providesTags: ['Ports'],
      keepUnusedDataFor: 5,
    }),

    checkAvailableVlan: builder.query<
      void,
      { accountId: string; portId: string; vlan: number }
    >({
      query: ({ accountId, portId, vlan }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/ports/${portId}/check-vlan/${vlan}`,
        method: 'HEAD',
      }),
    }),

    addNewPort: builder.mutation<
      PortElementV2,
      { accountId: string; body: PortAddElement }
    >({
      query({ accountId, body }) {
        return {
          url: `${API_PATH_ACCOUNTS}/${accountId}/ports`,
          method: 'POST',
          body,
        }
      },
      transformResponse: (response: PortElementV2) => {
        return {
          ...response,
          data: {
            ...response.data,
            kind: 'port',
          },
        }
      },
      invalidatesTags: ['Ports'],
    }),

    deletePhysicalPort: builder.mutation<
      PortElement,
      { accountId: string; id: string }
    >({
      query: ({ accountId, id }) => ({
        url: `${API_PATH_ACCOUNTS}/${accountId}/ports/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ports'],
    }),
  }),
})

export const {
  useLazyGetLOAQuery,
  useGetPortsQuery,
  useGetPortsbyFiltersQuery,
  useGetAccessVlanQuery,
  useCheckAvailableVlanQuery,
  useLazyCheckAvailableVlanQuery,
  useAddNewPortMutation,
  useDeletePhysicalPortMutation,
  useGetPortByIdV2Query,
} = portsApiSlice
